import PT from 'prop-types';
import { Grid, useMediaQuery, CircularProgress } from '@mui/material';

function HeaderButtons({ headerActions, deleteLoading }) {
  const screenMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  // eslint-disable-next-line react/prop-types
  const Item = ({ action }) => {
    // eslint-disable-next-line react/prop-types
    if (action?.key === 'delete' && deleteLoading)
      return (
        <CircularProgress
          size={30}
          sx={{ margin: theme => theme.spacing(1) }}
        />
      );
    return action;
  };
  return (
    <>
      {!screenMd && (
        <Grid item xs={6} sm={6}>
          {headerActions.map((action, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item lg={4} xs={4} sm={4}>
                <Item action={action} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {screenMd && (
        <Grid container>
          {headerActions.map((action, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item>
                <Item action={action} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

HeaderButtons.propTypes = {
  headerActions: PT.arrayOf(PT.object.isRequired).isRequired,
  deleteLoading: PT.bool,
};
HeaderButtons.defaultProps = {
  deleteLoading: false,
};

export default HeaderButtons;
