import { PersonCard as MsPersonCard } from '@microsoft/mgt-react';
import { useTheme } from '@mui/material/styles';
import PT from 'prop-types';

function PersonCard({ isExpanded, showPresence, ...rest }) {
  const theme = useTheme();
  return (
    <MsPersonCard
      showPresence={showPresence}
      isExpanded={isExpanded}
      className={theme.palette.mode === 'dark' && 'mgt-dark'}
      {...rest}
    />
  );
}

PersonCard.propTypes = {
  isExpanded: PT.bool,
  showPresence: PT.bool,
};

PersonCard.defaultProps = {
  isExpanded: true,
  showPresence: true,
};

export default PersonCard;
