/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { parameterMetadataApi, handleApiError } from 'api';

export default () => {
  const { assetId } = useParams();

  const [assetTags, setAssetTags] = useImmer([]);
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [isTagLoading, setIsTagLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setOpenEditingForm(false);
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    setError(undefined);
    setAssetTags([]);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getTags = async after => {
      setIsTagLoading(true);
      const query = {
        reference_id: assetId,
        reference_table: 'assets',
      };
      if (after) query.after = after;
      const response = await parameterMetadataApi(
        `getTags`,
        query,
        source.token
      ).catch(err => {
        handleApiError(err, []);
      });
      if (response) {
        const { tags, paging } = response;
        if (tags && !didCancel) {
          setAssetTags(curr => [...curr, ...tags]);
          setIsTagLoading(false);
        }
        if (!didCancel && paging?.cursors?.after)
          getTags(paging?.cursors?.after);
      }
    };

    if (assetId) {
      getTags();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [assetId]);

  return {
    openEditingForm,
    setOpenEditingForm,
    isTagLoading,
    assetTags,
    setAssetTags,
    setIsTagLoading,
    error,
    setError,
  };
};
