/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import { Inventory2Outlined, Inventory2 } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { assetTypePropType } from 'types';
import DataDrawerGrid from '../../common/grid';

function Value({ asset }) {
  const name = asset?.name;
  const typeName = asset?.assetType?.name;
  const { t } = useTranslation(['common']);
  return (
    <div aria-label="value component">
      <DataDrawerGrid
        icon={
          <Inventory2Outlined
            data-testid="icon"
            sx={{
              color: theme => asset?.deletedAt && theme.palette.secondary.dark,
            }}
          />
        }
        caption={
          <Typography
            variant="caption"
            color="textSecondary"
            data-testid="label"
          >
            {t('common:asset')}
          </Typography>
        }
        content={
          <Typography
            data-testid="value"
            sx={{
              color: asset.deletedAt
                ? theme => theme.palette.secondary.dark
                : theme => theme.palette.text.primary,
            }}
          >
            {name || t('common:dataDrawer.notDefined')}
          </Typography>
        }
      />
      <DataDrawerGrid
        icon={
          <Inventory2
            data-testid="icon"
            sx={{
              color: theme => asset?.deletedAt && theme.palette.secondary.dark,
            }}
          />
        }
        caption={
          <Typography
            variant="caption"
            color="textSecondary"
            data-testid="label"
          >
            {t('common:assetType')}
          </Typography>
        }
        content={
          <Typography
            data-testid="value"
            sx={{
              color: asset.deletedAt
                ? theme => theme.palette.secondary.dark
                : theme => theme.palette.text.primary,
            }}
          >
            {typeName || t('common:dataDrawer.notDefined')}
          </Typography>
        }
      />
    </div>
  );
}

Value.propTypes = {
  asset: assetTypePropType,
};
Value.defaultProps = {
  asset: {},
};

export default Value;
