/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { ReportingContext } from 'context';

export default () => {
  const { content } = useContext(ReportingContext);
  const [open, setOpen] = useImmer({});

  useEffect(() => {
    if (!isEmpty(content.repeatRegions)) {
      const collapse = {};
      content.repeatRegions.forEach(({ name, assets }) => {
        collapse[name] = false;
        assets?.forEach(asset => {
          return (collapse[asset.id] = false);
        });
      });
      collapse.simplePlaceholders = false;
      setOpen(collapse);
    }
  }, []);

  return {
    open,
    setOpen,
  };
};
