import { Checkboxes } from 'mui-rff';
import PT from 'prop-types';

function Checkbox({ name, size, options, required, ...rest }) {
  return (
    <Checkboxes
      required={required}
      name={name}
      size={size || 'small'}
      data={options}
      {...rest}
    />
  );
}
Checkbox.propTypes = {
  required: PT.bool,
  name: PT.string.isRequired,
  size: PT.string,
  options: PT.oneOfType([PT.array, PT.object]).isRequired,
};

Checkbox.defaultProps = {
  required: true,
  size: 'small',
};

export default Checkbox;
