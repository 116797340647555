import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { purple, grey, orange, red } from '@mui/material/colors';
import { darkScrollbar } from '@mui/material';
import config from 'config';

const envThemeColor = (env, mode) => {
  const obj = {
    development: mode === 'dark' ? purple.A100 : purple[800],
    ut: mode === 'dark' ? '#e0941b' : '#673f00',
    production: mode === 'dark' ? orange[500] : '#D44214',
  };
  return obj[env];
};

const muiTheme = (mode, i18n) =>
  createTheme(
    {
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: mode === 'dark' ? darkScrollbar() : null,
          },
        },
        MuiAutocomplete: {
          option: {
            overflowWrap: 'anywhere',
            hyphens: 'auto',
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
          },
        },
      },
      palette: {
        mode,
        primary: { main: envThemeColor(config.env, mode) },
        secondary: {
          main: grey[400],
          dark: mode === 'dark' ? grey[200] : grey[800],
        },
        action: {
          disabled: grey[400],
        },
        error: { main: mode === 'dark' ? red[500] : red[700] },
      },
    },
    i18n
  );

const theme = (type, i18n) => responsiveFontSizes(muiTheme(type, i18n));

export default theme;
