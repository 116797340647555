/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import PT from 'prop-types';
import axios from 'axios';
import { useImmer } from 'use-immer';
import { isEmpty, orderBy } from 'lodash';
import {
  parameterMetadataApi,
  environmentContextApi,
  handleApiError,
  parametersApi,
} from 'api';
import { formatTags } from 'utils';
import tagTypes from 'utils/constants/tagTypes';

export const GlobalTypesContext = React.createContext();

export const GlobalTypesProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const [assetTypeTrees, setAssetTypeTrees] = useImmer([]);
  const [globalTagsAndTypes, setGlobalTagsAndTypes] = useImmer(tagTypes);
  const [allDisciplines, setAllDisciplines] = useImmer([]);
  const pageLimit = 50;

  const allowedAssetTags = [
    'Category',
    'Calculation',
    'Report',
    'Client',
    'Workstage',
    'Sector',
    'Scope',
  ];
  const allowedParameterTags = ['Category', 'Calculation', 'Report'];
  const allowedTemplateTags = ['Discipline'];

  // eslint-disable-next-line consistent-return
  const getAssetTypeTree = async assetTypes => {
    const source = axios.CancelToken.source();
    const query = {
      asset_type_id: assetTypes,
    };
    try {
      const assetTypeTreeResponse = await parametersApi(
        `getAssetTypeTree`,
        query,
        source.token
      );
      if (assetTypeTreeResponse) {
        const { assetTypeTree } = assetTypeTreeResponse;
        return assetTypeTree;
      }
    } catch (err) {
      handleApiError(err);
    }
  };

  useEffect(() => {
    const fetchTrees = async () => {
      const response = await getAssetTypeTree([
        'a98d79a1-4f4a-42e5-ac28-0773387b3dfe', // site
        'fb7e2a70-3f23-44c0-a95e-79fd53f611f5', // network link
        'f61f7053-b28e-457a-b719-c04d7e1536a3', // masterplanning area
        'c8057e13-2d29-4841-b64a-b6e1c95d7b5d', // material
        '8882fd17-20bb-4e55-8d36-4a54dca1599a', // generic
      ]);
      setAssetTypeTrees(response);
    };
    if (isEmpty(assetTypeTrees) && isAuthenticated) fetchTrees();
  }, [assetTypeTrees, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getTags = async (typeId, typeIndex, after) => {
      const response = await parameterMetadataApi(
        'getTags',
        {
          page_limit: pageLimit,
          sort_by: 'name',
          order: 'asc',
          tag_type_id: typeId,
          after,
        },
        source.token
      ).catch(err => handleApiError(err, []));
      if (response && !didCancel) {
        const { tags, paging } = response;
        setGlobalTagsAndTypes(currTypes => {
          // eslint-disable-next-line no-param-reassign
          currTypes[typeIndex].tags = [...currTypes[typeIndex].tags, ...tags];
        });
        if (paging?.cursors?.after)
          await getTags(typeId, typeIndex, paging.cursors.after);
      }
    };

    const allTags = async () => {
      const tags = await Promise.all(
        globalTagsAndTypes.map(async type => {
          const tagsResponse = await parameterMetadataApi(
            'getTags',
            {
              page_limit: pageLimit,
              sort_by: 'name',
              order: 'asc',
              tag_type_id: type.id,
            },
            source.token
          );
          return tagsResponse;
        })
      );

      if (!isEmpty(tags) && !didCancel) {
        tags.forEach((tag, index) => {
          if (tag.paging?.cursors?.after)
            getTags(tag.tags[0].tagType.id, index, tag.paging.cursors.after);
          setGlobalTagsAndTypes(currTypes => {
            // eslint-disable-next-line no-param-reassign
            currTypes[index].tags = tag.tags;
          });
        });
      }
    };

    if (
      !isEmpty(globalTagsAndTypes) &&
      globalTagsAndTypes.every(type => isEmpty(type.tags)) &&
      isAuthenticated
    )
      allTags();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [isAuthenticated]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getAllDisciplines = async () => {
      try {
        const response = await environmentContextApi(
          'getDisciplines',
          {},
          source.token
        );
        if (!didCancel && response) {
          const { disciplines: disciplinesResponse } = response;
          const reformattedDisciplines = disciplinesResponse?.map(
            discipline => ({
              id: discipline.disciplineId,
              name: discipline.disciplineName,
              code: discipline.disciplineCode,
            })
          );

          setAllDisciplines(orderBy(reformattedDisciplines, ['name'], ['asc']));
        }
      } catch (err) {
        // do nothing
      }
    };

    if (isEmpty(allDisciplines) && isAuthenticated) getAllDisciplines();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [isAuthenticated]);

  const formattedTags = formatTags(globalTagsAndTypes);

  const assetTagsModifiedArr = formattedTags.filter(tagType =>
    allowedAssetTags.includes(tagType.name)
  );

  const sortedParametersTagsAndTypes = formattedTags.filter(tagType =>
    allowedParameterTags.includes(tagType.name)
  );
  const templateTypeTags = formattedTags
    .filter(tagType => allowedTemplateTags.includes(tagType.name))
    .map(type => {
      if (type.name === 'Discipline') {
        return { ...type, name: 'Disciplines' };
      }
      return type;
    });

  return (
    <GlobalTypesContext.Provider
      value={{
        assetTagsAndTypes: assetTagsModifiedArr,
        parameterTagsAndTypes: sortedParametersTagsAndTypes,
        allTagsAndTypes: globalTagsAndTypes,
        templateTypeTags,
        allDisciplines,
        getAssetTypeTree,
        assetTypeTrees,
        setAssetTypeTrees,
      }}
    >
      {children}
    </GlobalTypesContext.Provider>
  );
};

GlobalTypesProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
