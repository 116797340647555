import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { getSharepointInfo, getUserGuideInfo } from 'api/graph';
import formatFaqs from './helper';

export default () => {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const [faqLoading, setFaqLoading] = useState(true);
  const [faqIntroLoading, setFaqIntroLoading] = useState(true);
  const [faqIntro, setFaqIntro] = useState('');
  const [faq, setFaq] = useState([{ heading: '', content: '' }]);

  useEffect(() => {
    let didCancel = false;
    setFaqLoading(true);

    const getFiles = async path => {
      const fallbackPath = `/user-guide/faq-intro.md`;
      let faqIntroFile;
      try {
        faqIntroFile = await getUserGuideInfo(path);
      } catch (err) {
        if (err?.response?.status === 404) {
          await getFiles(fallbackPath);
        }
      }
      if (!didCancel && faqIntroFile) {
        setFaqIntro(faqIntroFile);
        setFaqIntroLoading(false);
      }
    };

    const getFaq = async () => {
      let data;
      try {
        const { value: faqs } = await getSharepointInfo(
          'f6977e80-ee2e-4521-81bd-c7548e46a541'
        );
        data = formatFaqs(faqs);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      if (!didCancel && data) {
        setFaq(data);
        setFaqLoading(false);
      }
    };

    if (isAuthenticated) {
      getFiles(`/user-guide/faq-intro.md`);
      getFaq();
    }

    return () => {
      didCancel = true;
    };
  }, [i18n.language, isAuthenticated]);

  return { faq, faqLoading, faqIntro, faqIntroLoading };
};
