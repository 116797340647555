import { Button } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textTransform: 'none',
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: lighten(theme.palette.primary.main, 0.3),
  },
}));

export default ActionButton;
