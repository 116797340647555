import { Suspense, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { Skeleton } from '@mui/material';
import { CookiesButton } from 'components/buttons';

export default function CookieConsent() {
  const [cookies, setCookie] = useCookies(['ddbCookiesPolicyAccepted']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = key => (
    <Suspense fallback={<Skeleton />}>
      <CookiesButton
        variant="outlined"
        onClick={() => {
          setCookie('ddbCookiesPolicyAccepted', true, {
            path: '/',
            maxAge: 31536000,
          });
          closeSnackbar(key);
        }}
        data-cy="accept-cookies-button"
      />
    </Suspense>
  );

  useEffect(() => {
    if (!cookies?.ddbCookiesPolicyAccepted)
      enqueueSnackbar(
        'We use cookies on our website. By continuing to use this site, without changing your settings, you consent to our use of cookies and third-party cookies',
        {
          persist: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          action,
        }
      );
  });
  return null;
}
