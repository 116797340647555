import { StepLabel, Stepper, Step, Typography } from '@mui/material';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

const HorizontalStepper = ({
  activeStep,
  steps,
  setActiveStep,
  actionButtons,
  wizard,
}) => {
  const { t } = useTranslation(['common']);

  const isNextDisabled = () => {
    return actionButtons[wizard].find(button => button.type === 'next')
      .disabled;
  };
  const isBackDisabled = () => {
    return actionButtons[wizard].find(button => button.type === 'back')
      .disabled;
  };

  return (
    <Stepper
      data-testid="horizontalStepper"
      activeStep={activeStep}
      sx={{
        padding: theme => theme.spacing(3),
      }}
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            data-testid="stepperLabel"
            optional={
              step.optional && (
                <Typography variant="caption" color="textSecondary">
                  {t('common:optional')}
                </Typography>
              )
            }
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              if (
                (index > activeStep && !isNextDisabled()) ||
                (index < activeStep && !isBackDisabled())
              ) {
                setActiveStep(index);
              }
            }}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

HorizontalStepper.propTypes = {
  steps: PT.arrayOf(PT.shape({ label: PT.string, optional: PT.bool }))
    .isRequired,
  activeStep: PT.number.isRequired,
  setActiveStep: PT.func.isRequired,
  actionButtons: PT.objectOf(
    PT.arrayOf(PT.shape({ type: PT.string, disabled: PT.bool }))
  ).isRequired,
  wizard: PT.string.isRequired,
};
export default HorizontalStepper;
