// eslint-disable react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { isEmpty, findIndex, uniqBy } from 'lodash';
import { parametersApi } from 'api';

export const ParametersContext = React.createContext();

export const ParametersProvider = ({ children }) => {
  const { t } = useTranslation(['errors', 'parameters']);
  const { projectId, assetId, parameterId } = useParams();
  const { pathname, search } = useLocation();
  const [parameters, setParameters] = useImmer([]);
  const [statistics, setStatistics] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [statsError, setStatsError] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [searchParams] = useSearchParams({
    order: 'asc',
    sort_by: 'parameter_type_name',
  });
  const [after, setAfter] = useState(null);
  const pageLimit = 50;
  const [previousSearch, setPreviousSearch] = useState('');
  const [previousAssetId, setPreviousAssetId] = useState('');
  const [previousParameterId, setPreviousParameterId] = useState('');
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  const getParameters = async () => {
    if (!after) setLoading(true);
    const source = axios.CancelToken.source();
    const query = {
      ...parsedSearch,
      project_id: projectId,
      page_limit: pageLimit,
      show_deleted: searchParams.get('show_deleted_parameters'),
    };
    if (assetId) {
      query.asset_id = assetId;
    }
    if (after) query.after = after;

    try {
      const response = await parametersApi(
        'getAllParameters',
        query,
        source.token
      );
      if (response) {
        const { parameters: params, paging } = response;
        setAfter(paging?.cursors?.after || null);
        setParameters(currentParameters =>
          uniqBy([...currentParameters, ...params], 'id')
        );
      }
      setLoading(false);
    } catch (err) {
      setError(err.response?.data);
      setLoading(false);
    }
  };

  if (parameterId && isEmpty(previousParameterId))
    setPreviousParameterId(parameterId);

  const updateParametersList = (editedPar, paramId) => {
    const index = findIndex(parameters, par => par.id === paramId);
    setParameters(currPars => {
      currPars[index] = editedPar; // eslint-disable-line no-param-reassign
    });
  };

  useEffect(() => {
    if (
      (pathname.includes('/parameters') &&
        search &&
        previousSearch !== search) ||
      (isEmpty(parameters) && parameterId) ||
      (pathname.includes('/parameters') &&
        isEmpty(search) &&
        !parameterId &&
        !assetId &&
        isEmpty(previousParameterId)) ||
      (previousAssetId !== assetId && assetId)
    ) {
      setAfter(null);
      setParameters([]);
      setPreviousSearch(search);
      setPreviousAssetId(assetId);
    }
  }, [projectId, searchParams, pathname, assetId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      after === null &&
      isEmpty(parameters) &&
      !loading &&
      pathname.includes('/parameters')
    ) {
      setLoading(true);
      getParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, parameters]);

  useEffect(() => {
    setStatsLoading(true);
    setStatsError({});
    const getStats = async () => {
      const source = axios.CancelToken.source();
      const query = {
        project_parameter: searchParams.get('project_parameter') || false,
        project_id: projectId,
      };
      if (assetId) query.asset_id = assetId;
      try {
        const response = await parametersApi(
          'getParametersStatistics',
          query,
          source.token
        );
        if (response) {
          const { statistics: stats } = response;
          setStatistics(stats);
        }
        setStatsLoading(false);
      } catch (err) {
        setStatsError({
          msg: `${t('parameters:projectStatistics')} ${t('errors:msg404')}`,
          details: t('parameters:statisticsError'),
        });
        setStatsLoading(false);
      }
    };

    if (projectId) getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, assetId, searchParams]);

  return (
    <ParametersContext.Provider
      value={{
        statistics,
        statsLoading,
        parameters,
        setParameters,
        pageLimit,
        loading,
        getParameters,
        error,
        updateParametersList,
        after,
        setAfter,
        statsError,
      }}
    >
      {children}
    </ParametersContext.Provider>
  );
};

ParametersProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
