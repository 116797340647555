import PT from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Link as LinkIcon } from '@mui/icons-material/';
import { useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import {
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Grid,
  Container,
  Box,
} from '@mui/material';
import { formatDate } from 'utils';
import { StyledIconButton } from 'components/buttons';
import { SwipeableDrawer } from 'components/drawer';
import { Person } from 'components/microsoft';
import Heading from './heading';

export default function NotificationsPanel({
  notificationList,
  notificationsOpen,
  handleNotificationsToggle,
}) {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { t, i18n } = useTranslation(['header']);
  return (
    <SwipeableDrawer
      open={notificationsOpen}
      onClose={handleNotificationsToggle}
      data-testid="notificationsPanel"
      ModalProps={{ keepMounted: notificationsOpen }}
    >
      <Container component="main" id="main-content-navigation" maxWidth="sm">
        <Heading handleNotificationsToggle={handleNotificationsToggle} />
        {isAuthenticated ? (
          <List
            sx={{ width: '100%', height: '100%', overflow: 'auto' }}
            dense
            data-cy="notifications-list"
            disablePadding
          >
            {notificationList && notificationList.length ? (
              notificationList.map((message, index) => (
                <ListItem
                  key={message.id}
                  alignItems="flex-start"
                  sx={{ paddingTop: 0 }}
                  data-cy={`notification-item-${index}`}
                  disableGutters
                  divider={index < notificationList.length - 1}
                >
                  <ListItemAvatar
                    sx={{ padding: theme => theme.spacing(1, 2) }}
                  >
                    <Person
                      data-testid={`notificationsAvatar-${message.photo}`}
                      userId={message.photo}
                      personCardInteraction={2}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography data-testid={`notificationsTitle-${index}`}>
                          {message.title}
                        </Typography>
                        {message.link && (
                          <StyledIconButton
                            title={`${t('common:link')} - ${message.title}`}
                            onClick={() => {
                              if (message.link.startsWith('/'))
                                navigate(message.link);
                              else window.open(message.link);
                              handleNotificationsToggle();
                            }}
                            icon={
                              <LinkIcon
                                fontSize="small"
                                data-testid={`notificationLink-${index}`}
                              />
                            }
                          />
                        )}
                      </Grid>
                    }
                    secondary={
                      <>
                        <span
                          data-testid={`notificationsMessage-${index}`}
                          id={`notificationsMessage-${index}`}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: message.text,
                          }}
                        />
                        {message.date && (
                          <>
                            <br />
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              data-testid="notificationsDate"
                            >
                              {formatDate(
                                message.date,
                                undefined,
                                i18n.language
                              )}
                            </Typography>
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: theme => theme.spacing(1, 0),
                }}
              >
                <CircularProgress
                  data-testid="loadingNotifications"
                  size={32}
                />
              </Box>
            )}
          </List>
        ) : (
          <Typography sx={{ padding: theme => theme.spacing(2) }}>
            {t('header:noNotifications')}
          </Typography>
        )}
      </Container>
    </SwipeableDrawer>
  );
}

NotificationsPanel.propTypes = {
  notificationList: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
      createdBy: PT.string.isRequired,
      photo: PT.string,
      title: PT.string.isRequired,
      text: PT.string.isRequired,
      date: PT.string,
      link: PT.string,
    })
  ),
  notificationsOpen: PT.bool.isRequired,
  handleNotificationsToggle: PT.func.isRequired,
};

NotificationsPanel.defaultProps = { notificationList: null };
