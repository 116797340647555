import { useState, useEffect, useContext } from 'react';
import { useImmer } from 'use-immer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { referenceDataApi, handleApiError } from 'api';
import { SourcesContext } from 'context';

export default () => {
  const { projectId } = useParams();
  const [sourceTypesResponse, setSourceTypesResponse] = useImmer([]);
  const [isLoading, setIsLoading] = useState(false);
  const { openAddSource } = useContext(SourcesContext);

  useEffect(() => {
    setSourceTypesResponse([]);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getSourceTypes = async after => {
      setIsLoading(true);
      const query = {
        project_id: projectId,
        sort_by: 'name',
        order: 'asc',
      };
      if (after) query.after = after;
      const response = await referenceDataApi(
        `getSourceTypes`,
        query,
        source.token
      ).catch(err => {
        handleApiError(err, []);
      });
      if (response) {
        const { sourceTypes, paging } = response;
        if (sourceTypes && !didCancel) {
          setSourceTypesResponse(curr => [...curr, ...sourceTypes]);
          setIsLoading(false);
        }
        if (!didCancel && paging?.cursors?.after)
          getSourceTypes(paging?.cursors?.after);
      }
    };

    if (projectId) {
      getSourceTypes();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, openAddSource]);

  return {
    sourceTypesResponse,
    isLoading,
  };
};
