import PT from 'prop-types';
import { Stack, Typography, MenuItem } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { StyledSwitch } from 'components/styles/switch-styles';
import { useContext } from 'react';
import { ThemeContext } from 'context';

const ToggleFilter = ({ type }) => {
  const { localTheme } = useContext(ThemeContext);
  const { t } = useTranslation(['common']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  let checked;
  if (type === 'deletedAssetFilter') {
    checked = parsedSearch?.show_deleted_assets === 'true';
  } else if (type === 'deletedParamFilter') {
    checked = parsedSearch?.show_deleted_parameters === 'true';
  }
  const onToggle = e => {
    const types = {
      deletedAssetFilter: 'show_deleted_assets',
      deletedParamFilter: 'show_deleted_parameters',
    };
    searchParams.set(types[type], e.target.checked ? 'true' : 'false');
    setSearchParams(searchParams);
  };

  return (
    <MenuItem
      tabIndex={0}
      onKeyDown={event => {
        if (event.key === ' ' || event.key === 'Enter') {
          onToggle({ target: { checked: !checked } });
        }
      }}
    >
      <Stack direction="row">
        <StyledSwitch
          palette={localTheme}
          aria-label="toggle-switch"
          size="small"
          checked={checked}
          onChange={onToggle}
        />
        <Typography variant="body2">{t('common:showRemoved')}</Typography>
      </Stack>
    </MenuItem>
  );
};
ToggleFilter.propTypes = {
  type: PT.string,
};

ToggleFilter.defaultProps = {
  type: '',
};
export default ToggleFilter;
