import { Box, Collapse, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  height: `calc(100vh - 64px - 56px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px - 56px)`,
  },
}));
export const StyledGrid = styled(Grid)(() => ({
  height: '100%',
}));

export const StyledCollapse = styled(Collapse)(() => ({
  '& .MuiCollapse-wrapperInner': { width: '100%' },
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
  height: '100%',
  paddingLeft: 1,
}));
