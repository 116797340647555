import { parametersApi } from 'api';
import { useParams } from 'react-router-dom';
import { findIndex } from 'lodash';
import { useContext } from 'react';
import { AssetContext } from 'context';
import { useTranslation } from 'react-i18next';
import validateAssetName from 'utils/validate-asset-name';

export default (setOpenEditingForm, setAssetError, asset, setAsset) => {
  const { assetId } = useParams();
  const { setAllAssets, allAssets } = useContext(AssetContext);
  const { t } = useTranslation();

  const sendUpdatedValues = ({ value }) => {
    const updateRevision = async () => {
      setAssetError(undefined);
      try {
        const response = await parametersApi('patchAsset', {
          asset_id: assetId,
          body: {
            name: value,
          },
        });
        if (response) {
          setOpenEditingForm(false);
          setAsset(response.asset);
          setAllAssets(curr => {
            const indexOfUpdate = findIndex(
              curr,
              existing => existing.id === asset.id
            );
            curr[indexOfUpdate] = { ...curr[indexOfUpdate], name: value }; // eslint-disable-line no-param-reassign
          });
        }
      } catch (err) {
        setAssetError(err?.response?.data);
      }
    };
    updateRevision();
  };

  const validateEditing = values => {
    const errors = {};
    const error = validateAssetName(
      values.value,
      asset.parent,
      asset.assetType.id,
      allAssets
    );
    if (error) errors.value = t(error);
    return errors;
  };

  return {
    validateEditing,
    sendUpdatedValues,
  };
};
