import PT from 'prop-types';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { StyledIconButton } from '../../../../../buttons';

export default function Heading({ handleNotificationsToggle }) {
  const { t } = useTranslation(['header']);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ padding: theme => theme.spacing(2), paddingBottom: 0 }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{
            fontWeight: theme => theme.typography.fontWeightBold,
            paddingTop: 0,
          }}
        >
          {t('header:notifications')}
        </Typography>
      </Grid>
      <Grid item>
        <StyledIconButton
          title={t('common:buttons.close')}
          onClick={handleNotificationsToggle}
          aria-label="close-button"
          icon={<Close />}
          data-cy="close-notifications-icon"
        />
      </Grid>
    </Grid>
  );
}

Heading.propTypes = {
  handleNotificationsToggle: PT.func.isRequired,
};
