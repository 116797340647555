import PT from 'prop-types';
import { DatePicker as MuiRffDatePicker } from 'mui-rff';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { localeDateMap } from 'i18n/languages';

function DatePicker({
  label,
  required,
  name,
  size,
  format,
  variant,
  error,
  helperText,
  ...rest
}) {
  const { t, i18n } = useTranslation(['common']);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeDateMap[i18n.language]}
    >
      <MuiRffDatePicker
        disableMaskedInput
        dateFunsUtils={DateFnsUtils}
        label={label}
        required={required || true}
        name={name}
        size={size}
        inputFormat={format}
        locale={localeDateMap[i18n.language]}
        okLabel={t('buttons.apply')}
        cancelLabel={t('buttons.cancel')}
        helperText={helperText}
        inputProps={{
          variant,
          error,
          placeholder: rest?.placeholder || format,
        }}
        minDate={new Date('1000-01-01')}
        maxDate={new Date('2500-01-01')}
        {...rest}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  label: PT.string.isRequired,
  required: PT.bool,
  name: PT.string.isRequired,
  size: PT.string,
  format: PT.string,
  variant: PT.string,
  helperText: PT.string,
  error: PT.bool,
};

DatePicker.defaultProps = {
  required: true,
  size: 'small',
  format: 'd MMM yyyy',
  variant: 'standard',
  helperText: '',
  error: false,
};

export default DatePicker;
