import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Button,
  Typography,
  CircularProgress,
  Chip,
} from '@mui/material';
import { BasicDropdown, ListBoxComponent } from 'components/pickers';
import { isEmpty } from 'lodash';
import StyledBox from './filter-styles';
import { renderCheckboxOption } from './helper';

function Filters({
  sortByOptions,
  filters,
  applyFilters,
  filtersMap,
  handleOnChange,
  handleOnCancel,
  clearFilters,
}) {
  const { t } = useTranslation();

  const getOptionLabel = option => {
    if (!isEmpty(sortByOptions)) {
      const [sortOption] = sortByOptions?.filter(
        item => item.sortBy === option.sortBy
      );
      return t(sortOption?.label);
    }
    return '';
  };

  return (
    <StyledBox data-testid="filtersList">
      <Button
        sx={{ marginTop: theme => theme.spacing(1), float: 'right' }}
        color="primary"
        variant="contained"
        size="small"
        disableElevation
        onClick={clearFilters}
      >
        {t('buttons.clearFilters')}
      </Button>
      <BasicDropdown
        data-testid="sortDropdown"
        label={t('sortBy')}
        options={sortByOptions}
        disableClearable
        value={filters?.sort_by}
        // eslint-disable-next-line consistent-return
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(opt, val) => opt.sortBy === val.sortBy}
        onChange={(event, value) => handleOnChange(value, 'sort_by')}
        size="small"
        preselectedOptionLabel={getOptionLabel(filters?.sort_by)}
      />
      <Divider sx={{ margin: theme => theme.spacing(1.5, 0, 1) }} />
      <Typography
        sx={{
          padding: theme => theme.spacing(1, 0, 0, 0),
          color: 'text.secondary',
        }}
        variant="body2"
      >
        {t('filterBy')}
      </Typography>
      {!isEmpty(filtersMap) &&
        filtersMap.map(({ id, label, key, ...rest }) => (
          <BasicDropdown
            sx={{ margin: theme => theme.spacing(1.5, 0, 1) }}
            key={id}
            disableListWrap
            disableCloseOnSelect
            ListboxComponent={ListBoxComponent}
            data-testid="filtersDropdown"
            value={filters[key]}
            getOptionLabel={option => option.name}
            label={t(id)}
            size="small"
            multiple
            // eslint-disable-next-line no-confusing-arrow
            onChange={(event, value) => handleOnChange(value, key)}
            renderOption={renderCheckboxOption}
            renderTags={(value, getTagProps) =>
              value.map(({ name }, index) => (
                <Chip
                  data-testid="chipTags"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  avatar={
                    name ? null : (
                      <CircularProgress
                        data-testid="loadingCircle"
                        size={20}
                        thickness={6}
                      />
                    )
                  }
                  label={name || ''}
                  {...getTagProps({ index })}
                />
              ))
            }
            {...rest}
          />
        ))}

      <Button
        sx={{ marginTop: theme => theme.spacing(1), float: 'right' }}
        onClick={applyFilters}
        color="primary"
        variant="contained"
        size="small"
        disableElevation
      >
        {t('buttons.apply')}
      </Button>
      <Button
        sx={{ marginTop: theme => theme.spacing(1), float: 'right' }}
        onClick={handleOnCancel}
        size="small"
        disableElevation
      >
        {t('buttons.cancel')}
      </Button>
    </StyledBox>
  );
}

Filters.propTypes = {
  sortByOptions: PT.arrayOf(PT.shape({})).isRequired,
  filters: PT.objectOf(PT.oneOfType([PT.object, PT.array])).isRequired,
  applyFilters: PT.func.isRequired,
  filtersMap: PT.arrayOf(PT.shape({})).isRequired,
  handleOnChange: PT.func.isRequired,
  handleOnCancel: PT.func.isRequired,
  clearFilters: PT.func.isRequired,
};

export default Filters;
