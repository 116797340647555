import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { handleApiError, parametersApi } from 'api';

export default () => {
  const [dataSetValid, setDataSetValid] = useState({});
  const [validityLoading, setValidityLoading] = useState(true);
  const { dataSetId } = useParams();

  useEffect(() => {
    const didCancel = false;
    const source = axios.CancelToken.source();
    const getDataSetStatus = async () => {
      try {
        const response = await parametersApi(
          'getDataSetValid',
          { data_set_id: dataSetId },
          source.token
        );
        if (response && !didCancel) {
          const { dataSet: dataSetResponse } = response;
          setDataSetValid({
            valid: dataSetResponse.valid,
            incorrectAssetCount: dataSetResponse.details?.incorrectAssetCount,
            missingParameters: dataSetResponse.details?.missingParameters,
          });
        }
      } catch (err) {
        handleApiError(err, []);
      } finally {
        setValidityLoading(false);
      }
    };

    if (dataSetId) {
      setValidityLoading(true);
      getDataSetStatus();
    }
  }, [dataSetId]);

  return {
    dataSetValid,
    validityLoading,
  };
};
