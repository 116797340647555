import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { isString } from 'lodash';
import { Link } from '@mui/material';
import html from 'remark-html';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeHighlight from 'rehype-highlight';
import powershell from 'highlight.js/lib/languages/powershell';
import StyledMarkdown from './markdown-styles';
import { CopyButton } from '../buttons';
import 'highlight.js/styles/github-dark.css';

const RouterLink = ({ href, children, toggleUserGuide, ...rest }) => {
  const navigate = useNavigate();
  if (href.match(/^(https?:)?\/\//))
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  if (href.startsWith('#') || href.startsWith('mailto')) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component="button"
      variant="body1"
      underline="always"
      color="textSecondary"
      onClick={() => {
        navigate(href);
        if (toggleUserGuide) toggleUserGuide();
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

RouterLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string.isRequired,
  toggleUserGuide: PropTypes.func,
};

RouterLink.defaultProps = {
  toggleUserGuide: null,
};

const getCode = arr =>
  arr.reduce((final, currV) => {
    if (currV.type !== 'code') getCode(currV.props.children);
    else final.push(...currV.props.children);
    return final;
  }, []);

const formatText = arr =>
  arr.reduce((final, currentV) => {
    if (isString(currentV)) final.push(currentV);
    if (currentV?.props?.children) final.push(...currentV.props.children);
    return final;
  }, []);

// eslint-disable-next-line react/prop-types
const Pre = ({ children: code }) => {
  const textArr = getCode(code);
  const text = formatText(textArr);

  return (
    <pre>
      <div className="code-copy-btn">
        <CopyButton toBeCopied={text.join('')} />
      </div>
      {code}
    </pre>
  );
};
export default function MarkdownElement({
  md,
  className,
  toggleUserGuide,
  ...rest
}) {
  return (
    <StyledMarkdown
      remarkPlugins={[html]}
      rehypePlugins={[
        [rehypeHighlight, { languages: { powershell } }],
        rehypeRaw,
        rehypeSlug,
      ]}
      components={{
        // eslint-disable-next-line react/display-name
        a: props => (
          <RouterLink {...props} toggleUserGuide={toggleUserGuide} {...rest} />
        ),
        pre: Pre,
      }}
    >
      {md}
    </StyledMarkdown>
  );
}

MarkdownElement.propTypes = {
  md: PropTypes.string.isRequired,
  className: PropTypes.string,
  toggleUserGuide: PropTypes.func,
};

MarkdownElement.defaultProps = {
  className: null,
  toggleUserGuide: null,
};
