const filterAssetsByParent = (phDict, filteredByTypeAssets, ph) => {
  const parentPh = phDict[ph.parentAssetPlaceholderId];
  if (!parentPh) {
    return filteredByTypeAssets;
  }
  const possibleParents = parentPh.possibleAssets || [];

  const result = filteredByTypeAssets.filter(asset => {
    if (asset.parent === null) {
      return asset;
    }

    return possibleParents.some(
      possibleParent => possibleParent.id === asset.parent
    );
  });
  return result;
};

export default filterAssetsByParent;
