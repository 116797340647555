import { Suspense } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import PageLayout from 'components/layout';
import Sidebar from 'components/sidebar';
import { HistoryProvider } from 'context';
import HistoryContent from './history-content/history-content';

const HistoryPage = () => {
  return (
    <main>
      <PageLayout aria-label="history-page">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Suspense fallback={null}>
                  <Sidebar id="history" />
                </Suspense>
                <Suspense fallback={null}>
                  <HistoryProvider>
                    <HistoryContent />
                    <Outlet />
                  </HistoryProvider>
                </Suspense>
              </>
            }
          />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
      </PageLayout>
    </main>
  );
};

export default HistoryPage;
