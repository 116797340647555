import { useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { SnackbarMessage } from 'components/snackbars';

function ErrorComponent({ error }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        <Suspense fallback={null}>
          <SnackbarMessage
            errorMessage={error.errorMessage}
            errorCode={error.errorCode}
          />
        </Suspense>,
        {
          persist: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
          preventDuplicate: true,
        }
      );
    }
    return () => {
      closeSnackbar();
    };
  }, [closeSnackbar, enqueueSnackbar, error]);

  return null;
}

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    errorCode: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
  }),
};
ErrorComponent.defaultProps = { error: { errorCode: '', errorMessage: '' } };

export default ErrorComponent;
