import formatDate from './format-date';

export default (value, locale) => {
  const regex =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
  if (value == null) return '';
  if (regex.test(value)) {
    const dateValue = new Date(value);
    return formatDate(dateValue, 'do MMMM yyyy');
  }
  if (value instanceof Date) return formatDate(value, 'do MMMM yyyy', locale);

  return value;
};
