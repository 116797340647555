import { useEffect } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import FinalForm from 'components/forms';
import TextField from 'components/pickers/final-form-fields/text-field';
import Select from 'components/pickers/final-form-fields/select';
import DatePicker from 'components/pickers/final-form-fields/date-picker';
import config from 'config';
import { useSnackbar } from 'notistack';
import useAddSource from './hooks/use-add-source';

const AddSource = ({
  openAddSource,
  setOpenAddSource,
  setOpenEditingForm,
  setSelectedSource,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    allSourceTypes,
    validateAddSourceInputs,
    postNewSource,
    openSnackBar,
  } = useAddSource(
    openAddSource,
    setOpenAddSource,
    setOpenEditingForm,
    setSelectedSource
  );

  useEffect(() => {
    if (openSnackBar) {
      enqueueSnackbar(t('addSource.sourceAdded'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSnackBar]);

  return (
    <FinalForm
      aria-label="add-source"
      title={t('addSource.newSource')}
      initialValues={{}}
      formFields={[
        {
          size: 12,
          field: <TextField required label={t('source')} name="title" />,
        },
        {
          size: 12,
          field: (
            <TextField
              required
              label={t('addSource.source.reference')}
              name="reference"
            />
          ),
        },
        {
          size: 12,
          field: (
            <Select
              label={t('addSource.source.sourceType')}
              name="sourceType"
              options={allSourceTypes}
            />
          ),
        },
        {
          size: 12,
          field: (
            <DatePicker
              placeholder={t('common:selectDate')}
              label={t('addSource.source.publicationDate')}
              name="publicationDate"
            />
          ),
        },
        config.featureFlags.isSourceCommentEnabled && {
          size: 12,
          field: (
            <TextField label={t('addSource.source.comment')} name="comment" />
          ),
        },
      ]}
      validate={validateAddSourceInputs}
      onClose={() => {
        setOpenAddSource(false);
        setOpenEditingForm(true);
      }}
      onSubmit={postNewSource}
      conditions={[
        {
          when: 'sourceType',
          type: 'filter',
          is: [
            '6a1292c7-a245-41cf-8872-46feb9a7fd11',
            'f266bbf1-0126-40b9-8fda-9f92a2731af9',
          ], // all types apart from Assumption & Derived value
          size: 12,
          field: (
            <TextField
              required
              label={t('addSource.source.directoryUrl')}
              name="url"
            />
          ),
        },
      ]}
    />
  );
};

AddSource.propTypes = {
  openAddSource: PT.bool.isRequired,
  setOpenAddSource: PT.func.isRequired,
  setOpenEditingForm: PT.func,
  setSelectedSource: PT.func.isRequired,
};

AddSource.defaultProps = {
  setOpenEditingForm: () => {},
};

export default AddSource;
