import { createRef } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

function SnackProvider({ children }) {
  const notistackRef = createRef();

  return (
    <SnackbarProvider
      maxSnack={2}
      preventDuplicate
      autoHideDuration={5000}
      ref={notistackRef}
    >
      {children}
    </SnackbarProvider>
  );
}
SnackProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SnackProvider;
