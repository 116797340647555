import { difference } from 'lodash';

const addPhInfoToAssetInstances = (
  assetInstancesArr,
  templateAssetsArr,
  aph,
  parentDict
) => {
  const assetInstances = assetInstancesArr.filter(instance => {
    if (aph.parentAssetPlaceholderId === null && aph.assetType === null) {
      return false;
    }

    if (!parentDict[instance.parent]?.typeIndex) {
      return true;
    }

    return (
      instance.assetType?.id === aph.assetType?.id &&
      instance.assetSubType?.id === aph.assetSubType?.id &&
      parentDict[instance.parent].typeIndex.id === aph.parentAssetPlaceholderId
    );
  });

  assetInstances.forEach(instance => {
    // eslint-disable-next-line no-param-reassign
    parentDict[instance.id] = {
      ...instance,
      typeIndex: aph,
    };
  });

  const reducedAssetInstances = difference(assetInstancesArr, assetInstances);

  const childrenPh = templateAssetsArr.filter(
    templateAsset => templateAsset.parentAssetPlaceholderId === aph.id
  );

  childrenPh.forEach(child => {
    addPhInfoToAssetInstances(
      reducedAssetInstances,
      templateAssetsArr,
      child,
      parentDict
    );
  });
};

export default addPhInfoToAssetInstances;
