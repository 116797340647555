import { Tabs, Typography } from '@mui/material';
import { styled, darken, lighten } from '@mui/material/styles';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.background.default, 0.1)
      : darken(theme.palette.background.default, 0.1),
  width: 60,
  '& .MuiTabs-indicator': {
    width: '5px',
    left: 0,
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.5rem',
  marginLeft: theme.spacing(0.75),
  width: theme.spacing(7),
}));

export { StyledTabs, StyledTypography };
