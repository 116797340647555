import { Suspense } from 'react';
import { useMatch } from 'react-router-dom';
import { useMediaQuery, AppBar, Toolbar, Avatar, Box } from '@mui/material';
import UserGuide from '../user-guide';
import HomeButton from './home';
import User from './user';
import { MobileMenu, MainMenu } from './menu';
import handleUserGuide from './hooks';
import handleNotifications from './menu/notifications/hooks';
import NotificationsPanel from './menu/notifications/panel';
import Docs from './docs';

function HeaderToolbar() {
  const screenSize = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { toggleUserGuide, userGuideOpen } = handleUserGuide();
  const notifications = handleNotifications();
  const matchDocs = useMatch('/docs/*');
  const matchIntegrations = useMatch('/integrations/*');

  return (
    <>
      <AppBar sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Suspense fallback={null}>
            <HomeButton data-cy="homeButton" />
            {(matchDocs || matchIntegrations) && (
              <Docs
                match={
                  matchDocs?.pathnameBase || matchIntegrations?.pathnameBase
                }
              />
            )}
          </Suspense>
          <Box sx={{ flexGrow: 1 }} />
          {screenSize ? (
            <MainMenu
              toggleUserGuide={toggleUserGuide}
              notifications={notifications}
            />
          ) : (
            <Suspense fallback={null}>
              <MobileMenu
                toggleUserGuide={toggleUserGuide}
                notifications={notifications}
              />
            </Suspense>
          )}
          <Suspense fallback={<Avatar />}>
            <User />
          </Suspense>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Suspense fallback={null}>
        <UserGuide
          userGuideOpen={userGuideOpen}
          toggleUserGuide={toggleUserGuide}
        />
        <NotificationsPanel {...notifications} />
      </Suspense>
    </>
  );
}

export default HeaderToolbar;
