import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { legal } from './links';

function Legal() {
  const { t } = useTranslation(['api-docs', 'footer']);

  return (
    <>
      <Typography component="h2" gutterBottom>
        {t('footer:footerLegal')}
      </Typography>
      <ul data-cy="legal-links">
        {legal.map(({ text, name, ...rest }) => (
          <li key={text}>
            <Link
              underline="none"
              color="textSecondary"
              variant="body2"
              data-cy={name}
              {...rest}
            >
              {t(text)}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Legal;
