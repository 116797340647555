import { Feedback as FeedbackIcon } from '@mui/icons-material';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';
import config from 'config';
import { StyledIconButton } from '../../../buttons';

function Feedback({ title, handleClose }) {
  const { t } = useTranslation(['header']);

  return title ? (
    <MenuItem
      component="a"
      color="inherit"
      data-cy="feedback-button"
      onClick={() => {
        window.open(config.url.feedback, '_blank');
        handleClose();
      }}
      aria-label={t('header:provideFeedback')}
    >
      <FeedbackIcon data-testid="feedback" />
      <Typography
        variant="button"
        sx={{ paddingLeft: theme => theme.spacing(1) }}
      >
        {title}
      </Typography>
    </MenuItem>
  ) : (
    <StyledIconButton
      title={t('header:provideFeedback')}
      component="a"
      color="inherit"
      onClick={() => window.open(config.url.feedback, '_blank')}
      aria-label={t('header:provideFeedback')}
      icon={<FeedbackIcon data-testid="feedback" />}
      data-cy="feedback-button"
    />
  );
}

Feedback.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Feedback.defaultProps = {
  title: null,
  handleClose: null,
};

export default Feedback;
