import { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import { CircularProgress, Grid, Divider, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

export default function InfinityScroll({
  hasMore,
  children,
  allLoaded,
  setHasMore,
  notFoundComponent,
  isNotFound,
  loadFavourites,
}) {
  const loader = useRef(null);
  const { t } = useTranslation(['common']);
  const handleObserver = useCallback(entries => {
    const target = entries[0];

    if (target.isIntersecting) {
      setHasMore(prev => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <div aria-label="infinite-scroll">
      {isNotFound ? notFoundComponent : children}
      <div ref={loader}>
        {hasMore && !isNotFound && !loadFavourites && (
          <Grid
            container
            justifyContent="center"
            sx={{ paddingTop: theme => theme.spacing(2) }}
          >
            <CircularProgress
              size={60}
              thickness={6}
              color="secondary"
              aria-label="loading-more"
            />
          </Grid>
        )}
      </div>
      {((allLoaded && !isNotFound) || loadFavourites) && (
        <Divider
          aria-label="showing-all"
          textAlign="center"
          sx={{
            paddingBottom: theme => theme.spacing(15),
            paddingTop: theme => theme.spacing(5),
            margin: theme => theme.spacing(2),
          }}
        >
          <CheckCircle fontSize="large" color="primary" />
          <Typography>{t('showingAllResults')}</Typography>
        </Divider>
      )}
    </div>
  );
}

InfinityScroll.propTypes = {
  hasMore: PT.bool,
  allLoaded: PT.bool.isRequired,
  children: PT.node.isRequired,
  setHasMore: PT.func.isRequired,
  notFoundComponent: PT.node.isRequired,
  isNotFound: PT.bool.isRequired,
  loadFavourites: PT.bool.isRequired,
};

InfinityScroll.defaultProps = {
  hasMore: false,
};
