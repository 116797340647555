import { Autocomplete as MuiRffAutocomplete } from 'mui-rff';
import { TextField } from '@mui/material';
import PT from 'prop-types';

const Autocomplete = props => {
  const {
    label,
    name,
    options,
    getOptionLabel,
    getOptionValue,
    required,
    variant,
    error,
    helperText,
    ...rest
  } = props;

  return (
    <MuiRffAutocomplete
      label={label}
      name={name}
      options={options}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      renderInput={params => {
        return (
          <TextField
            {...params}
            required={required}
            label={label}
            variant={variant}
            name={name}
            error={error}
            helperText={helperText}
          />
        );
      }}
      {...rest}
    />
  );
};

Autocomplete.propTypes = {
  label: PT.string.isRequired,
  name: PT.string.isRequired,
  options: PT.arrayOf(PT.shape({ id: PT.string })).isRequired,
  getOptionLabel: PT.func,
  getOptionValue: PT.func,
  required: PT.bool,
  variant: PT.string,
  error: PT.bool,
  helperText: PT.string,
};

Autocomplete.defaultProps = {
  getOptionLabel: option => option.id,
  getOptionValue: option => option.id,
  required: true,
  variant: 'standard',
  error: false,
  helperText: '',
};

export default Autocomplete;
