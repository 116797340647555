/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-confusing-arrow */
import TreeItem from 'components/tree/tree-item';
import PT, { string } from 'prop-types';
import { isEmpty } from 'lodash';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import {
  ChevronRight,
  ExpandMore,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { CircularProgress, Checkbox, Grid, Radio } from '@mui/material';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import qs from 'qs';
import { useContext } from 'react';
import { AssetContext, ThemeContext } from 'context';
import { useTranslation } from 'react-i18next';
import StyledTreeIconButton from './tree-items-styles';

const TreeItems = ({
  node,
  expanded,
  isHierarchy,
  setExpanded,
  dataSet,
  updateSelection,
  selectedAssets,
  onAddAssetClick,
  onEditClick,
  onDoneClick,
  textFieldError,
  onClearClick,
  selectedNode,
  onRemoveAssetClick,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const searchString = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const { theAsset, getAssets, assetsLoading, allAssets, isExpanding } =
    useContext(AssetContext);
  const showDeleted = parsedSearch.show_deleted_assets === 'true';
  delete searchString.project_parameter;
  const { localTheme } = useContext(ThemeContext);
  const { t } = useTranslation(['common,templates']);

  const onOpenClick = () => {
    if (!dataSet) {
      if (!isHierarchy) return;
      if (!node.id.includes('_')) {
        if (node.id !== theAsset.id) {
          const selected = allAssets.find(asset => asset.id === node.id);
          const chunkSize = 99;
          // eslint-disable-next-line max-depth
          Array.from(
            { length: Math.ceil(selected.children.length / chunkSize) },
            (_, i) =>
              getAssets({
                asset_id: selected.children.slice(
                  i * chunkSize,
                  i * chunkSize + chunkSize
                ),
              })
          );
        }
      }
      setExpanded(curr => [...curr, node.id]);
    } else {
      setExpanded(curr => [...curr, node.id]);
    }
  };

  const onNodeToggle = () => {
    if (!dataSet) {
      if (!isHierarchy) return;
      if (!node.id.includes('_')) {
        navigate({
          pathname: `asset/${node.id}/parameters`,
          search: `?${createSearchParams({
            ...searchString,
          })}`,
        });
      }
    }
  };

  const labelInfo = showDeleted
    ? node.children?.length
    : node.children?.filter(asset => asset?.deletedAt === null).length;
  const deletedStyleColor = theme =>
    showDeleted && node?.deletedAt
      ? theme.palette.secondary.dark
      : theme.palette.primary.main;

  const deletedChildren = node?.children?.every(type => {
    if (!isEmpty(type.children)) {
      return !type?.children?.every(child => {
        return child?.deletedAt !== null;
      });
    }
    return true;
  });

  const findSelectedAssets = nodeId => {
    const foundAsset = selectedAssets.find(
      selectedAsset => selectedAsset?.id === nodeId
    );
    return !!foundAsset;
  };

  return (
    (!isHierarchy ||
      showDeleted ||
      node?.isAssetInstance ||
      node.isTypeOnly ||
      (!showDeleted &&
        !node?.children?.every(child => child.deletedAt !== null))) && (
      <TreeItem
        key={node?.id}
        nodeId={node?.id}
        node={node}
        labelText={
          dataSet && !node.isAssetInstance
            ? `${node.name} (${
                // eslint-disable-next-line react/prop-types
                node.setInfo.length >= 2
                  ? t('templates:wizard:selectOneOfEachSubType')
                  : node.setInfo[0]?.count
                  ? `${t('common:select')} ${node.setInfo[0]?.count}`
                  : t('templates:wizard:minRequired')
              })`
            : node?.name
        }
        classes={{
          label: node.isAssetInstance ? 'instanceLabel' : 'typeLabel',
          iconContainer:
            node.isAssetInstance && dataSet
              ? 'dataSetInstanceLabel'
              : !node.isAssetInstance && dataSet
              ? 'dataSetTypeLabel'
              : 'templateDetailsTree',
        }}
        labelInfo={
          node?.children?.length > 0 && !node?.isAssetInstance
            ? `(${labelInfo})`
            : ''
        }
        labelIcon={
          showDeleted &&
          node.deletedAt && (
            <RemovedAssetIcon
              sx={{
                paddingRight: theme => theme.spacing(0.5),
                color: theme => theme.palette.secondary.dark,
              }}
            />
          )
        }
        onLabelClick={onNodeToggle}
        isAssetInstance={node.isAssetInstance}
        deleted={node.deletedAt}
        icon={
          <Grid container>
            <Grid item xs={dataSet ? 6 : 12}>
              {(assetsLoading &&
                node.hasChildren &&
                isEmpty(node.children) &&
                expanded.includes(node.id)) ||
              (assetsLoading &&
                (isExpanding || selectedNode === node.id) &&
                node.id === theAsset.id) ? (
                <CircularProgress size="50%" aria-label="loading-spinner" />
              ) : !showDeleted && node.isAssetInstance && !deletedChildren ? (
                <div aria-label="deleted-children" />
              ) : (node.hasChildren || !isEmpty(node.children)) &&
                !expanded.includes(node.id) ? (
                <StyledTreeIconButton
                  sx={{ padding: dataSet ? 0.75 : 1.25 }}
                  mode={localTheme}
                  title={`Expand ${node.name}`}
                  data-cy="tree-expand-icon"
                  onClick={onOpenClick}
                  icon={<ChevronRight />}
                />
              ) : (
                (node.hasChildren || !isEmpty(node.children)) &&
                expanded.includes(node.id) && (
                  <StyledTreeIconButton
                    sx={{ padding: dataSet ? 0.75 : 1.25 }}
                    mode={localTheme}
                    data-cy="tree-collapse-icon"
                    title={`Collapse ${node.name}`}
                    onClick={() => {
                      setExpanded(curr =>
                        curr.filter(nodes => nodes !== node.id)
                      );
                    }}
                    icon={<ExpandMore />}
                  />
                )
              )}
            </Grid>
            {dataSet && node.isAssetInstance && (
              <Grid item xs={6}>
                {node.assetTypeCount === 1 && !node.multipleSubtypes ? (
                  <Radio
                    value={node.id}
                    checked={findSelectedAssets(node.id)}
                    onClick={() => updateSelection(node)}
                  />
                ) : (
                  <Checkbox
                    data-cy="select-asset"
                    aria-label="select-asset"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={{
                      marginRight: theme => theme.spacing(2),
                      backgroundColor: 'primary',
                    }}
                    checked={findSelectedAssets(node.id)}
                    onClick={() => updateSelection(node)}
                  />
                )}
              </Grid>
            )}
          </Grid>
        }
        color={theme =>
          node?.isAssetInstance ? deletedStyleColor(theme) : theme.palette.text
        }
        textWeight={node?.isAssetInstance ? 400 : 700}
        dataSet={dataSet}
        onAddAssetClick={onAddAssetClick}
        onEditClick={onEditClick}
        selectedAssets={selectedAssets}
        updateSelection={updateSelection}
        onClearClick={onClearClick}
        onDoneClick={onDoneClick}
        textFieldError={textFieldError}
        onRemoveAssetClick={onRemoveAssetClick}
      >
        {!isEmpty(node.children)
          ? node.children
              .filter(deletedAsset =>
                showDeleted ? deletedAsset : deletedAsset.deletedAt === null
              )
              .map(child => {
                if (Array.isArray(child)) {
                  return child.reduce((allChildren, grandChild) => {
                    if (!showDeleted && grandChild?.deletedAt === null) {
                      return (
                        <TreeItems
                          isHierarchy={isHierarchy}
                          node={grandChild}
                          key={grandChild.id}
                          expanded={expanded}
                          setExpanded={setExpanded}
                          dataSet={dataSet}
                          updateSelection={updateSelection}
                          selectedAssets={selectedAssets}
                          onAddAssetClick={onAddAssetClick}
                          onEditClick={onEditClick}
                          onDoneClick={onDoneClick}
                          textFieldError={textFieldError}
                          onClearClick={onClearClick}
                          selectedNode={selectedNode}
                          onRemoveAssetClick={onRemoveAssetClick}
                        />
                      );
                    }
                    return allChildren;
                  }, {});
                }
                return (
                  <TreeItems
                    isHierarchy={isHierarchy}
                    node={child}
                    key={child.id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    dataSet={dataSet}
                    updateSelection={updateSelection}
                    selectedAssets={selectedAssets}
                    onAddAssetClick={onAddAssetClick}
                    onEditClick={onEditClick}
                    onDoneClick={onDoneClick}
                    textFieldError={textFieldError}
                    onClearClick={onClearClick}
                    selectedNode={selectedNode}
                    onRemoveAssetClick={onRemoveAssetClick}
                  />
                );
              })
          : null}
      </TreeItem>
    )
  );
};
TreeItems.propTypes = {
  node: PT.shape({
    id: PT.string,
    name: PT.string,
    isAssetInstance: PT.bool,
    children: PT.array,
    deletedAt: PT.string,
    hasChildren: PT.bool,
    checked: PT.bool,
    parentChecked: PT.bool,
    isTypeOnly: PT.bool,
    setInfo: PT.arrayOf(PT.shape({ count: PT.number })),
    assetTypeCount: PT.number || PT.null,
    multipleSubtypes: PT.bool,
  }),

  expanded: PT.arrayOf(string),
  isHierarchy: PT.bool.isRequired,
  setExpanded: PT.func.isRequired,
  dataSet: PT.bool.isRequired,
  updateSelection: PT.func,
  selectedAssets: PT.arrayOf(PT.oneOfType([PT.string, PT.shape({})])),
  onAddAssetClick: PT.func,
  onEditClick: PT.func,
  onDoneClick: PT.func,
  textFieldError: PT.string,
  onClearClick: PT.func,
  selectedNode: PT.string,
  onRemoveAssetClick: PT.func,
};
TreeItems.defaultProps = {
  node: {},
  expanded: [],
  selectedAssets: [],
  onAddAssetClick: () => {},
  updateSelection: () => {},
  onEditClick: () => {},
  onDoneClick: () => {},
  textFieldError: '',
  onClearClick: () => {},
  selectedNode: '',
  onRemoveAssetClick: () => {},
};
export default TreeItems;
