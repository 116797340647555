/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { useCallback, useState, useEffect, Fragment } from 'react';
import {
  Box,
  Collapse,
  Skeleton,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { List as ListIcon, Link as LinkIcon } from '@mui/icons-material';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import { DataGrid } from 'components/tables';
import { useNavigate, useParams } from 'react-router-dom';
import { useDataSetDetails } from 'routes/templates/data-set-details/hooks';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';
import { StyledIconButton, EditButton } from 'components/buttons';
import { useTheme } from '@mui/material/styles';
import EmptyState from 'components/empty-state';
import {
  useGridApiContext,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium';
import EditEntry from 'routes/parameters-page/parameter-details-pane/details/edit-entry';
import AddSource from 'routes/parameters-page/common/add-source';

const SetInstanceOutputsSummary = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation(['common', 'templates', 'parameters']);
  const {
    parametersLoading,
    dataSetAssets,
    outputsLoading,
    formValues,
    setFormValues,
    heldFormValues,
    setHeldFormValues,
    setDataSetError,
    setUpdatedParameter,
    paramUpdated,
    setParamUpdated,
    getDataSetAssetParameters,
  } = useDataSetDetails();
  const [openSummary, setOpenSummary] = useState(true);
  const [openParameters, setOpenParameters] = useState({});

  useEffect(() => {
    if (!isEmpty(dataSetAssets) && !paramUpdated) {
      setOpenParameters(
        dataSetAssets.reduce(
          (acc, _, index) => ({ ...acc, [index]: false }),
          {}
        )
      );
    }
    setParamUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetAssets]);

  const EditEntryPanelContent = ({ row: rowProp }) => {
    const [openForm, setOpenForm] = useState(true);
    const [openAddSource, setOpenAddSource] = useState(false);
    const [selectedSource, setSelectedSource] = useState({});
    const apiRef = useGridApiContext();
    const closePanel = id => {
      if (id) apiRef.current.toggleDetailPanel(id);
    };
    useEffect(() => {
      if (openForm === false && openAddSource === false) closePanel(rowProp.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openForm]);

    return openAddSource ? (
      <AddSource
        openAddSource={openAddSource}
        setOpenAddSource={setOpenAddSource}
        setOpenEditingForm={setOpenForm}
        setSelectedSource={setSelectedSource}
      />
    ) : (
      <EditEntry
        aria-label="editForm"
        parameter={rowProp}
        setOpenEditingForm={setOpenForm}
        setParameter={setUpdatedParameter}
        setOpenAddSource={setOpenAddSource}
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
        setError={setDataSetError}
        formValues={formValues}
        setFormValues={setFormValues}
        heldFormValues={heldFormValues}
        setHeldFormValues={setHeldFormValues}
      />
    );
  };

  const getEditEntryPanelContent = useCallback(
    ({ row }) => <EditEntryPanelContent row={row} />,
    []
  );
  const toggleOpenParameters = id => {
    setOpenParameters(prevOpenParameters => ({
      ...prevOpenParameters,
      [id]: !prevOpenParameters[id],
    }));
  };

  const summaryText = [
    { key: t('templates:dataSetSummary.open'), fontWeight: 'regular' },
    {
      key: t('templates:dataSetSummary.assetTypeInstance'),
      fontWeight: 'bold',
    },
    {
      key: t('templates:dataSetSummary.toSee'),
      fontWeight: 'regular',
    },
    {
      key: t('templates:dataSetSummary.parameters'),
      fontWeight: 'bold',
    },
    {
      key: t('templates:dataSetSummary.relatedToSet'),
      fontWeight: 'regular',
    },
  ];

  const setOutputsColumns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: params => (
        <EditButton
          type="Parameter"
          key="edit-button"
          {...params}
          isEditing={params.value}
          onClick={() => {}}
        />
      ),
      headerName: 'Edit parameter',
    },
    {
      flex: 0.8,
      field: 'parameterType',
      headerName: 'Parameter type',
      type: 'string',
      valueGetter: ({ value }) => value.name,
      renderCell: ({ value }) => {
        return (
          <Typography
            variant="body2"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {value}
          </Typography>
        );
      },
      resizable: false,
    },
    {
      flex: 0.3,
      field: 'selectedEntry',
      headerName: 'Value',
      hideable: false,
      valueGetter: ({ value }) => {
        const result = value?.values[0]?.value;
        return result;
      },
      colSpan: () => 1,
    },
    {
      flex: 0.2,
      field: 'selectedEntryUnit',
      headerName: 'Unit',
      hideable: false,
      valueGetter: ({ row }) => {
        const result = row?.selectedEntry?.values[0]?.unit?.symbol || '-';
        return result;
      },
      resizable: false,
    },
    {
      flex: 0.5,
      field: 'selectedEntrySource',
      headerName: 'Source',
      hideable: false,
      valueGetter: ({ row }) => {
        const result = row?.selectedEntry?.source?.sourceType?.name;
        return result;
      },
      resizable: false,
    },
    {
      flex: 0.2,
      field: 'id',
      headerName: 'Link',
      hideable: false,
      renderCell: ({ value }) => {
        return (
          <Grid container justifyContent="center">
            <StyledIconButton
              aria-label="link-to-parameter"
              title={t(`parameters:goToParameter`, {
                parameterId: value,
              })}
              onClick={() =>
                navigate(`/projects/${projectId}/parameters/${value}`)
              }
              icon={<LinkIcon />}
            />
          </Grid>
        );
      },
      resizable: false,
    },
  ];

  return (
    <Box aria-label="set-instance-details-summary" width="100%">
      <ExpandOrCollapseButton
        open={openSummary}
        onClick={() => setOpenSummary(!openSummary)}
        sx={{ marginLeft: theme.spacing(-1.5) }}
      />
      <Typography variant="caption" display="inline" color="textSecondary">
        {t('common:summary')}
      </Typography>
      <Collapse
        in={openSummary}
        sx={{ paddingLeft: () => theme.spacing(1.25) }}
      >
        {summaryText.map(text => (
          <Typography
            key={text.key}
            variant="body2"
            display="inline"
            fontWeight={text.fontWeight}
          >
            {text.key}
          </Typography>
        ))}
        <br />

        {outputsLoading ? (
          <Skeleton width="100%" height={50} />
        ) : !isEmpty(dataSetAssets) ? (
          dataSetAssets.map((assetSet, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Grid
                aria-label="asset-details-section"
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  xs={5}
                  aria-label="asset-name"
                  wrap="nowrap"
                >
                  <Typography variant="subtitle1" display="inline">
                    {capitalize(assetSet?.name)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  wrap="nowrap"
                  xs={7}
                >
                  <Grid
                    item
                    container
                    aria-label="asset-type-name"
                    justifyContent="flex-end"
                    sx={{ textAlign: 'right' }}
                    wrap="nowrap"
                  >
                    <Typography
                      variant="subtitle1"
                      display="inline"
                      sx={{
                        mr: () => theme.spacing(1.25),
                        color: theme.palette.grey[600],
                      }}
                    >
                      {assetSet.assetTypeName
                        ? `(${capitalize(assetSet.assetTypeName)})`
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ExpandOrCollapseButton
                      aria-label="expand-asset-parameters-table"
                      open={openParameters[index]}
                      onClick={() => {
                        toggleOpenParameters(index);
                        if (isEmpty(assetSet.parameters)) {
                          getDataSetAssetParameters(assetSet.id);
                        }
                      }}
                      sx={{ padding: theme.spacing(1) }}
                      disabled={!assetSet.hasParameters}
                      title={
                        assetSet.hasParameters === false
                          ? t('templates:noParameters')
                          : undefined
                      }
                      loading={parametersLoading[assetSet.id]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isEmpty(assetSet.parameters) &&
                !parametersLoading[assetSet.id] && (
                  <Collapse in={openParameters[index]}>
                    <DataGrid
                      columnBuffer={5}
                      sx={{ borderRadius: 0 }}
                      loading={parametersLoading[assetSet.id]}
                      density="standard"
                      DetailPanelContentComponent={getEditEntryPanelContent}
                      disableColumnMenu
                      columns={setOutputsColumns}
                      autoHeight
                      rowHeight={32}
                      rows={assetSet?.parameters}
                      disableSelectionOnClick
                      aria-label="data-set-asset-parameters"
                      showToolbar={false}
                      hideFooterRowCount
                      showColumnRightBorder
                      showCellRightBorder
                      highlightRow
                      hideFooter
                    />
                  </Collapse>
                )}
              <Divider sx={{ mt: '5px' }} />
            </Fragment>
          ))
        ) : (
          isEmpty(dataSetAssets) && (
            <>
              <EmptyState
                Icon={ListIcon}
                title="templates:dataSetEmpty.noAssetsAssigned"
              />
              <Divider sx={{ mt: '5px' }} />
            </>
          )
        )}
      </Collapse>
    </Box>
  );
};

export default SetInstanceOutputsSummary;
