import PT from 'prop-types';
import { Grid } from '@mui/material';

function DataDrawerGrid(props) {
  const { icon, caption, content, alert } = props;
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
    >
      {alert && (
        <Grid sx={{ width: '100%', padding: theme => theme.spacing(1) }} item>
          {alert}
        </Grid>
      )}
      <Grid
        direction="row"
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        wrap="nowrap"
      >
        {icon && (
          <Grid sx={{ padding: theme => theme.spacing(1) }} item>
            {icon}
          </Grid>
        )}

        <Grid item sx={{ width: '100%', padding: theme => theme.spacing(1) }}>
          <Grid item>{caption}</Grid>
          <Grid item>{content}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

DataDrawerGrid.propTypes = {
  icon: PT.node.isRequired,
  caption: PT.node.isRequired,
  content: PT.node.isRequired,
  alert: PT.node,
};

DataDrawerGrid.defaultProps = {
  alert: null,
};

export default DataDrawerGrid;
