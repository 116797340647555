import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

export default function SimpleAlerts({
  severity,
  variant,
  text,
  title,
  onClose,
  boxStyle,
  titleStyle,
  ariaLabel,
  color,
}) {
  return (
    <Alert
      aria-label={ariaLabel}
      data-cy="alert-banner"
      variant={variant}
      severity={severity}
      onClose={onClose}
      sx={boxStyle}
      color={color || severity}
    >
      {title && <AlertTitle sx={titleStyle}>{title}</AlertTitle>}
      {text}
    </Alert>
  );
}

SimpleAlerts.propTypes = {
  severity: PropTypes.string,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any,
  boxStyle: PropTypes.shape({}),
  titleStyle: PropTypes.shape({}),
  ariaLabel: PropTypes.string,
  color: PropTypes.string,
};

SimpleAlerts.defaultProps = {
  title: null,
  text: null,
  severity: 'error',
  variant: 'filled',
  onClose: null,
  boxStyle: {},
  titleStyle: {},
  ariaLabel: '',
  color: null,
};
