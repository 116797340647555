import { useMediaQuery, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTypography } from 'routes/reporting-page/reporting-page-styles';

const GlobalSourcesTitle = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { t } = useTranslation(['sources']);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <Grid item>
        <StyledTypography
          aria-label="drawer-title-sources"
          variant={screenSm ? 'h6' : 'h5'}
          pt={1.2}
        >
          {t('sources:globalSourcesManagement')}
        </StyledTypography>
      </Grid>
    </Grid>
  );
};

export default GlobalSourcesTitle;
