import { PeoplePicker as MsPeoplePicker } from '@microsoft/mgt-react';
import { styled, useTheme } from '@mui/material/styles';
import PT from 'prop-types';

const StyledPeoplePicker = styled(MsPeoplePicker)(({ theme, error }) => ({
  '--input-background-color': error ? theme.palette.error.dark : 'none',
}));

function PeoplePicker({ showMax, userType, ...rest }) {
  const theme = useTheme();
  return (
    <StyledPeoplePicker
      showMax={5}
      userType={userType}
      className={theme.palette.mode === 'dark' && 'mgt-dark'}
      {...rest}
    />
  );
}

PeoplePicker.propTypes = {
  showMax: PT.number,
  userType: PT.string,
};

PeoplePicker.defaultProps = {
  showMax: 5,
  userType: 'user',
};

export default PeoplePicker;
