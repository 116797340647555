/* eslint-disable no-param-reassign */
export default function formatFaqs(faqs) {
  const sortedByCategory = faqs.reduce((newFaqs, currentFaq) => {
    const { category, Title, answer, status } = currentFaq.fields;
    if (status === 'Approved') {
      if (newFaqs[category]) newFaqs[category].push({ Title, answer });
      else newFaqs[category] = [{ Title, answer }];
    }
    return newFaqs;
  }, {});
  return Object.entries(sortedByCategory).map(([heading, info]) => ({
    heading,
    content: info
      .map(({ Title, answer }) => `* <b>${Title}</b> \n  >${answer}`)
      .join('\n'),
  }));
}
