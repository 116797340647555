import PT from 'prop-types';
import { Source as SourceIcon } from '@mui/icons-material';
import { ExpandOrCollapseButton } from '..';
import StyledIconButton from '../styled';

function SourceButton({ open, onClick, title, disabled }) {
  if (!title) {
    return (
      <StyledIconButton
        icon={<SourceIcon aria-label="source icon without expand icon" />}
        disabled
      />
    );
  }
  return (
    <ExpandOrCollapseButton
      open={open}
      onClick={onClick}
      disabled={disabled}
      title={title}
      icon={<SourceIcon aria-label="source icon" />}
    />
  );
}

SourceButton.propTypes = {
  title: PT.string,
  disabled: PT.bool,
  open: PT.bool.isRequired,
  onClick: PT.func.isRequired,
};

SourceButton.defaultProps = {
  title: null,
  disabled: false,
};

export default SourceButton;
