import {
  shape,
  string,
  objectOf,
  bool,
  number,
  arrayOf,
  array,
  node,
  oneOfType,
  oneOf,
  any,
  object,
  func,
} from 'prop-types';

export const assetTypePropType = shape({
  id: string,
  name: string,
});

export const unitType = shape({
  id: oneOfType([string, number]),
  createdAt: string,
  deletedAt: string,
  updatedAt: string,
  name: string,
});

export const parameterType = shape({
  id: oneOfType([string, number]),
  parameterType: shape({
    id: oneOfType([string, number]),
    name: string.isRequired,
    dataType: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
  }),
  selectedEntry: shape({
    id: oneOfType([string, number]),
    status: oneOf([
      'unanswered',
      'answered',
      'checked',
      'approved',
      'rejected',
    ]),
    sourceId: oneOfType([string, number]),
    valid: shape({ isValid: bool, state: string }),
    values: arrayOf(
      shape({
        id: oneOfType([string, number]),
        // eslint-disable-next-line react/forbid-prop-types
        value: any,
        unit: unitType,
      })
    ),
    createdAt: string,
    createdBy: shape({
      staffId: number,
      staffName: string,
      companyCentreArupUnit: string,
      email: string,
      gradeLevel: number,
      locationName: string,
      myPeoplePageUrl: string,
    }),
  }),
  parents: arrayOf(
    shape({
      id: oneOfType([string, number]),
      projectId: string,
      children: arrayOf(oneOfType([string, number])),
      name: string.isRequired,
      assetType: shape({
        id: oneOfType([string, number]),
        parentId: oneOfType([string, number]),
        name: string.isRequired,
        createdAt: string,
        updatedAt: string,
        deletedAt: string,
        assetSubType: bool,
      }),
    })
  ),
  deletedAt: string,
});
export const parameterSetTemplateType = shape({
  id: oneOfType([string, number]),
  createdAt: string,
  createdBy: string,
  parameterSetCategory: shape({
    id: oneOfType([string, number]),
    name: string.isRequired,
  }),
  items: arrayOf(shape({})),
  updatedAt: string,
  updatedBy: string,
  rootAssetType: shape({
    id: string,
    name: string,
    parentId: string,
  }),
  name: string,
  deletedAt: string,
});

export const source = shape({
  id: string,
  created_at: string,
  date_day: string,
  date_month: string,
  date_year: string,
  deleted_at: string,
  reference: string,
  reference_id: string,
  reference_table: string,
  reference_url: string,
  scope: string,
  source_type: shape({
    id: string,
    name: string,
    visible: bool,
    deleted_at: string,
  }),
  time: string,
  title: string,
  updated_at: string,
  url: string,
});
export const addParameter = shape({
  id: string,
  parameterTypeId: string,
  dataType: string,
  status: string,
  value: oneOfType([string, number, bool]),
  unitTypeId: string,
  unitId: string,
  name: string,
  sourceId: string,
  // eslint-disable-next-line react/forbid-prop-types
  tags: array,
  // eslint-disable-next-line react/forbid-prop-types
  disciplines: array,
});
export const dictionaryOfStringType = objectOf(string);

export const dictionaryOfBooleanType = objectOf(bool);

export const projectType = shape({
  centreCode: string,
  confidential: bool,
  shortTitle: string,
  projectNumber: string,
  clientOrganisationName: string,
  projectDirectorEmail: string,
  projectDirectorName: string,
  projectId: string,
  projectManagerEmail: string,
  projectManagerName: string,
  projectUrl: string,
  scopeOfWorks: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
});

export const parameterListFiltersType = shape({
  categories: arrayOf(),
  disciplines: arrayOf(),
  parameter: arrayOf(parameterType),
  qaStatus: arrayOf(),
  sourceTypeId: arrayOf(),
  sources: arrayOf(),
  units: arrayOf(),
});

export const filterOptionType = shape({
  name: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: array, // This can be many things, too complicated to put here simply
});

export const checkBoxType = shape({
  name: string.isRequired,
  isSelected: bool, // This can be many things, too complicated to put here simply
});

export const menuType = shape({
  label: string.isRequired,
  value: string,
  icon: node,
  onClick: func,
});

export const sortType = shape({
  sortBy: string.isRequired,
  order: string.isRequired,
});

// Needs further investigation
export const filterType = object;

export const assetTypeType = shape({
  id: string.isRequired,
  parent: string,
});

export const parameterTypeType = shape({
  id: string.isRequired,
});

export const sourceType = shape({
  id: string,
  source: string,
  valueLocation: string,
  directoryURL: string,
  published: string,
  comment: string,
});

export const tagTypeType = shape({
  id: string.isRequired,
  name: string.isRequired,
});

export const children = oneOfType([arrayOf(node), node]).isRequired;

export const tagType = shape({
  id: string.isRequired,
  name: string.isRequired,
  globalTag: bool,
  loadingTag: bool,
  // eslint-disable-next-line react/forbid-prop-types
  icon: any,
});

export const entryType = shape({
  id: oneOfType([string, number]).isRequired,
  relevance: arrayOf(string).isRequired,
  source: sourceType,
  entryValue: string,
  type: string,
  status: string,
  sourceOpen: bool,
  popoverOpen: bool,
  relevanceOpen: bool,
  editEntryOpen: bool,
});

export const userType = shape({ avatar: string, role: arrayOf(string) });
