import PT from 'prop-types';
import { Star, StarBorder } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import config from 'config';
import handleFavourites from './hooks';
import { StyledIconButton } from '../../buttons';

function FavouritesButton({ loading, project }) {
  const { t } = useTranslation(['common']);
  const [cookies, setCookie, removeCookie] = useCookies([
    'ddbFavoriteProjects',
    'ddbCookiesPolicyAccepted',
  ]);
  const { addFavorite, removeFavorite, favorite } = handleFavourites(
    project,
    cookies,
    setCookie,
    removeCookie
  );

  const icon = favorite ? (
    <Star data-testid="favourite" />
  ) : (
    <StarBorder data-testid="notFavourite" />
  );

  return (
    <StyledIconButton
      title={
        !cookies?.ddbCookiesPolicyAccepted?.functional &&
        config.featureFlags.arePoliciesEnabled
          ? t('cookies:requiresCookies', {
              name: t('common:favourites'),
            })
          : t('common:favourite')
      }
      data-testid="favouritesButtons"
      data-cy={!loading && 'favourites-button'}
      onClick={favorite ? removeFavorite : addFavorite}
      disabled={
        loading ||
        (!cookies?.ddbCookiesPolicyAccepted?.functional &&
          config.featureFlags.arePoliciesEnabled)
      }
      aria-label={favorite ? 'Favourite' : 'Not favourite'}
      icon={
        loading ? (
          <Skeleton width={20} height={20} data-testid="loading" />
        ) : (
          icon
        )
      }
    />
  );
}

FavouritesButton.propTypes = {
  project: PT.shape({
    shortTitle: PT.string,
    number: PT.string,
    projectId: PT.string,
  }),
  loading: PT.bool,
};

FavouritesButton.defaultProps = {
  project: {
    shortTitle: '',
    number: null,
    projectId: null,
  },
  loading: false,
};

export default FavouritesButton;
