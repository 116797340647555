import localConfig from './local';
import devConfig from './dev';
import utConfig from './ut';
import prodConfig from './prod';

const { origin } = window.location;
let config = prodConfig;

if (origin.includes('localhost')) config = localConfig;
if (origin.includes('dev')) config = devConfig;
if (origin.includes('sandbox')) config = utConfig;

const configClone = config;

export default configClone;
