import PT from 'prop-types';
import { Unarchive } from '@mui/icons-material';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function RestoreButton({ onClick, disabled, tooltip }) {
  const { renderTooltipRestore } = useButton();
  return (
    <StyledIconButton
      key="restore"
      title={disabled ? tooltip : renderTooltipRestore()[0]}
      variant="outlined"
      onClick={onClick}
      disabled={disabled || renderTooltipRestore()[1]}
      aria-label={renderTooltipRestore()[0]}
      icon={<Unarchive sx={{ transform: 'scale(1.5)' }} />}
    />
  );
}

RestoreButton.propTypes = {
  onClick: PT.func.isRequired,
  disabled: PT.bool,
  tooltip: PT.string,
};

RestoreButton.defaultProps = { disabled: null, tooltip: '' };

export default RestoreButton;
