import { useContext } from 'react';
import PT from 'prop-types';
import { Add } from '@mui/icons-material';
import { Tooltip as ToolTip } from '@mui/material';
import { ProjectPermissionContext } from 'context';
import Fab from '../../fab';

function AddButton({ disabled, onClick, tooltip, type }) {
  const { writePermission } = useContext(ProjectPermissionContext);

  return (
    <ToolTip title={tooltip} data-testid="add-button-tooltip">
      <span>
        <Fab
          variant="circular"
          size="small"
          style={{ transform: 'scale(0.7)' }}
          aria-label="Add"
          disabled={disabled || !writePermission}
          onClick={onClick}
          data-testid="add-button"
          data-cy={`add-button-${type}`}
        >
          <Add />
        </Fab>
      </span>
    </ToolTip>
  );
}

AddButton.propTypes = {
  disabled: PT.bool,
  onClick: PT.func.isRequired,
  tooltip: PT.string.isRequired,
  type: PT.string,
};

AddButton.defaultProps = {
  disabled: null,
  type: 'asset',
};

export default AddButton;
