import SelectReport from './selectReport';
import Mapping from './mapping';
import Summary from './summary';

const steps = [
  {
    label: 'reporting:template',
    content: <SelectReport />,
    optional: false,
  },
  { label: 'reporting:mapping', content: <Mapping />, optional: true },
  { label: 'reporting:generation', content: <Summary />, optional: false },
];

export default steps;
