import { useState, useRef } from 'react';
import PT from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import { findIndex } from 'lodash';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';

function Docs({ match }) {
  const navigate = useNavigate();
  const { t } = useTranslation(['api-docs']);

  const options = [
    { name: t('api-docs:apiDocs'), url: '/docs', id: 'documentation' },
    {
      name: t('api-docs:integrations'),
      url: '/integrations',
      id: 'integrations',
    },
  ];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(
    findIndex(options, { url: match })
  );

  const handleClick = () => navigate(options[selectedIndex].url);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    navigate(options[index].url);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="docs split button"
        disableElevation
        data-testid="docs-dropdown-button"
        color="primary"
      >
        <Button onClick={handleClick} color="secondary">
          {options[selectedIndex].name}
        </Button>
        <Button
          size="small"
          color="secondary"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          data-cy="split-button-menu"
          data-testid="split-button-menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.url}
                      data-cy={`button-menu-${option.id}`}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

Docs.propTypes = {
  match: PT.string.isRequired,
};

export default Docs;
