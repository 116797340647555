import { Suspense } from 'react';
import { Skeleton, Grid, Container, useMediaQuery } from '@mui/material';
import { withAppInsights } from 'app/app-insights';
import AppFooter from 'components/footer';
import { useTranslation } from 'react-i18next';
import { StyledTitleTypography } from './styles';
import Description from './description';
import FavouriteAndRecentProjects from './favourite-and-recent-projects';
import DDBUseCases from './ddb-use-cases';

const HomePage = () => {
  const { t } = useTranslation(['home']);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <>
      <main
        id="main-content"
        tabIndex="-1"
        data-testid="home-page"
        style={{ minHeight: 650 }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <StyledTitleTypography
                  variant={isMdUp ? 'h3' : 'h1'}
                  component="h1"
                  color="inherit"
                  gutterBottom
                  data-cy="dDBHomeText"
                >
                  {t('home:title')}
                </StyledTitleTypography>
                <Suspense fallback={<Skeleton height={100} />}>
                  <Description />
                </Suspense>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              paddingBottom={theme => theme.spacing(4)}
            >
              <img
                src="/illustrations/landing-page/landingPage.svg"
                alt="Landing Page"
                style={{
                  transform: isMdUp ? 'none' : 'scaleX(-1)',
                }}
              />
            </Grid>
            {isMdUp && (
              <Suspense fallback={<Skeleton height={100} />}>
                <FavouriteAndRecentProjects />
              </Suspense>
            )}
            <Suspense fallback={<Skeleton height={100} />}>
              <DDBUseCases />
            </Suspense>
          </Grid>
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default withAppInsights(HomePage, 'HomePage');
