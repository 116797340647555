import * as dateLocales from 'date-fns/locale';
import {
  plPL,
  enUS,
  arEG,
  daDK,
  deDE,
  esES,
  frFR,
  itIT,
  jaJP,
  koKR,
  nlNL,
  ruRU,
  srRS,
  thTH,
  trTR,
  viVN,
  zhCN,
  idID,
} from '@mui/material/locale';

export const LANGUAGES_LABELS = [
  {
    code: 'en-GB',
    text: 'English (GB)',
  },
  {
    code: 'en-US',
    text: 'English (US)',
  },
  {
    code: 'ar-EG',
    text: 'Arabic (EG)',
  },
  {
    code: 'cy-CY',
    text: 'Welsh (CY)',
  },
  {
    code: 'da-DK',
    text: 'Danish (DK)',
  },
  {
    code: 'de-DE',
    text: 'German (DE)',
  },
  {
    code: 'es-ES',
    text: 'Spanish (ES)',
  },
  {
    code: 'fr-FR',
    text: 'French (FR)',
  },
  {
    code: 'it-IT',
    text: 'Italian (IT)',
  },
  {
    code: 'ja-JP',
    text: 'Japanese (JP)',
  },
  {
    code: 'ko-KR',
    text: 'Korean (KR)',
  },
  {
    code: 'ms-MS',
    text: 'Malay (MS)',
  },
  {
    code: 'pl-PL',
    text: 'Polish (PL)',
  },
  {
    code: 'nl-NL',
    text: 'Dutch (NL)',
  },
  {
    code: 'ru-RU',
    text: 'Russian (RU)',
  },
  {
    code: 'sr-RS',
    text: 'Serbian (RS)',
  },
  {
    code: 'th-TH',
    text: 'Thai (TH)',
  },
  {
    code: 'tr-TR',
    text: 'Turkish (TR)',
  },
  {
    code: 'vi-VN',
    text: 'Vietnamese (VN)',
  },
  {
    code: 'zh-CN',
    text: 'Chinese (CN)',
  },
];

export const LANGUAGES = LANGUAGES_LABELS.map(({ code }) => code);

export const localeDateMap = {
  'en-GB': dateLocales.enGB,
  'cy-CY': dateLocales.enGB,
  'pl-PL': dateLocales.pl,
  'en-US': dateLocales.enUS,
  'ar-EG': dateLocales.ar,
  'da-DK': dateLocales.da,
  'de-DE': dateLocales.deAT,
  'es-ES': dateLocales.es,
  'fr-FR': dateLocales.fr,
  'it-IT': dateLocales.it,
  'ja-JP': dateLocales.ja,
  'ko-KR': dateLocales.ko,
  'ms-MS': dateLocales.id,
  'nl-NL': dateLocales.nl,
  'ru-RU': dateLocales.ru,
  'sr-RS': dateLocales.sr,
  'th-TH': dateLocales.th,
  'tr-TR': dateLocales.tr,
  'vi-VI': dateLocales.vi,
  'zh-CN': dateLocales.zhCN,
};

export const localeThemeMap = {
  'en-GB': enUS,
  'pl-PL': plPL,
  'en-US': enUS,
  'cy-CY': enUS,
  'ar-EG': arEG,
  'da-DK': daDK,
  'de-DE': deDE,
  'es-ES': esES,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'ms-MS': idID,
  'nl-NL': nlNL,
  'ru-RU': ruRU,
  'sr-RS': srRS,
  'th-TH': thTH,
  'tr-TR': trTR,
  'vi-VI': viVN,
  'zh-CN': zhCN,
};

export const langNotInDocs = [
  'en-GB',
  'en-US',
  'cy-CY',
  'da-DK',
  'de-DE',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'ms-MS',
  'pl-PL',
  'nl-NL',
  'ru-RU',
  'sr-RS',
  'th-TH',
  'tr-TR',
  'vi-VN',
];
export const langNotToConvertFormat = ['zh-CN', 'en-GB', 'en-US'];
