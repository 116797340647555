/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import PT from 'prop-types';
import { MoreVert } from '@mui/icons-material';
import { SpeedDial, SpeedDialAction } from '@mui/material';

function SpeedDialMenu({ actions }) {
  const [open, setOpen] = useState(false);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial"
      icon={<MoreVert />}
      onClick={handleOpenClose}
      open={open}
      direction="down"
      FabProps={{ size: 'small' }}
    >
      {actions.map((action, index) => (
        <SpeedDialAction key={index} icon={action} tooltipTitle="" />
      ))}
    </SpeedDial>
  );
}

SpeedDialMenu.propTypes = {
  actions: PT.arrayOf(PT.object.isRequired).isRequired,
};

export default SpeedDialMenu;
