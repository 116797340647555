import { useState } from 'react';

export default (push, allParameterTypes, values, remove) => {
  // const [openImport, setOpenImport] = useState(false);
  // const [valueOptions, setValueOptions] = useImmer([]);
  // const [optionsLoading, setOptionsLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState(
    values?.parameterTypes?.map(({ parameterTypeId }) => parameterTypeId) || []
  );

  // TODO add options functionality once design agreed
  // const itemForImport = values?.itemsImport;

  // useEffect(() => {
  //   const getAllOptions = async () => {
  //     setOptionsLoading(true);
  //     const query = {
  //       parameter_type_id: itemForImport[itemForImport.length - 1]?.id,
  //     };
  //     const itemOptions = [];
  //     const allOptions = await parametersApi('getAllItemTypes', query).catch(
  //       handleApiError
  //     );
  //     if (allOptions) {
  //       const { itemTypes } = allOptions;
  //       const itemTypesWithOptions = itemTypes.filter(
  //         itemType => itemType.hasOptions === true
  //       );
  //       if (itemTypesWithOptions.length !== 0) {
  //         const optionPromises = await Promise.all(
  //           itemTypesWithOptions.map(async itemType => {
  //             const { options } = await parametersApi('getOptions', {
  //               item_type_id: itemType.id,
  //             });
  //             return options;
  //           })
  //         );
  //         optionPromises.forEach(options => {
  //           options.forEach(option => {
  //             const existingOption = itemOptions.find(
  //               // eslint-disable-next-line max-nested-callbacks
  //               item => item.value === option.value
  //             );
  //             if (!existingOption) {
  //               itemOptions.push(option);
  //             }
  //           });
  //         });
  //       }
  //       setValueOptions(itemOptions);
  //       setOptionsLoading(false);
  //     }
  //   };
  //   if (values?.itemsImport) {
  //     setValueOptions([]);
  //     getAllOptions();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [itemForImport]);

  const updateSelectionModel = newRowSelectionModel => {
    const { parameterTypes, itemsImport } = values;

    const filtered = allParameterTypes.filter(param => {
      return (
        newRowSelectionModel.includes(param.parameterTypeId) &&
        !parameterTypes?.find(
          ({ parameterTypeId }) => parameterTypeId === param.parameterTypeId
        )
      );
    });
    if (filtered.length) {
      filtered.forEach(parameterType => {
        if (!parameterType.currentAvailableParameter) {
          push('itemsImport', parameterType);
        }
        push('parameterTypes', parameterType);
      });
    } else {
      const toRemove = parameterTypes.filter(({ parameterTypeId }) => {
        return !newRowSelectionModel.includes(parameterTypeId);
      });
      toRemove.forEach(({ parameterTypeId }) => {
        const parameterIndex = parameterTypes?.findIndex(
          param => param.parameterTypeId === parameterTypeId
        );
        if (parameterIndex >= 0) remove('parameterTypes', parameterIndex);

        const itemsIndex = itemsImport?.findIndex(
          param => param.parameterTypeId === parameterTypeId
        );
        if (itemsIndex >= 0) remove('itemsImport', itemsIndex);
      });
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  return {
    rowSelectionModel,
    setRowSelectionModel,
    updateSelectionModel,
  };
};
