import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context';

export default () => {
  const { login, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = () => {
    handleClose();
    navigate('/');
    logout();
  };

  const userLogin = () => {
    handleClose();
    login();
  };

  return { anchorEl, navigate, userLogout, userLogin, handleMenu, handleClose };
};
