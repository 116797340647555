import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  Person,
  Gavel,
  Public,
  AccountBalance,
  Category,
  Insights,
  Article,
  ChangeCircle as ChangeCircleIcon,
  Biotech as BiotechIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
} from '@mui/icons-material';
import {
  lightGreen,
  green,
  blue,
  red,
  pink,
  indigo,
  deepPurple,
  deepOrange,
  teal,
  blueGrey,
} from '@mui/material/colors';

// MUI standard colors chosen to pass acessibility contrast requiements in dark & light mode
export const tagTypeLookup = {
  Disciplines: {
    color: { light: lightGreen[900], dark: lightGreen[200] },
    icon: <BiotechIcon />,
  },
  Workstage: {
    color: { light: blueGrey[800], dark: blueGrey[100] },
    icon: <PrecisionManufacturingIcon />,
  },
  Calculation: {
    color: { light: deepOrange[900], dark: deepOrange[300] },
    icon: <ChangeCircleIcon />,
  },
  Client: {
    color: { light: blue[800], dark: blue[100] },
    icon: <Person />,
  },
  Code: {
    color: indigo[400],
    icon: <Gavel />,
  },
  Sector: {
    color: { light: red[800], dark: red[200] },
    icon: <AccountBalance />,
  },
  Scope: {
    color: { light: teal[800], dark: teal[200] },
    icon: <Insights />,
  },
  Location: {
    color: { light: green[700], dark: green[200] },
    icon: <Public />,
  },
  Report: {
    color: { light: deepPurple[500], dark: deepPurple[200] },
    icon: <Article />,
  },
  Category: {
    color: { light: pink[700], dark: pink[200] },
    icon: <Category />,
  },
};

const color = (tagType, theme, palette) => {
  let tagColor;
  if (tagTypeLookup[tagType]?.color)
    tagColor = tagTypeLookup[tagType]?.color[palette];
  else tagColor = theme.palette.primary.main;
  return tagColor;
};

export const StyledChip = styled(MuiChip)(({ theme, ...props }) => ({
  margin: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  backgroundColor:
    props.holder !== 'true' &&
    tagTypeLookup[props.tagtype]?.color[props.palette],
  color:
    props.holder === 'true'
      ? color(props.tagtype, theme, props.palette)
      : theme.palette.primary.contrastText,
  width: props.holder === 'true' && '130px',
  maxWidth: props.tagtype !== 'addTag' && props.holder !== 'true' && '500px',
  justifyContent: 'left',
  '& .MuiChip-icon': {
    color:
      props.holder === 'true' || props.tagtype === 'addTag'
        ? color(props.tagtype, theme, props.palette)
        : theme.palette.primary.contrastText,
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.contrastText,
  },
  fontWeight: theme.typography.fontWeightMedium,
}));
