import { Switch } from '@mui/material';
import { styled, lighten, darken } from '@mui/material/styles';

export const StyledSwitch = styled(props => <Switch {...props} />)(
  ({ theme, ...props }) => ({
    '& .MuiSwitch-switchBase': {
      '&:hover': {
        backgroundColor:
          props.palette === 'light'
            ? `${lighten(theme.palette.primary.main, 0.5)} !important`
            : `${darken(theme.palette.primary.main, 0.3)} !important`,
      },
      '&:focus': {
        backgroundColor:
          props.palette === 'light'
            ? `${lighten(theme.palette.primary.main, 0.5)} !important`
            : `${darken(theme.palette.primary.main, 0.3)} !important`,
      },
      '&.Mui-focusVisible': {
        backgroundColor:
          props.palette === 'light'
            ? `${lighten(theme.palette.primary.main, 0.5)} !important`
            : `${darken(theme.palette.primary.main, 0.3)} !important`,
      },
    },
  })
);

export default StyledSwitch;
