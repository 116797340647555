export default tagsArray =>
  tagsArray.map(tag => {
    if (tag.name === 'Parameter Category') {
      return {
        ...tag,
        name: 'Category',
      };
    }
    return { ...tag };
  });
