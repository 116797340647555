import PT from 'prop-types';
import { Toolbar, useMediaQuery } from '@mui/material';
import {
  ContainerMdUp,
  ContainerMdDown,
  StyledDrawer,
} from './permanent-styles';

export default function PermanentDrawer({
  children,
  open,
  anchor,
  variant,
  width,
  toggleDrawerOpen,
  ...rest
}) {
  const hiddenMdDown = useMediaQuery(theme => theme.breakpoints.up('md'));

  if (hiddenMdDown)
    return (
      <StyledDrawer
        data-testid="smDownDrawer"
        anchor={anchor}
        variant={variant}
        open={open}
        width={width}
        {...rest}
      >
        <Toolbar />
        <ContainerMdDown disableGutters>{children}</ContainerMdDown>
      </StyledDrawer>
    );

  return (
    <StyledDrawer
      data-testid="mdUpDrawer"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      open={open}
      width={width}
      anchor={anchor}
      variant={open ? 'temporary' : 'permanent'}
      onClose={toggleDrawerOpen}
      sx={{
        zIndex: theme => {
          const index = open ? 2 : 0;
          return theme.zIndex.drawer + index;
        },
      }}
      {...rest}
    >
      {!open && <Toolbar />}
      <ContainerMdUp disableGutters>{children}</ContainerMdUp>
    </StyledDrawer>
  );
}

PermanentDrawer.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  open: PT.bool.isRequired,
  anchor: PT.string,
  variant: PT.string,
  width: PT.oneOfType([PT.string, PT.number]),
  toggleDrawerOpen: PT.func.isRequired,
};

PermanentDrawer.defaultProps = {
  anchor: 'left',
  variant: 'permanent',
  width: null,
};
