/* eslint-disable no-param-reassign */
import { useContext } from 'react';
import { PageLayoutContext } from 'context';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyButton } from 'components/buttons';
import { useTranslation } from 'react-i18next';

export default dataSetId => {
  const {
    openTemplatePanels,
    setOpenTemplatePanels,
    numberOfTemplatePanelsOpen,
  } = useContext(PageLayoutContext);
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();
  const { projectId, dataTemplateId } = useParams();

  const handleDataTemplatesChange = () => {
    setOpenTemplatePanels(curr => {
      curr.openTemplates = !openTemplatePanels.openTemplates;
    });
  };

  const handleTemplateDetailsChange = () => {
    setOpenTemplatePanels(curr => {
      curr.openTemplateDetails = !openTemplatePanels.openTemplateDetails;
    });
    if (openTemplatePanels.openTemplateDetails && !dataTemplateId) {
      navigate(`/projects/${projectId}/templates/`);
    }
  };

  const handleDataSetChange = () => {
    setOpenTemplatePanels(curr => {
      curr.openDataSets = !openTemplatePanels.openDataSets;
    });
  };

  const handleDataSetDetailsChange = () => {
    setOpenTemplatePanels(curr => {
      curr.openDataSetDetails = !openTemplatePanels.openDataSetDetails;
    });
  };

  const headerActions = [
    <CopyButton
      aria-label="copy"
      key="copy"
      toBeCopied={dataSetId}
      toolTipText={t('common:dataDrawer:header.copyDataSetIdTooltip')}
    />,
  ];

  return {
    openTemplatePanels,
    handleDataTemplatesChange,
    handleTemplateDetailsChange,
    handleDataSetChange,
    handleDataSetDetailsChange,
    numberOfTemplatePanelsOpen,
    headerActions,
  };
};
