import React, { useContext, useRef, useEffect, useCallback } from 'react';
import { HistoryContext } from 'context';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Divider,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { format, isToday, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import AuditItemAccordion from '../audit-item-accordion/audit-item-accordion';

const GroupedHistoriesByDate = () => {
  const {
    allHistories,
    isLoaded,
    fetchMoreHistories,
    isLoadingMore,
    finalCursor,
  } = useContext(HistoryContext);
  const loader = useRef(null);
  const { t } = useTranslation(['histories']);

  const formatDate = dateString => {
    const date = parseISO(dateString);
    return isToday(date) ? t('histories:today') : format(date, 'MMMM d, yyyy');
  };

  const historiesByDate = allHistories.reduce((acc, history) => {
    const date = formatDate(history.updatedAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(history);
    return acc;
  }, {});

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];

      if (target.isIntersecting && !isLoadingMore && !finalCursor) {
        fetchMoreHistories();
      }
    },
    [fetchMoreHistories, isLoadingMore, finalCursor]
  );

  useEffect(() => {
    if (!isLoaded) return;

    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const currentLoader = loader.current;
    if (currentLoader) observer.observe(currentLoader);

    // eslint-disable-next-line consistent-return
    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [isLoaded, handleObserver]);

  return (
    <Box sx={{ p: theme => theme.spacing(3) }}>
      {Object.entries(historiesByDate).map(([date, histories]) => (
        <Box key={date} aria-label="grouped-history">
          <Box
            sx={{
              pb: theme => theme.spacing(3),
              pt: theme => theme.spacing(3),
            }}
          >
            {isLoaded && (
              <Typography variant="button" aria-label="grouped-date">
                {date}
              </Typography>
            )}
          </Box>
          {histories.map((history, index) => (
            <AuditItemAccordion
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              history={history}
              isLoaded={isLoaded}
              isLastAccordion={index === histories.length - 1}
            />
          ))}
        </Box>
      ))}
      {!finalCursor ? (
        <div ref={loader} aria-label="loading-observer">
          <Grid
            container
            justifyContent="center"
            sx={{ paddingTop: theme => theme.spacing(2) }}
          >
            <CircularProgress
              size={60}
              thickness={3}
              aria-label="loading-more"
            />
          </Grid>
        </div>
      ) : (
        <Divider
          aria-label="showing-all"
          textAlign="center"
          sx={{
            paddingBottom: theme => theme.spacing(15),
            paddingTop: theme => theme.spacing(5),
            margin: theme => theme.spacing(2),
          }}
        >
          <CheckCircle fontSize="large" color="primary" />
          <Typography>{t('showingAllResults')}</Typography>
        </Divider>
      )}
    </Box>
  );
};

export default React.memo(GroupedHistoriesByDate);
