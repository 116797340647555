/* eslint-disable no-nested-ternary */
import { Suspense, useState, useContext } from 'react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'routes/parameters-page/parameters-page-styles';
import Filters from 'components/filters';
import NavigationHeader from 'components/cards/navigation-header';
import { FoldingDrawer } from 'components/drawer';
import {
  DataSetsContext,
  DataSetsWizardContext,
  DataTemplatesContext,
} from 'context';
import useTemplatesPage from 'routes/templates/hooks/use-templates-page';
import PageLayoutBox from 'components/layout/page-layout-styles';
import DataSetsList from 'components/data-sets-list';
import handleSetInstancesFilters from 'components/filters/hooks/handle-set-instances-filters';
import { useParams } from 'react-router-dom';
import DataSetWizard from '../data-set-wizard';

function SetInstances() {
  const { dataSets, loading } = useContext(DataSetsContext);
  const { templateAssets, dataTemplate } = useContext(DataTemplatesContext);
  const { handleOpenCloseWizard, openDataSetWizard, topLevelAssetType } =
    useContext(DataSetsWizardContext);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const handleFilterActive = () => {
    setIsFilterActive(!isFilterActive);
  };
  const { dataTemplateId } = useParams();
  const {
    openTemplatePanels,
    numberOfTemplatePanelsOpen,
    handleDataSetChange,
  } = useTemplatesPage();
  const [paramSetInstancesFiltersCount, setParamSetInstancesFiltersCount] =
    useState(0);
  const {
    sortByOptions,
    filters,
    applyFilters,
    filtersMap,
    handleOnChange,
    handleOnCancel,
    clearFilters,
  } = handleSetInstancesFilters(
    handleFilterActive,
    setParamSetInstancesFiltersCount,
    isFilterActive
  );
  const { t } = useTranslation(['common', 'parameters', 'templates']);
  const keyAsset = templateAssets.filter(asset => asset.isKey === true)[0];

  // TODO: add search and filter functionality once it is implemented on BE
  return (
    <FoldingDrawer
      width={numberOfTemplatePanelsOpen > 2 ? '5%' : '20%'}
      open={openTemplatePanels.openDataSets}
      tooltip="templates:dataSets"
      onClick={handleDataSetChange}
    >
      <PageLayoutBox>
        <StyledBox aria-label="set-instances-panel">
          <Suspense fallback={null}>
            <NavigationHeader
              hasProjectCard={false}
              open
              handleAdd={handleOpenCloseWizard}
              isActive={isFilterActive}
              handleClick={handleFilterActive}
              label="dataSets"
              setFilterCount={setParamSetInstancesFiltersCount}
              filterCount={paramSetInstancesFiltersCount}
              disabled={
                !dataTemplate?.publishedAt ||
                !topLevelAssetType.available ||
                !dataTemplateId
              }
              tooltipMessage={
                !dataTemplateId
                  ? t('templates:selectTemplateFirst')
                  : !dataTemplate?.publishedAt
                  ? t('templates:publishTemplateFirst')
                  : !topLevelAssetType.available
                  ? t('templates:addAssetsFirst', {
                      type: topLevelAssetType.type,
                    })
                  : t('common:buttons.add')
              }
            />
          </Suspense>
          {isFilterActive && (
            <Suspense fallback={null}>
              <Filters
                sortByOptions={sortByOptions}
                filters={filters}
                applyFilters={applyFilters}
                filtersMap={filtersMap}
                handleOnChange={handleOnChange}
                handleOnCancel={handleOnCancel}
                clearFilters={clearFilters}
              />
            </Suspense>
          )}
          {openDataSetWizard && (
            <Suspense fallback={null}>
              <DataSetWizard
                open={openDataSetWizard}
                toggleOpen={handleOpenCloseWizard}
              />
            </Suspense>
          )}

          <Divider />
          <Suspense fallback={null}>
            <DataSetsList
              style={{ width: '100%' }}
              rows={dataSets}
              loading={loading}
              keyAsset={keyAsset}
            />
          </Suspense>
        </StyledBox>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default SetInstances;
