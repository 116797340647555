import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { Avatar } from '@mui/material';
import { Person } from 'components/microsoft';
import { StyledIconButton } from 'components/buttons';
import handleUser from './hooks';
import UserMenu from './menu';

function User() {
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  const { anchorEl, userLogout, userLogin, handleMenu, handleClose, navigate } =
    handleUser();

  return (
    <>
      <StyledIconButton
        title={t('header:manageAccount')}
        data-testid="userAvatar"
        color="inherit"
        onClick={handleMenu}
        aria-label={t('header:manageAccount')}
        size="small"
        icon={
          isAuthenticated ? (
            <Person personQuery="me" />
          ) : (
            <Avatar aria-label="avatar" />
          )
        }
        data-cy="manage-account-icon"
      />
      <UserMenu
        anchorEl={anchorEl}
        userLogout={userLogout}
        navigate={navigate}
        handleClose={handleClose}
        userLogin={userLogin}
      />
    </>
  );
}

export default User;
