import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppFooter from 'components/footer';
import ContactOnboarding from 'components/contact-onboarding';
import ImageInformationSection from 'components/information-sections/image-information-section';

import { StyledDivider } from '../../home/styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const DataIntegration = () => {
  const { t } = useTranslation(['home']);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: t('home:dataIntegration'),
      description: t('home:dataIntegrationDescription'),
      imageSrc: '/illustrations/landing-page/integration.svg',
      imageAlt: 'integration',
      buttonText: t('home:goToProjects'),
      titleSection: true,
    },
    {
      title: t('home:connectedPlatforms'),
      description: t('home:connectedPlatformsDescription'),
      secondaryDescription: t('home:connectorsLearnMore'),
      imageSrc: '/illustrations/landing-page/platforms.svg',
      imageAlt: 'platforms',
      link: 'https://docs.ddb.arup.com/connectors/overview.html',
      linkText: t('home:here'),
      leftSection: true,
    },
    {
      title: t('home:api'),
      description: t('home:apiDescription'),
      imageSrc: '/illustrations/landing-page/hub.svg',
      imageAlt: 'hub',
    },
    {
      title: t('home:dataTransfer'),
      description: t('home:dataTransferDescription'),
      imageSrc: '/illustrations/landing-page/workflow.svg',
      imageAlt: 'workflow',
      leftSection: true,
    },
  ];

  return (
    <>
      <main id="main-content" tabIndex="-1" style={{ minHeight: 650 }}>
        <Container maxWidth="lg">
          {sections.map((section, index) => (
            <div key={section.title}>
              <ImageInformationSection
                title={section.title}
                description={section.description}
                secondaryDescription={section.secondaryDescription}
                imageSrc={section.imageSrc}
                imageAlt={section.imageAlt}
                buttonText={section.buttonText}
                titleSection={section.titleSection}
                isMdUp={isMdUp}
                link={section.link}
                linkText={section.linkText}
                leftSection={section.leftSection}
              />
              {index < sections.length - 1 && <StyledDivider />}
            </div>
          ))}
          <ContactOnboarding isMdUp={isMdUp} />
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default DataIntegration;
