import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { startCase } from 'lodash';
import { getUserGuideInfo } from 'api/graph';
import { getUserGuideLocation } from 'utils';

export default () => {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const userGuideLocation = getUserGuideLocation(pathname);

  const [contentLoading, setContentLoading] = useState(false);
  const [mainContent, setMainContent] = useState([]);

  useEffect(() => {
    let didCancel = false;

    const getFiles = async path => {
      setContentLoading(true);
      let mainFiles;
      try {
        mainFiles = await getUserGuideInfo(path);
      } catch (err) {
        if (err?.response?.status === 404) {
          await getFiles(`/user-guide/${userGuideLocation}/`);
        }
      }
      if (!didCancel && mainFiles) {
        setMainContent(
          mainFiles.map(({ content, heading }) => ({
            content,
            heading: startCase(heading.replace('.md', '')),
          }))
        );
        setContentLoading(false);
      }
    };
    if (userGuideLocation && isAuthenticated)
      getFiles(`/user-guide/${userGuideLocation}/`);

    return () => {
      didCancel = true;
    };
  }, [i18n.language, pathname, userGuideLocation, isAuthenticated]);

  return { contentLoading, mainContent };
};
