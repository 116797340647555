import { forwardRef } from 'react';
import { Grid, Link, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import HomePageStack from 'components/home-page-stack';
import { StyledTitleTypography } from './styles';

const CentraliseDataLink = forwardRef((props, ref) => (
  <Link
    to="/centralise-data"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));
const DataIntegrationLink = forwardRef((props, ref) => (
  <Link
    to="/data-integration"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));

const TrackChangesLink = forwardRef((props, ref) => (
  <Link
    to="/track-changes"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));

CentraliseDataLink.displayName = 'CentraliseDataLink';
DataIntegrationLink.displayName = 'DataIntegrationLink';
TrackChangesLink.displayName = 'TrackChangesLink';

const DDBUseCases = () => {
  const { t } = useTranslation(['home']);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const propsList = [
    {
      title: t('home:centraliseData'),
      description: t('home:centraliseDataDescription'),
      imageSrc: '/illustrations/landing-page/centraliseData.svg',
      imageAlt: 'centralise data',
      LinkComponent: CentraliseDataLink,
      learnMoreText: t('home:learnMore'),
    },
    {
      title: t('home:dataIntegration'),
      description: t('home:dataIntegrationDescription'),
      imageSrc: '/illustrations/landing-page/integration.svg',
      imageAlt: 'integration',
      LinkComponent: DataIntegrationLink,
      learnMoreText: t('home:learnMore'),
    },
    {
      title: t('home:trackChanges'),
      description: t('home:trackChangesDescription'),
      imageSrc: '/illustrations/landing-page/changes.svg',
      imageAlt: 'changes',
      LinkComponent: TrackChangesLink,
      learnMoreText: t('home:learnMore'),
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      data-testid="why-ddb"
      spacing={4}
    >
      <Grid item md={12}>
        <StyledTitleTypography
          variant={isMdUp ? 'h3' : 'h1'}
          component="h1"
          color="inherit"
        >
          {t('home:whyDDB')}
        </StyledTitleTypography>
      </Grid>
      {propsList.map(props => (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            pb: theme => theme.spacing(8),
          }}
          key={propsList.title}
        >
          <HomePageStack {...props} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DDBUseCases;
