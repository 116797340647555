/* eslint-disable no-param-reassign */
import { useState, useContext } from 'react';
import { parametersApi } from 'api';
import { AssetContext } from 'context';
import { isEmpty, uniqBy } from 'lodash';

export default type => {
  const { id, name, parentAssetSubTypeId, isSubType } = type;
  const typeId = parentAssetSubTypeId?.id || id;
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [error, setError] = useState('');

  const { assetManipulation, setAssetManipulation } = useContext(AssetContext);
  // eslint-disable-next-line no-shadow

  const loadAssetSubTypes = async () => {
    setAssetsLoading(true);
    const array = [];

    const getSubTypes = async after => {
      const query = { asset_type_id: id, sort_by: 'name', order: 'asc' };
      if (after) query.after = after;
      try {
        const response = await parametersApi('getAssetSubTypes', query);
        const { assetSubTypes, paging } = response;
        if (assetSubTypes) {
          const filteredSubTypes = assetSubTypes?.filter(
            subType =>
              subType.id !== '221ec1b6-7d80-4214-ab5c-fc1be03c66a1' &&
              subType.id !== '6d60139a-1c68-4e82-9380-0f1818667217' // Ocado specific asset sub types
          );
          array.push(...filteredSubTypes);
        }
        if (paging?.cursors?.after) {
          await getSubTypes(paging?.cursors?.after);
        }
      } catch (err) {
        setError(err);
      }
      return array;
    };
    if (!isSubType) {
      setAssetsLoading(false);
      setAssetManipulation(curr => {
        curr.isAddingNew[name] = true;
      });
      return;
    }

    const assetSubTypesList = await getSubTypes();
    if (!isEmpty(assetSubTypesList) && id) {
      const removeInvalidChildrenTypes = assetSubTypesList.filter(subType => {
        return (
          subType.parentAssetSubTypeId === type?.parentAssetSubTypeId?.id ||
          subType.parentAssetSubTypeId === null
        );
      });
      const filtered = removeInvalidChildrenTypes.filter(subType => {
        const alreadyExists = type.children.some(
          child => child.name === subType.name
        );
        return !alreadyExists;
      });
      if (isEmpty(removeInvalidChildrenTypes)) {
        setAssetManipulation(curr => {
          curr.isAddingNew[name] = false;
          curr.allSubTypesUsed[typeId] = true;
          curr.hasSubTypes[typeId] = [];
        });
      } else if (isEmpty(filtered)) {
        setAssetManipulation(curr => {
          curr.isAddingNew[name] = false;
          curr.allSubTypesUsed[typeId] = true;
        });
      } else {
        setAssetManipulation(curr => {
          curr.isAddingNew[name] = true;
          curr.allSubTypesUsed[typeId] = false;
          curr.isOpen[name] = true;
          if (isEmpty(curr.hasSubTypes[typeId])) {
            curr.hasSubTypes[typeId] = uniqBy([...filtered], 'id');
          } else {
            curr.hasSubTypes[typeId] = uniqBy(
              [...curr.hasSubTypes[typeId], ...filtered],
              'id'
            );
          }
        });
      }
    }
    setAssetsLoading(false);
  };

  return {
    loadAssetSubTypes,
    assetsLoading,
    setAssetsLoading,
    error,
    assetManipulation,
  };
};
