import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

export default () => {
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(true);

  const toggleDrawerOpen = () => setOpen(!open);

  useEffect(() => {
    setOpen(!screenMd);
  }, [screenMd]);

  return { toggleDrawerOpen, open };
};
