/* eslint-disable no-console */
import { isPlainObject, size } from 'lodash';
import axios from 'axios';
import { getToken } from 'auth';
import { camelCaseKeys, parseCookie } from 'utils';
import config from 'config';
import ApiError from './api-error';

const apiGenerator = async (
  client,
  operationId,
  query,
  cancelToken
  // eslint-disable-next-line consistent-return
) => {
  const api = await client;
  const token = await getToken();
  const apiVersion = api.spec.info.version.split('.')[0];
  const parsedCookie = parseCookie();

  if (api.url.includes('localhost')) api.spec.schemes = ['http'];

  const requestInterceptor = request => {
    const axiosRequest = {
      ...request,
      userFetch: async (url, req) => {
        const newRequest = {
          ...req,
          data: req.body,
          headers: {
            ...req.headers,
            Version: apiVersion,
            Anonymised: config?.featureFlags?.arePoliciesEnabled
              ? !parsedCookie?.analytic
              : false,
          },
          withCredentials: true,
          cancelToken,
          ...query?.response,
        };
        const axiosResponse = await axios(url, newRequest);
        // If body doesn't come back then give default value null
        // https://developer.mozilla.org/en-US/docs/Web/API/Response/Response
        const body = isPlainObject(axiosResponse.data)
          ? JSON.stringify(axiosResponse.data)
          : axiosResponse?.data || null;
        const init = {
          status: axiosResponse.status,
        };
        // If no keys for headers don't add to the response
        if (size(axiosResponse.headers)) init.headers = axiosResponse.headers;
        return new Response(body, init);
      },
    };
    return axiosRequest;
  };
  const request = {
    operationId,
    parameters: query,
    requestInterceptor,
    securities: { authorized: { api_key: { value: token } } },
  };

  if (query?.body) request.requestBody = query.body;

  try {
    const response = await api.execute(request);
    if (response.body) {
      return camelCaseKeys(response.body);
    }
    return response.data;
  } catch (err) {
    if (!axios.isCancel(err)) {
      /* istanbul ignore next */
      console.error('Error: ', operationId, { ...err });
      return Promise.reject(new ApiError(err));
    }
  }
};

export default apiGenerator;
