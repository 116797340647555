import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export default styled(Grid)(({ theme }) => ({
  gridTemplateColumns: '10fr 2fr',
  gridTemplateAreas: "'titlebar titlebar' 'filter button' 'order button'",
  display: 'grid',
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(1.5),
  flexGrow: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
  },
}));
