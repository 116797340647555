import { findIndex, isEmpty } from 'lodash';

const formatAssetTypeHierarchy = (parent, assetTypeTree, allAssets) => {
  const parentIndex = findIndex(allAssets, all => all.id === parent?.id);

  let assets = [];
  if (parentIndex !== -1 && !isEmpty(allAssets[parentIndex].children)) {
    assets = allAssets.filter(asset => {
      return asset.parent === allAssets[parentIndex].id;
    });
  } else if (parent.id === null) assets = allAssets;

  const response = [];
  if (assetTypeTree?.length) {
    assetTypeTree.forEach(assetType => {
      const childrenAssets = assets
        .filter(asset => asset.assetType?.id === assetType?.id)
        .map(childAsset => {
          return {
            ...childAsset,
            parentName: childAsset.parent
              ? allAssets.reduce((assetsArray, { id, name }) => {
                  if (id === childAsset.parent) return name;
                  return assetsArray;
                }, {})
              : '',
          };
        });

      response.push({
        id: assetType.id,
        name: assetType.name,
        children: childrenAssets,
        parent: parent?.id,
        parentAssetSubTypeId: parent?.assetSubType,
        parentDeleted: parent?.deletedAt,
        isSubType: assetType.assetSubType,
      });
    });
  }
  return response;
};

export default formatAssetTypeHierarchy;
