import {
  formatInternationalizedValue,
  formatValueUnit,
  formatRelativeDate,
} from 'utils';

export default function formatParameterInfo(parameter, i18n) {
  const status = parameter?.selectedEntry?.status || 'unanswered';
  const values = parameter?.selectedEntry?.values;
  const createdAt = parameter?.selectedEntry?.createdAt;
  const createdBy = parameter?.selectedEntry?.createdBy;
  const name = parameter?.parameterType?.name || '';
  const parents = parameter?.parents;
  const source = parameter?.selectedEntry?.source || {};
  const currValue = values?.[0];
  const value =
    currValue?.value.toString() || (currValue?.value === false ? 'false' : '');
  const internationalizedValue =
    parameter?.parameterType?.dataType === 'number' &&
    typeof values?.[0]?.value === 'number'
      ? formatInternationalizedValue(value, i18n.language)
      : value;
  const units = currValue?.unit?.symbol || '';
  const valueWithUnit = formatValueUnit(
    internationalizedValue,
    units,
    i18n.language
  );
  const assetName = parents?.[0]?.name || '';
  const staffName = createdBy?.staffName || '';
  const staffNameWithDate = `${staffName} ${formatRelativeDate(
    createdAt,
    undefined,
    i18n.language
  )}`;

  return {
    id: parameter.id,
    status,
    name,
    internationalizedValue,
    units,
    valueWithUnit,
    assetName,
    staffName,
    createdAt,
    staffNameWithDate,
    source,
    deletedAt: parameter.deletedAt,
  };
}
