import { Typography, Box, Skeleton } from '@mui/material';
import PT from 'prop-types';
import { parameterEventsPropTypes } from 'routes/history-page/history-content/prop-types/prop-types';
import { useTranslation } from 'react-i18next';
import useParameterAccordionDetails from './hooks/use-parameter-accordion-details';

const ParameterAccordionDetails = ({ history, expanded }) => {
  const { t } = useTranslation(['histories', 'common']);
  const { commentContent, isCommentLoaded } = useParameterAccordionDetails(
    history.comment,
    expanded
  );

  return (
    <Box aria-label="parameter-accordion-details">
      {history.eventType.endsWith('parameter.created') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-created-details"
        >
          <Typography variant="body2">
            {t('histories:parameterCreated')}
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('entry.replaced') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-replaced-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('common:dataDrawer:editForm:value')}: ${history.value}`}
          </Typography>
          {history.unit?.symbol && (
            <Typography
              variant="body2"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${t('common:dataDrawer:editForm:unit')}: ${
                history.unit.symbol
              }`}
            </Typography>
          )}
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('common:source')}: ${history.source.title}`}
          </Typography>
          {isCommentLoaded ? (
            <Typography
              variant="caption"
              fontWeight="500"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${t('common:comment')}: ${commentContent || ''}`}
            </Typography>
          ) : (
            <Skeleton variant="rectangular" width={800} height={20} />
          )}
        </Box>
      )}
      {history.eventType.endsWith('entry.revision.updated') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-revision-updated-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('histories:qaStatusChange')}: ${history.status}`}
          </Typography>
          {isCommentLoaded ? (
            <Typography
              variant="caption"
              fontWeight="500"
              aria-label="revision-comment"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${t('common:comment')}: ${commentContent || ''}`}
            </Typography>
          ) : (
            <Skeleton
              aria-label="revision-comment"
              variant="rectangular"
              width={800}
              height={20}
            />
          )}
        </Box>
      )}
      {history.eventType.endsWith('parameter.removed') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-deleted-details"
        >
          <Typography variant="body2">
            {t('histories:parameterDeleted')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ParameterAccordionDetails.propTypes = {
  expanded: PT.bool.isRequired,
  history: parameterEventsPropTypes.isRequired,
};

export default ParameterAccordionDetails;
