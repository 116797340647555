import { Typography, Box } from '@mui/material';
import { assetEventsPropTypes } from 'routes/history-page/history-content/prop-types/prop-types';
import { useTranslation } from 'react-i18next';

const AssetAccordionDetails = ({ history }) => {
  const { t } = useTranslation(['histories', 'common']);

  return (
    <Box aria-label="asset-accordion-details">
      {history.eventType.endsWith('asset.created') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="asset-created-details"
        >
          <Typography variant="body2">{t('histories:assetCreated')}</Typography>
        </Box>
      )}
      {history.eventType.endsWith('asset.updated') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="asset-updated-details"
        >
          <Typography variant="body2">
            {`${t('histories:assetUpdated')} `}
            <Typography
              variant="body2"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {history.name}
            </Typography>
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('asset.removed') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="asset-removed-details"
        >
          <Typography variant="body2">{t('histories:assetDeleted')}</Typography>
        </Box>
      )}
    </Box>
  );
};

AssetAccordionDetails.propTypes = {
  history: assetEventsPropTypes.isRequired,
};

export default AssetAccordionDetails;
