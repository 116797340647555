import { useContext } from 'react';
import { ReportingContext } from 'context';

export default (activeStep, setActiveStep) => {
  const { report, isContentLoading } = useContext(ReportingContext);

  const isNextDisabled =
    !!(activeStep === 0 && !report?.name) ||
    (activeStep === 1 && isContentLoading);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return {
    handleBack,
    handleNext,
    isNextDisabled,
  };
};
