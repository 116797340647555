import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  height: `calc(100vh - 64px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px)`,
  },
  flexGrow: 1,
  width: '95%',
  alignItems: 'center',
  overflow: 'auto',
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  paddingBottom: theme.spacing(0.5),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.getContrastText,
}));
