import { format, isValid, isToday } from 'date-fns';
import { localeDateMap, LANGUAGES } from 'i18n/languages';

export default (rawDate, locale = LANGUAGES[0]) => {
  const date = new Date(rawDate);
  if (!isValid(date)) {
    return '-';
  }

  if (isToday(date)) {
    return format(date, `'Today', HH:mm`, { locale: localeDateMap[locale] });
  }

  return format(date, 'dd MMM yyyy, HH:mm', { locale: localeDateMap[locale] });
};
