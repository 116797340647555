/* eslint-disable no-param-reassign */
import { useImmer } from 'use-immer';
import { useContext, useEffect } from 'react';
import { AssetContext } from 'context';

export default () => {
  const { allAssets, error } = useContext(AssetContext);
  const [summary, setSummary] = useImmer({});

  const handleToggleOpen = assetType => {
    setSummary(curr => {
      curr[assetType].open = !curr[assetType].open;
    });
  };

  useEffect(() => {
    const assets = {};
    allAssets
      .filter(asset => asset?.new || asset?.editedName)
      .forEach(asset => {
        if (assets[asset.assetType.name])
          assets[asset.assetType.name].children = [
            asset,
            ...assets[asset.assetType.name].children,
          ];
        else {
          assets[asset.assetType.name] = { open: true, children: [asset] };
        }
      });
    setSummary({ ...assets });
  }, [allAssets]); // eslint-disable-line react-hooks/exhaustive-deps

  return { handleToggleOpen, error, allAssets, summary };
};
