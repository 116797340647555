/* eslint-disable no-unused-expressions */
import { useTranslation } from 'react-i18next';
import { MenuBook } from '@mui/icons-material';
import { langNotInDocs, langNotToConvertFormat } from 'i18n/languages';
import TooltipButton from '../help';
import config from '../../../../config';

const DictionaryButton = () => {
  const { t, i18n } = useTranslation(['common', 'parameters']);

  const docsLang = !langNotToConvertFormat.includes(i18n.language)
    ? `${i18n.language.substring(0, 2)}-${i18n.language
        .substring(0, 2)
        .toUpperCase()}`
    : i18n.language;
  return (
    <TooltipButton
      titleHeader={t('parameters:dataDictionary')}
      titleBody={t('parameters:dataDictionaryConfirm')}
      onClick={() => {
        langNotInDocs.includes(i18n.language) || !i18n.language
          ? window.open(config.url.docsDataDictionary(''), '_blank')
          : window.open(config.url.docsDataDictionary(docsLang), '_blank');
      }}
      icon={<MenuBook color="primary" aria-label="dictionaryButton" />}
    />
  );
};

export default DictionaryButton;
