import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import { Collapse, Typography, Box, Grid, Skeleton } from '@mui/material';
import { Summarize } from '@mui/icons-material';
import StatusCircle from 'components/status-circle';
import { parameterType } from 'types';
import { DataGrid } from 'components/tables';
import SourceDetailCard from 'components/cards/source-detail-card';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import SourceButton from 'components/buttons/icons/source';
import useDetails from './hooks/use-details';
import EditEntry from './edit-entry';
import AddSource from '../../common/add-source';
import DataDrawerGrid from '../../common/grid';
import CommentForm from './reject-entry';
import RejectedAlert from './rejected-alert';

function Details({
  parameter,
  setParameter,
  loading: parameterLoading,
  setError,
  commentLoading,
  internationalizedValue,
  valueWithUnit,
}) {
  const { t } = useTranslation(['common', 'parameters']);

  const {
    entries,
    handleOpen,
    openRejectForm,
    setOpenRejectForm,
    openEditingForm,
    openSourceDetail,
    openDetails,
    openAddSource,
    setOpenAddSource,
    loading,
    getActionButtons,
    setOpenEditingForm,
    selectedSource,
    setSelectedSource,
    openRejectedAlert,
    setOpenRejectedAlert,
    handleOpenSourceDetails,
    formValues,
    setFormValues,
    heldFormValues,
    setHeldFormValues,
    openResetForm,
    setOpenResetForm,
    openApproveForm,
    setOpenApproveForm,
    openCheckForm,
    setOpenCheckForm,
  } = useDetails(parameter, setParameter, setError);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // MUI datagrid master detail needed to simplify showing detail data.
  const columns = [
    {
      field: 'action',
      hideable: false,
      headerName: t('common:action'),
      maxWidth: 80,
      renderCell: getActionButtons,
      sortable: false,
      disableExport: true,
    },
    {
      field: 'status',
      headerName: t('common:dataDrawer.status.title'),
      maxWidth: 80,
      renderCell: ({ value }) => (
        <Grid container display="grid" alignItems="center">
          {value === 'rejected' ? (
            <ExpandOrCollapseButton
              open={openRejectedAlert}
              onClick={() => setOpenRejectedAlert(!openRejectedAlert)}
              icon={<StatusCircle status={value} />}
            />
          ) : (
            <StatusCircle status={value} />
          )}
        </Grid>
      ),
    },
    {
      flex: 1,
      hideable: false,
      field: 'value',
      // type: parameter?.parameterType?.dataType || 'string',
      headerName: t('parameters:value'),
      valueGetter: () => {
        return parameter?.parameterType?.dataType === 'date'
          ? valueWithUnit
          : internationalizedValue;
      },
    },
    {
      flex: 0.5,
      field: 'units',
      headerName: t('common:dataDrawer.history.units'),
      hideable: false,
    },
    {
      flex: 2,
      field: 'source',
      headerName: t('parameters:source'),
      valueGetter: ({ value }) => value?.title || null,
      renderCell: ({ value, row }) => (
        <SourceButton
          title={`${
            openSourceDetail
              ? t('common:buttons.collapseButton')
              : t('common:buttons.expandButton')
          } ${t('parameters:source')}: ${value}`}
          disabled={!row.status}
          open={openSourceDetail}
          onClick={handleOpenSourceDetails}
        />
      ),
    },
  ];

  return (
    <Box width="100%" aria-label="detailsComponent">
      <DataDrawerGrid
        icon={<Summarize aria-label="detailsIcon" />}
        caption={
          <Typography variant="caption">{t('common:details')}</Typography>
        }
        content={
          <Box>
            {loading ? (
              <Skeleton height={30} width="100%" display="inline" />
            ) : (
              <>
                <ExpandOrCollapseButton
                  open={openDetails}
                  onClick={handleOpen}
                />
                <Typography
                  component="body"
                  variant="subtitle1"
                  display="inline"
                >
                  {parameter?.selectedEntry?.source?.title}
                </Typography>
              </>
            )}
            <Collapse in={openDetails}>
              <DataGrid
                // manually entered until master detail added
                height={177}
                getRowHeight={() => 50}
                columnVisibilityModel={{
                  ...columnVisibilityModel,
                  units: !!parameter?.parameterType?.unitTypeId,
                }}
                onColumnVisibilityModelChange={newModel =>
                  setColumnVisibilityModel(newModel)
                }
                exportName={`project-${parameter?.projectId}-parameter-${parameter?.id}-entries`}
                disableSelectionOnClick
                hideFooter
                aria-label="detailsTable"
                loading={parameterLoading}
                columns={columns}
                rows={entries}
              />
              {parameter?.selectedEntry?.status === 'rejected' &&
                openRejectedAlert && (
                  <RejectedAlert
                    parameter={parameter}
                    setOpenRejectedAlert={setOpenRejectedAlert}
                  />
                )}
              {openSourceDetail && (
                <SourceDetailCard
                  aria-label="sourceDetailCard"
                  source={parameter?.selectedEntry?.source}
                  id={parameter?.id}
                  loading={parameterLoading}
                  commentLoading={commentLoading}
                />
              )}
              {openRejectForm && (
                <CommentForm
                  aria-label="rejectForm"
                  setParameter={setParameter}
                  parameter={parameter}
                  setOpenCommentForm={setOpenRejectForm}
                  setError={setError}
                  formType="reject"
                />
              )}
              {openResetForm && (
                <CommentForm
                  aria-label="resetForm"
                  setParameter={setParameter}
                  parameter={parameter}
                  setOpenCommentForm={setOpenResetForm}
                  setError={setError}
                  formType="reset"
                />
              )}
              {openApproveForm && (
                <CommentForm
                  aria-label="approveForm"
                  setParameter={setParameter}
                  parameter={parameter}
                  setOpenCommentForm={setOpenApproveForm}
                  setError={setError}
                  formType="approve"
                />
              )}
              {openCheckForm && (
                <CommentForm
                  aria-label="checkForm"
                  setParameter={setParameter}
                  parameter={parameter}
                  setOpenCommentForm={setOpenCheckForm}
                  setError={setError}
                  formType="check"
                />
              )}
              {openEditingForm && (
                <EditEntry
                  aria-label="editForm"
                  parameter={parameter}
                  setOpenEditingForm={setOpenEditingForm}
                  setParameter={setParameter}
                  setOpenAddSource={setOpenAddSource}
                  selectedSource={selectedSource}
                  setSelectedSource={setSelectedSource}
                  setError={setError}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  heldFormValues={heldFormValues}
                  setHeldFormValues={setHeldFormValues}
                />
              )}
              {openAddSource && (
                <AddSource
                  openAddSource={openAddSource}
                  setOpenAddSource={setOpenAddSource}
                  setOpenEditingForm={setOpenEditingForm}
                  setSelectedSource={setSelectedSource}
                />
              )}
            </Collapse>
          </Box>
        }
      />
    </Box>
  );
}

Details.propTypes = {
  parameter: parameterType.isRequired,
  setParameter: PT.func.isRequired,
  loading: PT.bool,
  setError: PT.func.isRequired,
  commentLoading: PT.bool,
  internationalizedValue: PT.string.isRequired,
  valueWithUnit: PT.string.isRequired,
};

Details.defaultProps = {
  loading: false,
  commentLoading: true,
};

export default Details;
