import { useEffect, useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { isEmpty } from 'lodash';
import axios from 'axios';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context';

export default () => {
  const { user } = useContext(UserContext);
  const [openTermsConditions, setOpenTermsConditions] = useState(false);
  const [markdownLoading, setMarkdownLoading] = useState(true);
  const [openCookiesConsent, setOpenCookiesConsent] = useState(false);
  const [openCookiesWarning, setOpenCookiesWarning] = useState(false);
  const [cookieTypes, setCookieTypes] = useState([]);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [error, setError] = useState({});
  const [policyLastUpdated, setPolicyLastUpdated] = useState(
    new Date('Thu, 01 Jan 1971 00:00:00 GMT')
  );
  const [cookiesLastUpdated, setCookiesLastUpdated] = useState(
    new Date('Thu, 01 Jan 1971 00:00:00 GMT')
  );
  const [cookies, setCookie, removeCookie] = useCookies([
    'ddbTermsConditionsPolicyAccepted',
    'ddbCookiesPolicyAccepted',
  ]);
  const [cookiesAccepted, setCookiesAccepted] = useState({});
  const [apiDocs, setApiDocs] = useState([]);
  const [imageString, setImageString] = useState('');
  const [cookiesClean, setCookiesClean] = useState(true);
  const [managePreferences, setManagePreferences] = useState(false);
  const { t } = useTranslation(['cookies', 'footer']);

  useEffect(() => {
    if (
      cookies?.ddbCookiesPolicyAccepted &&
      cookies?.ddbCookiesPolicyAccepted?.essential
    )
      setCookiesAccepted({ ...cookies?.ddbCookiesPolicyAccepted });
    else if (!isEmpty(user.roles)) {
      setCookiesAccepted({
        acceptedDate: null,
        essential: true,
        analytic: user?.roles?.includes('App.Internal'),
        functional: false,
      });
    } else {
      setCookiesAccepted({
        acceptedDate: null,
        essential: true,
        analytic: false,
        functional: false,
      });
    }
  }, [cookies, user]);

  useEffect(() => {
    if (
      cookiesAccepted?.essential ===
        cookies?.ddbCookiesPolicyAccepted?.essential &&
      cookiesAccepted?.analytic ===
        cookies?.ddbCookiesPolicyAccepted?.analytic &&
      cookiesAccepted?.functional ===
        cookies?.ddbCookiesPolicyAccepted?.functional
    ) {
      setCookiesClean(true);
    } else {
      setCookiesClean(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesAccepted, cookies]);

  useEffect(() => {
    if (
      cookies?.ddbCookiesPolicyAccepted &&
      new Date(cookiesLastUpdated) >
        new Date(cookies?.ddbCookiesPolicyAccepted?.acceptedDate)
    ) {
      removeCookie('ddbCookiesPolicyAccepted', { path: '/' });
      setOpenCookiesConsent(true);
      setOpenTermsConditions(false);
      setOpenCookiesWarning(false);
    } else if (
      !cookies?.ddbCookiesPolicyAccepted ||
      !cookies?.ddbCookiesPolicyAccepted?.essential
    ) {
      setOpenCookiesConsent(true);
      setOpenTermsConditions(false);
      setOpenCookiesWarning(false);
    } else if (
      cookies?.ddbTermsConditionsPolicyAccepted &&
      new Date(policyLastUpdated) >
        new Date(cookies?.ddbTermsConditionsPolicyAccepted)
    ) {
      removeCookie('ddbTermsConditionsPolicyAccepted', { path: '/' });
      setOpenTermsConditions(true);
      setOpenCookiesConsent(false);
      setOpenCookiesWarning(false);
    } else if (!cookies?.ddbTermsConditionsPolicyAccepted) {
      setOpenTermsConditions(true);
      setOpenCookiesConsent(false);
      setOpenCookiesWarning(false);
    } else if (
      cookies?.ddbCookiesPolicyAccepted.analytic === false &&
      user?.roles?.includes('App.Internal')
    ) {
      setOpenTermsConditions(false);
      setOpenCookiesConsent(false);
      setOpenCookiesWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, user, cookiesLastUpdated, policyLastUpdated]);

  const onAcceptPolicyClick = () => {
    setCookie('ddbTermsConditionsPolicyAccepted', new Date().toISOString(), {
      path: '/',
      maxAge: 31536000,
    });
    setOpenTermsConditions(false);
    setAgreeChecked(false);
  };

  const onAcceptSelectedCookiesClick = () => {
    setCookie(
      'ddbCookiesPolicyAccepted',
      JSON.stringify({
        ...cookiesAccepted,
        acceptedDate: new Date().toISOString(),
      }),
      {
        path: '/',
        maxAge: 31536000,
      }
    );
    if (!cookiesAccepted.functional) {
      removeCookie('paletteType', { path: '/' });
      removeCookie('i18next', { path: '/' });
      removeCookie('lastSeenNotification', { path: '/' });
      removeCookie('ddbFavoriteProjects', { path: '/' });
    }
    if (!cookiesAccepted.analytical) {
      removeCookie('_ga ', { path: '/' });
      removeCookie('__utmz ', { path: '/' });
    }
    setOpenCookiesConsent(false);
    setManagePreferences(false);
  };

  const onAcceptAllCookiesClick = () => {
    setCookie(
      'ddbCookiesPolicyAccepted',
      JSON.stringify({
        essential: true,
        analytic: true,
        functional: true,
        acceptedDate: new Date().toISOString(),
      }),
      {
        path: '/',
        maxAge: 31536000,
      }
    );
    setOpenCookiesConsent(false);
    setManagePreferences(false);
  };

  const onAcceptAnalyticCookies = () => {
    setCookiesAccepted(curr => {
      return {
        ...curr,
        analytic: true,
      };
    });
    setCookie(
      'ddbCookiesPolicyAccepted',
      JSON.stringify({
        ...cookiesAccepted,
        analytic: true,
        acceptedDate: new Date().toISOString(),
      }),
      {
        path: '/',
        maxAge: 31536000,
      }
    );
    setOpenCookiesWarning(false);
  };

  const onManageCookiesClick = () => {
    setManagePreferences(true);
  };

  const onAnalyticChange = () => {
    setCookiesAccepted(curr => {
      return {
        ...curr,
        analytic: !cookiesAccepted.analytic,
      };
    });
  };

  const onFunctionalChange = () => {
    setCookiesAccepted(curr => {
      return {
        ...curr,
        functional: !cookiesAccepted.functional,
      };
    });
  };

  useEffect(() => {
    if (!isEmpty(cookiesAccepted)) {
      setCookieTypes([
        {
          type: t('cookies:essentialCookies'),
          cookies: [
            {
              name: t('cookies:essentialCookies'),
              description: t('cookies:essentialCookiesDescription'),
              disabled: true,
              checked: cookiesAccepted?.essential,
            },
          ],
        },
        {
          type: t('cookies:nonessentialCookies'),
          cookies: [
            {
              name: t('cookies:analyticCookies'),
              description: `${t('cookies:analyticCookiesDescription')} ${t(
                'cookies:disableCookieWarning'
              )} ${t('cookies:internalCookiesRequired')}`,
              disabled: user?.roles?.includes('App.Internal'),
              onChange: onAnalyticChange,
              checked: cookiesAccepted?.analytic,
              tooltip: t('cookies:internalCookiesRequired'),
            },
            {
              name: t('cookies:functionalityCookies'),
              description: `${t('cookies:functionalityCookiesDesciption')}  ${t(
                'cookies:disableCookieWarning'
              )}`,
              disabled: false,
              onChange: onFunctionalChange,
              checked: cookiesAccepted?.functional,
            },
          ],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, cookiesAccepted]);

  const githubDocs = async url => {
    const md = await axios.get(url, {
      headers: {
        Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`,
        Accept: 'application/vnd.github+json',
      },
    });
    if (isEmpty(md.data)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: { status: t('errors:msg404') },
        message: t('errors:submitError'),
      });
    }
    if (md?.data[0]?.commit) {
      return md?.data[0]?.commit;
    }
    return Buffer.from(md.data.content, md.data.encoding).toString();
  };

  const githubImage = async url => {
    const md = await axios.get(url, {
      headers: {
        Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`,
        Accept: 'application/vnd.github.raw',
      },
    });
    if (isEmpty(md.data)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: { status: t('errors:msg404') },
        message: t('errors:submitError'),
      });
    }
    return md?.data;
  };

  useEffect(() => {
    setError({});
    let apiContent;
    let policyDate;
    let cookiesDate;
    let ddbImage;
    const getDocs = async () => {
      try {
        apiContent = await githubDocs(config.integrations.policiesMarkdownAUP);
        policyDate = await githubDocs(config.integrations.policiesAUPCommits);
        cookiesDate = await githubDocs(
          config.integrations.policiesCookiesCommits
        );
        ddbImage = await githubImage(config.integrations.poweredDDBImage);
      } catch (err) {
        setMarkdownLoading(false);
        setError(err);
      }
      if (apiContent) {
        const dropLinks = apiContent.split(/## Supporting Documents/);
        const extLinks = dropLinks[0].replace(
          '(privacy-policy.md)',
          `(${config.url.policiesPrivacy})`
        );
        const dropHeader = extLinks.replace(
          'Acceptable Use Policy ("AUP")',
          ''
        );
        const splitImageStart = dropHeader?.split(
          `<a :href="$withBase('/Powered by DDB_large text.svg')"`
        );
        const splitImageEnd = splitImageStart[1]?.split(`</a>`);
        if (!isEmpty(splitImageStart) && !isEmpty(splitImageEnd)) {
          setApiDocs([splitImageStart[0], splitImageEnd[1]]);
        } else {
          setApiDocs([dropHeader]);
        }
      }
      if (ddbImage) {
        setImageString(ddbImage);
      }
      if (policyDate) {
        setPolicyLastUpdated(new Date(policyDate.committer?.date));
      }
      if (cookiesDate) {
        setCookiesLastUpdated(new Date(cookiesDate.committer?.date));
      }
      setMarkdownLoading(false);
    };
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const additionalLinks = [
    {
      text: 'Data Security',
      href: config.url.policiesDataSecurity,
      name: 'data-security',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      text: 'Data Residency',
      href: config.url.policiesDataResidency,
      name: 'data-residency',
      target: '_blank',
      rel: 'noreferrer',
    },
  ];

  return {
    additionalLinks,
    markdownLoading,
    setMarkdownLoading,
    openTermsConditions,
    setOpenTermsConditions,
    onAcceptPolicyClick,
    cookies,
    setCookie,
    removeCookie,
    cookiesLastUpdated,
    policyLastUpdated,
    agreeChecked,
    setAgreeChecked,
    openCookiesConsent,
    setOpenCookiesConsent,
    openCookiesWarning,
    setOpenCookiesWarning,
    onAcceptAllCookiesClick,
    onAcceptSelectedCookiesClick,
    managePreferences,
    setManagePreferences,
    onManageCookiesClick,
    cookieTypes,
    cookiesAccepted,
    setCookiesAccepted,
    onAnalyticChange,
    onFunctionalChange,
    cookiesClean,
    setCookiesClean,
    onAcceptAnalyticCookies,
    apiDocs,
    githubDocs,
    imageString,
    error,
  };
};
