import { useContext } from 'react';
import { ProjectContext } from 'context';
import { MetaTitle } from 'components/meta';

export default function ProjectMetadataTitle() {
  const { project, loading } = useContext(ProjectContext);
  return (
    <MetaTitle title={loading ? '' : project.shortTitle} translate={false} />
  );
}
