import { darken, lighten, styled } from '@mui/material/styles';
import { PriorityHigh } from '@mui/icons-material';

import { CircularProgress } from '@mui/material';
import RemovedAssetIcon from '../Icons/removed-asset';

const getStatusStyles = (status, theme, size) => {
  const statusStyleLookup = {
    unanswered: {
      position: 'absolute',
      zIndex: '2',
      color: theme.palette.grey[200],
    },
    answered: {
      position: 'absolute',
      zIndex: '3',
      color: theme.palette.error.main,
    },
    checked: {
      position: 'absolute',
      zIndex: '4',
      color: theme.palette.warning.light,
    },
    approved: {
      position: 'absolute',
      zIndex: '5',
      color: theme.palette.success.light,
    },
    rejected: {
      position: 'absolute',
      color: theme.palette.error.dark,
      width: size,
      height: size,
    },
    project: {
      position: 'absolute',
      zIndex: '5',
      color: theme.palette.primary.main,
    },
    deleted: {
      position: 'absolute',
      color: theme.palette.secondary.dark,
      width: size,
      height: size,
    },
  };
  return statusStyleLookup[status];
};

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  zIndex: '1',
  color:
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.background.default, 0.3)
      : darken(theme.palette.background.default, 0.2),
}));

export const StyledCircularProgressStatus = styled(CircularProgress)(
  ({ theme, status, size }) => ({
    ...getStatusStyles(status, theme, size),
  })
);

export const StyledPriorityHigh = styled(PriorityHigh)(
  ({ theme, status, size }) => ({
    ...getStatusStyles(status, theme, size),
  })
);

export const StyledDeleteOutline = styled(RemovedAssetIcon)(
  ({ theme, status, size }) => ({
    ...getStatusStyles(status, theme, size),
  })
);
