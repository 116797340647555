import PT from 'prop-types';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { Skeleton, Grid, Typography, Divider } from '@mui/material';
import { SwipeableDrawer } from '../drawer';
import Yammer from '../yammer';
import { getMarkdown, getMainMarkdown, getFaqs } from './hooks';
import MarkdownElement from '../markdown';
import Panel from './expansion-panel';
import { StyledContainer, StyledBox } from './user-guide-styles';
import { StyledIconButton } from '../buttons';

export default function UserGuide({
  userGuideOpen,
  toggleUserGuide,
  className,
}) {
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation(['user-guide']);
  const { introLoading, intro } = getMarkdown();
  const { contentLoading, mainContent } = getMainMarkdown();
  const { faqLoading, faq, faqIntro, faqIntroLoading } = getFaqs();

  return (
    <SwipeableDrawer
      open={userGuideOpen}
      onClose={toggleUserGuide}
      data-testid="userGuide"
      ModalProps={{ keepMounted: userGuideOpen }}
    >
      {isAuthenticated ? (
        <StyledContainer
          component="main"
          id="main-content"
          maxWidth="sm"
          tabIndex={-1}
          className={className}
        >
          {introLoading ? (
            <Skeleton height={150} width={500} />
          ) : (
            <>
              <StyledBox>
                <StyledIconButton
                  title={t('common:buttons.close')}
                  onClick={toggleUserGuide}
                  aria-label="close-button"
                  icon={<Close />}
                />
              </StyledBox>
              <MarkdownElement md={intro} />
            </>
          )}
          {!contentLoading && <Panel data={mainContent} />}
          <Divider />
          {faqLoading ? (
            <Skeleton height={150} width={500} />
          ) : (
            <>
              <MarkdownElement
                md={t('user-guide:developerInformation')}
                toggleUserGuide={toggleUserGuide}
                data-cy="docsLink"
              />
              <Divider />
              <MarkdownElement
                md={t('user-guide:integrations')}
                toggleUserGuide={toggleUserGuide}
                data-cy="integrationsLink"
              />
            </>
          )}
          <Divider />
          {faqIntroLoading ? (
            <Skeleton height={50} width={500} />
          ) : (
            <MarkdownElement md={faqIntro} />
          )}
          {faqLoading ? (
            <Skeleton height={150} width={500} />
          ) : (
            <>
              <Panel data={faq} />
              <Grid container alignItems="center">
                <Typography>{t('user-guide:yammer')}</Typography>
                <Yammer />
              </Grid>
            </>
          )}
        </StyledContainer>
      ) : (
        <StyledContainer
          component="main"
          id="main-content"
          maxWidth="sm"
          tabIndex={-1}
          className={className}
        >
          <StyledIconButton
            title={t('common:buttons.close')}
            sx={{ marginTop: theme => theme.spacing(3), float: 'right' }}
            onClick={toggleUserGuide}
            aria-label="close-button"
            icon={<Close />}
          />
          <Typography
            variant="h5"
            sx={{
              marginTop: theme => theme.spacing(4),
              fontWeight: theme => theme.typography.fontWeightBold,
            }}
            gutterBottom
          >
            {t('user-guide:title')}
          </Typography>
          <Typography>{t('user-guide:login')}</Typography>
        </StyledContainer>
      )}
    </SwipeableDrawer>
  );
}

UserGuide.propTypes = {
  userGuideOpen: PT.bool.isRequired,
  toggleUserGuide: PT.func.isRequired,
  className: PT.string,
};

UserGuide.defaultProps = {
  className: '',
};
