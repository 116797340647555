import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend'; // i18next-chained-backend should be placed here
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback http load
import { LANGUAGES } from './languages';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales), learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language, learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: LANGUAGES,
    load: 'currentOnly',
    fallbackLng: {
      default: [LANGUAGES[0]],
    },
    detection: {
      caches: [],
      order: ['cookie', 'navigation'],
    },
    fallbackNS: 'common',
    defaultNS: 'common',
    react: { useSuspense: true },
    debug: false,
    cache: { enabled: true },
    saveMissing: true,
    saveMissingTo: 'all',
    appendNamespaceToMissingKey: true,
    backend: {
      backends: [
        LocalStorageBackend, // primary
        HttpApi, // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'ddb_i18next_res_',
          // expiration
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          // Version applied to all languages, can be overriden using the option `versions`
          defaultVersion: '',
          // language versions
          versions: {},
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage,
        },
        {
          loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`, // xhr load path for my own fallback
          addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}.json`, // path to missing keys, NOTE: need actual server for this to work
        },
      ],
    },
  })
  .then(() => {
    i18n.services.pluralResolver.addRule('pl-PL', {
      numbers: [0, 1, 2],
      plurals(n) {
        if (n === 1) return Number(0);
        if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20))
          return Number(1);
        return Number(2);
      },
    });
  });

export default i18n;
