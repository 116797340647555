import { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { withAppInsights } from 'app/app-insights';
import { MetaTitle } from 'components/meta';
import { ResponsiveContainer } from 'components/drawer';
import config from 'config';
import UserDetails from './details';
import Cookies from './cookies';
import UserPermissions from './permissions';
import UserTheme from './theme';
import tabs from './tabs-list';

function UserSettings() {
  return (
    <main>
      <MetaTitle title="settings:userSettings" />
      <ResponsiveContainer
        navigationListTitle="settings:userSettings"
        navigationList={tabs}
      >
        <Routes>
          <Route
            path="details"
            element={
              <Suspense fallback={null}>
                <UserDetails />
              </Suspense>
            }
          />
          <Route
            path="permissions"
            element={
              <Suspense fallback={null}>
                <UserPermissions />
              </Suspense>
            }
          />
          <Route
            path="theme"
            element={
              <Suspense fallback={null}>
                <UserTheme />
              </Suspense>
            }
          />
          <Route
            path="cookies"
            element={
              config.featureFlags.arePoliciesEnabled ? (
                <Suspense fallback={null}>
                  <Cookies />
                </Suspense>
              ) : (
                <Navigate to="/error" replace />
              )
            }
          />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
      </ResponsiveContainer>
    </main>
  );
}

export default withAppInsights(UserSettings, 'UserSettingsPage');
