import { useContext } from 'react';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Tooltip } from '@mui/material';
import { withAppInsights } from 'app/app-insights';
import { ThemeContext } from 'context';
import config from 'config';
import { useCookies } from 'react-cookie';
import { StyledSwitch } from 'components/styles/switch-styles';

function UserTheme() {
  const { t } = useTranslation(['user', 'common']);
  const { handleThemeChange, paletteType, localTheme } =
    useContext(ThemeContext);
  const [cookies] = useCookies(['ddbCookiesPolicyAccepted']);
  return (
    <Box
      sx={{ padding: theme => theme.spacing(5) }}
      data-testid="user-settings-theme"
    >
      <Typography variant="h4" component="h1" mb={3} gutterBottom>
        {t('common:theme')}
      </Typography>
      <Typography variant="subtitle1" component="p" gutterBottom>
        {t('common:themeToggle')}
      </Typography>
      <Grid component="label" container spacing={1} alignItems="center">
        <Grid item>
          <Brightness4 />
        </Grid>
        <Grid item sx={{ mb: theme => theme.spacing(0.5) }}>
          <Tooltip
            title={
              !cookies?.ddbCookiesPolicyAccepted?.functional &&
              config.featureFlags.arePoliciesEnabled
                ? `${t('common:theme')} - ${localTheme} - ${t(
                    'cookies:requiresCookies',
                    { name: t('common:theme') }
                  )}`
                : `${t('common:theme')} - ${localTheme}`
            }
          >
            <span>
              <StyledSwitch
                palette={localTheme}
                aria-label="theme-toggle"
                checked={paletteType}
                onChange={handleThemeChange}
                name="theme"
                color="primary"
                data-cy="theme-toggle"
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Brightness7 />
        </Grid>
      </Grid>
    </Box>
  );
}

export default withAppInsights(UserTheme, 'UserThemePage');
