import ApiError from './api-error';

const handleApiError = (error, returnVal) => {
  if (error instanceof ApiError) return undefined;
  if (typeof returnVal === 'function') return returnVal();
  if (returnVal !== undefined) return returnVal;
  throw error;
};

export default handleApiError;
