import { TextField as MuiRffTextField } from 'mui-rff';
import PT from 'prop-types';

function TextField(props) {
  const { label, name, size, type, variant, ...rest } = props;
  return (
    <MuiRffTextField
      label={label}
      name={name}
      type={type}
      size={size || 'small'}
      variant={variant}
      {...rest}
    />
  );
}

TextField.propTypes = {
  label: PT.string.isRequired,
  name: PT.string.isRequired,
  size: PT.string,
  type: PT.string,
  variant: PT.string,
};

TextField.defaultProps = {
  size: 'small',
  type: 'text',
  variant: 'standard',
};
export default TextField;
