import { ListItemText, Typography, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  display: 'grid',
  margin: 0,
  maxWidth: '100%',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingLeft: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: theme.spacing(9),
  width: '100%',
  margin: 0,
  padding: 0,
}));

export const StyledTypographyCardInfo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '1.75rem',
  letterSpacing: '0.031em',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  paddingLeft: theme.spacing(0.25),
  fontSize: theme.typography.pxToRem(13),
  lineHeight: '1.75rem',
  letterSpacing: '0.031em',
  color: theme.palette.text.primary,
}));
