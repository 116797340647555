import { useEffect } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, CircularProgress, Grid, Box } from '@mui/material';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Search,
} from '@mui/icons-material';
import { ListBoxComponent } from 'components/pickers';
import Alert from 'components/alerts';
import { Autocomplete } from 'components/pickers/final-form-fields';
import config from 'config';
import { reorderArray } from 'utils';
import ParameterTypeImporter from '../import-params';
import getTypes from './hooks';

const ParameterSelector = ({ isAssetLoading, open, values, ...rest }) => {
  const { t } = useTranslation(['common', 'parameters']);
  const { setFormAttribute } = rest;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const {
    parametersLoading: isLoading,
    parameterTypes,
    openImportDrawer,
    handleOpen,
    setOpenImportDrawer,
    inputValue,
    setInputValue,
    allLoading,
    allParameterTypes,
    error,
  } = getTypes(open, isAssetLoading);

  useEffect(() => {
    const orderedParameterTypes = reorderArray(values.parameterTypes);
    setFormAttribute('parameterTypes', orderedParameterTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parameterTypes]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      height={isLoading ? '40vh' : 'auto'}
    >
      {isLoading && (
        <CircularProgress size={100} aria-label="loadingSkeleton" />
      )}
      {error && (
        <Box
          sx={{ padding: theme => theme.spacing(3) }}
          aria-label="import-alert"
        >
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      {!error && !isLoading && !openImportDrawer && (
        <Grid item xs={config.featureFlags.isCreateItemEnabled ? 10 : 12}>
          <Autocomplete
            name="parameterTypes"
            data-cy="select-parameter-box"
            aria-label="parameter-selector"
            variant="outlined"
            disableCloseOnSelect
            disableListWrap
            required
            value={values.parameterTypes || []}
            ListboxComponent={ListBoxComponent}
            options={parameterTypes}
            getOptionValue={option => option}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) =>
              option.parameterTypeId === value.parameterTypeId
            }
            onChange={(event, newValue) => {
              const newValues = newValue.map(value => value.parameterTypeId);
              const filteredItems = values.itemsImport?.filter(item =>
                newValues.includes(item.parameterTypeId)
              );
              setFormAttribute('itemsImport', filteredItems);
            }}
            loading={isLoading}
            multiple
            label={t('parameters:addParameters')}
            ChipProps={{ color: 'primary' }}
            renderOption={(props, option, { selected }) => (
              <li {...props} data-cy="selected-parameter">
                <Checkbox
                  data-cy="selected-parameter"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{
                    marginRight: theme => theme.spacing(2),
                    backgroundColor: 'primary',
                  }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') setInputValue(newInputValue);
              if (event?.type === 'blur') setInputValue('');
            }}
            inputValue={inputValue}
          />
        </Grid>
      )}
      {!error &&
        !isLoading &&
        !openImportDrawer &&
        config.featureFlags.isCreateItemEnabled && (
          <Grid item xs={2}>
            <Button
              data-cy="advanced-search-button"
              disabled={isLoading}
              onClick={handleOpen}
              sx={{ margin: theme => theme.spacing(2) }}
              endIcon={<Search />}
            >
              {t('parameters:cantFindParameter')}
            </Button>
          </Grid>
        )}
      {!isLoading && openImportDrawer && (
        <Grid item xs={12}>
          <ParameterTypeImporter
            values={values}
            setFormAttribute={setFormAttribute}
            isAssetLoading={isAssetLoading}
            open={openImportDrawer}
            setOpenImportDrawer={setOpenImportDrawer}
            currentAvailableParameter={parameterTypes}
            allParameterTypes={allParameterTypes}
            error={error}
            isLoading={allLoading}
            {...rest}
          />
        </Grid>
      )}
    </Grid>
  );
};

ParameterSelector.propTypes = {
  open: PT.bool.isRequired,
  values: PT.shape({
    parameterTypes: PT.arrayOf(PT.shape({})),
    itemsImport: PT.arrayOf(PT.shape({})),
  }).isRequired,
  isAssetLoading: PT.bool.isRequired,
};

export default ParameterSelector;
