import PT from 'prop-types';

const Highlighted = ({ text, highlight }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          // eslint-disable-next-line react/no-array-index-key
          <mark key={i} aria-label="highlighted-text">
            {part}
          </mark>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>{part}</span>
        );
      })}
    </span>
  );
};

Highlighted.propTypes = {
  text: PT.string,
  highlight: PT.string,
};
Highlighted.defaultProps = {
  text: '',
  highlight: '',
};
export default Highlighted;
