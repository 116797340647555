import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimelapseOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DataTemplateDetails from 'components/data-template-details';
import DataDrawerGrid from '../../routes/parameters-page/common/grid';

function Workstage() {
  const { t } = useTranslation(['common']);
  const [openWorkstage, setOpenWorkstage] = useState(false);
  const handleOpen = () => setOpenWorkstage(!openWorkstage);

  // TO DO: Workstage content

  return (
    <div aria-label="value component">
      <DataDrawerGrid
        icon={
          <TimelapseOutlined
            data-testid="workstage-icon"
            sx={{
              color: theme => theme.palette.primary.black,
            }}
          />
        }
        caption={
          <Typography
            variant="caption"
            color="textPrimary"
            data-testid="workstage-label"
          >
            {t('common:workstage')}
          </Typography>
        }
        content={
          <DataTemplateDetails
            open={openWorkstage}
            handleOpen={handleOpen}
            content="Workstage content"
            subtitle="common:open"
          />
        }
      />
    </div>
  );
}

Workstage.propTypes = {};

export default Workstage;
