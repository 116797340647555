import PT from 'prop-types';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

function InfiniteList({
  loadMoreRows,
  list,
  hasNextPage,
  loader,
  loading,
  children,
  loaderNumber,
  itemSize,
}) {
  const itemCount = hasNextPage ? list.length + 1 : list.length;
  const isItemLoaded = index => !hasNextPage || index < list.length;

  // eslint-disable-next-line react/prop-types
  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index) || loading) content = loader;
    else content = children[index];

    return (
      <div
        style={style}
        role="listitem"
        name="paramCardItem"
        data-testid="paramCardItem"
        data-cy="paramCardItem"
      >
        {content}
      </div>
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      loadMoreItems={loadMoreRows}
      itemCount={loading ? loaderNumber : itemCount}
      minimumBatchSize={50}
      threshold={50}
    >
      {({ onItemsRendered, ref }) => (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              innerElementType="ul"
              ref={ref}
              onItemsRendered={onItemsRendered}
              itemCount={loading ? loaderNumber : itemCount}
              height={height - 81}
              overscanCount={200}
              itemSize={itemSize}
              width={width}
            >
              {Item}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}

InfiniteList.propTypes = {
  list: PT.arrayOf(PT.shape({})).isRequired,
  loader: PT.node.isRequired,
  loaderNumber: PT.number.isRequired,
  itemSize: PT.number.isRequired,
  loadMoreRows: PT.func.isRequired,
  hasNextPage: PT.bool.isRequired,
  loading: PT.bool.isRequired,
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};

export default InfiniteList;
