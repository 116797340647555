import PT from 'prop-types';
import { Cancel, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function EditButton({ onClick, isEditing, disabled, type }) {
  const { renderTooltipEditCloseIcon } = useButton();
  const { t } = useTranslation(['common', 'parameters']);

  return (
    <StyledIconButton
      type={`edit${type}`}
      title={
        disabled
          ? t('common:dataDrawer.header.editPermissionTooltip')
          : renderTooltipEditCloseIcon(isEditing, type)[0]
      }
      variant="outlined"
      onClick={onClick}
      disabled={disabled || renderTooltipEditCloseIcon(isEditing, type)[1]}
      icon={
        isEditing ? <Cancel aria-label="Close" /> : <Edit aria-label="Edit" />
      }
    />
  );
}

EditButton.propTypes = {
  onClick: PT.func.isRequired,
  isEditing: PT.bool.isRequired,
  disabled: PT.bool,
  type: PT.string.isRequired,
};

EditButton.defaultProps = {
  disabled: null,
};

export default EditButton;
