import PT from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalOffer } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { GlobalTypesContext, UserContext } from 'context';
import DataTemplateDetails from 'components/data-template-details';
import Tags from '../../routes/parameters-page/common/tags';
import DataDrawerGrid from '../../routes/parameters-page/common/grid';

function TemplateTags({
  templateId,
  templateTags,
  setTemplateTags,
  loading,
  label,
}) {
  const { t } = useTranslation(['templates']);
  const { user } = useContext(UserContext);
  const { templateTypeTags } = useContext(GlobalTypesContext);
  const [openTags, setOpenTags] = useState(false);

  const handleOpen = () => setOpenTags(!openTags);

  return (
    <DataDrawerGrid
      icon={<LocalOffer data-testid="tags-icon" />}
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="tags-label"
        >
          {label === 'dataSet'
            ? t('templates:parameterTags')
            : t('templates:templateTags')}
        </Typography>
      }
      content={
        <DataTemplateDetails
          open={openTags}
          handleOpen={handleOpen}
          content={templateTypeTags.map(tagType => (
            <Tags
              referenceTable="template"
              id={templateId}
              existingTags={templateTags}
              setExistingTags={setTemplateTags}
              tagType={tagType}
              globalTags={tagType?.tags}
              key={tagType?.id}
              loading={loading}
              permission={user?.roles?.includes('App.Admin')}
            />
          ))}
          subtitle="common:tags"
        />
      }
    />
  );
}

TemplateTags.propTypes = {
  templateId: PT.string,
  templateTags: PT.arrayOf(PT.shape({})).isRequired,
  setTemplateTags: PT.func,
  loading: PT.bool.isRequired,
  label: PT.string,
};

TemplateTags.defaultProps = {
  templateId: '',
  setTemplateTags: () => {},
  label: '',
};

export default TemplateTags;
