import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledGrid = styled(Grid)(() => ({
  position: 'fixed',
  bottom: 0,
  width: 'calc(100vw - 70px)',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}));
