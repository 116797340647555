import { useTranslation } from 'react-i18next';
import { Inventory } from '@mui/icons-material';
import { Typography } from '@mui/material';
import AssetTypesSummary from './summary/summary';
import DataDrawerGrid from '../../../parameters-page/common/grid';

function AssetTypes() {
  const { t } = useTranslation(['common']);
  return (
    <DataDrawerGrid
      icon={
        <Inventory
          data-testid="asset-types-icon"
          sx={{
            color: theme => theme.palette.primary.black,
          }}
        />
      }
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="asset-types-label"
        >
          {t('common:assetTypes')}
        </Typography>
      }
      content={<AssetTypesSummary />}
    />
  );
}

export default AssetTypes;
