/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Chip,
  Tooltip,
  CircularProgress,
  ListItemButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Edit, Done, Clear } from '@mui/icons-material';
import PT from 'prop-types';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { useContext } from 'react';
import { AssetContext } from 'context';
import useItem from './hooks/use-item';

const Item = ({
  asset,
  columnIndex,
  index,
  assetInstanceIndex,
  selected,
  edited,
  setRemoveAssetWarning,
}) => {
  const { t } = useTranslation('common');
  const {
    onSelectClick,
    onDoneClick,
    onClearClick,
    onEditClick,
    textFieldError,
    editingValue,
    setEditingValue,
    onRemoveClick,
  } = useItem(
    asset,
    columnIndex,
    index,
    assetInstanceIndex,
    setRemoveAssetWarning
  );
  const { assetsLoading, assetWizardColumns, allRefs } =
    useContext(AssetContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const showDeletedAssets = parsedSearch.show_deleted_assets === 'true';

  if (showDeletedAssets && asset.deletedAt) {
    return (
      <ListItem
        ref={allRefs[asset.id]}
        sx={{
          padding: theme => theme.spacing(0.5),
          '&$selected, &$selected:hover': {
            backgroundColor: theme => theme.palette.action.selected,
          },
        }}
      >
        <ListItemButton
          aria-label="item-component-button"
          selected={selected}
          onClick={onSelectClick}
          transition-duration={0}
        >
          <ListItemText
            aria-label="deletedItem"
            sx={{
              textTransform: 'capitalize',
              color: theme => theme.palette.secondary.dark,
              pl: asset?.new ? 0 : 3,
              fontSize: '14px',
              wordWrap: 'break-word',
            }}
            disableTypography
          >
            {asset.name}
          </ListItemText>
          <ListItemIcon sx={{ maxWidth: 24, minWidth: 24 }}>
            <RemovedAssetIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    );
  }
  if (!asset.deletedAt) {
    return (
      <ListItem
        ref={allRefs[asset.id]}
        aria-label="item-component"
        data-cy={`wizard-column-child-item-${columnIndex}`}
        key={asset.id}
        transition-duration={0}
        sx={{
          padding: theme => theme.spacing(0.5),
          '&$selected, &$selected:hover': {
            backgroundColor: theme => theme.palette.action.selected,
          },
        }}
        secondaryAction={
          selected &&
          assetsLoading &&
          columnIndex === assetWizardColumns.length - 1 ? (
            <CircularProgress aria-label="loadingSpinner" />
          ) : !edited ? (
            <>
              {asset?.new && (
                <Tooltip title={t('common:buttons:cancel')}>
                  <Clear
                    sx={{ color: theme => theme.palette.primary.main }}
                    onClick={onRemoveClick}
                    data-cy="wizard-cancel-child"
                    aria-label="remove-icon"
                  />
                </Tooltip>
              )}
              {!asset.assetType.assetSubType && (
                <Tooltip title={t('common:edit')}>
                  <Edit
                    sx={{ color: theme => theme.palette.primary.main }}
                    data-cy="wizard-edit-child"
                    onClick={onEditClick}
                    aria-label="edit-icon"
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <Tooltip title={t('common:buttons:save')}>
                <Done
                  sx={{ color: theme => theme.palette.secondary.dark }}
                  onClick={onDoneClick}
                  data-cy="wizard-save-child"
                  aria-label="accept-icon"
                />
              </Tooltip>
              <Tooltip title={t('common:buttons:cancel')}>
                <Clear
                  sx={{ color: theme => theme.palette.secondary.dark }}
                  onClick={onClearClick}
                  data-cy="wizard-clear-child"
                  aria-label="cancel-icon"
                />
              </Tooltip>
            </>
          )
        }
      >
        {edited ? (
          <TextField
            aria-label="edited-item"
            autoFocus
            error={textFieldError}
            sx={{ width: 120, marginLeft: theme => theme.spacing(5) }}
            id={asset.id}
            value={editingValue}
            inputProps={{
              'data-cy': 'wizard-child-name',
            }}
            onChange={e => setEditingValue(e.target.value)}
            helperText={t(textFieldError)}
          />
        ) : (
          <ListItemButton
            aria-label="item-component-button"
            selected={selected}
            onClick={onSelectClick}
            transition-duration={0}
          >
            {asset?.new && (
              <Chip
                aria-label="new-chip"
                size="small"
                color="secondary"
                label={t('common:new')}
                sx={{ marginRight: 1, maxWidth: 50 }}
              />
            )}
            <ListItemText
              aria-label="item"
              sx={{
                textTransform: 'capitalize',
                color: theme =>
                  (asset?.new || asset?.editedName) &&
                  theme.palette.primary.main,
                pl: asset?.new ? 0 : 3,
                pr: 2,
                fontSize: '14px',
                wordWrap: 'break-word',
              }}
              disableTypography
            >
              {asset.name}
            </ListItemText>
          </ListItemButton>
        )}
      </ListItem>
    );
  }
  return null;
};

Item.propTypes = {
  columnIndex: PT.number.isRequired,
  index: PT.number.isRequired,
  assetInstanceIndex: PT.number.isRequired,
  asset: PT.shape({
    id: PT.string,
    name: PT.string,
    new: PT.bool,
    assetTypeId: PT.string,
    children: PT.arrayOf(PT.string),
    deletedAt: PT.string,
    assetType: PT.shape({ assetSubType: PT.bool }),
    parent: PT.string,
    editedName: PT.string,
  }).isRequired,
  selected: PT.bool.isRequired,
  edited: PT.bool.isRequired,
  setRemoveAssetWarning: PT.func.isRequired,
};
export default Item;
