import { AllInbox, BeachAccess } from '@mui/icons-material';
import { useMediaQuery, Button, Tooltip as ToolTip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import config from 'config';

function Home() {
  const screenSize = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { t } = useTranslation(['header']);
  const navigate = useNavigate();

  return (
    <ToolTip title={t('header:home')}>
      <Button
        component="span"
        color="inherit"
        size="large"
        sx={{
          marginRight: theme => theme.spacing(2.5),
          whiteSpace: 'nowrap',
          textTransform: 'none',
        }}
        onClick={() => navigate('/')}
        data-cy="homeButton"
      >
        {config.env === 'ut' ? (
          <BeachAccess
            fontSize="large"
            sx={{ marginRight: theme => theme.spacing(1) }}
          />
        ) : (
          <AllInbox
            fontSize="large"
            sx={{ marginRight: theme => theme.spacing(1) }}
          />
        )}
        {screenSize ? `Digital Design Brief` : `DDB`}
        {config.env === 'ut' && ' Sandbox'}
      </Button>
    </ToolTip>
  );
}

export default Home;
