const filterAssetsByType = (assets, ph) => {
  const filteredAssets = assets.filter(
    asset =>
      ph.assetType &&
      asset.assetType?.id === ph.assetType?.id &&
      (!ph.assetSubType || asset.assetSubType?.id === ph.assetSubType?.id)
  );
  return filteredAssets;
};

export default filterAssetsByType;
