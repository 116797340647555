import { BugReport as BugReportIcon } from '@mui/icons-material';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';
import config from 'config';
import { StyledIconButton } from '../../../buttons';

function Bugs({ title, handleClose }) {
  const { t } = useTranslation(['header']);

  return title ? (
    <MenuItem
      component="a"
      color="inherit"
      data-cy="bug-button"
      onClick={() => {
        window.open(config.url.bugs, '_blank');
        handleClose();
      }}
      aria-label={t('header:reportBug')}
    >
      <BugReportIcon data-testid="bug" />
      <Typography
        variant="button"
        sx={{ paddingLeft: theme => theme.spacing(1) }}
      >
        {title}
      </Typography>
    </MenuItem>
  ) : (
    <StyledIconButton
      title={t('header:reportBug')}
      component="a"
      color="inherit"
      onClick={() => window.open(config.url.bugs, '_blank')}
      aria-label={t('header:provideFeedback')}
      icon={<BugReportIcon data-testid="bug" />}
      data-cy="bug-button"
    />
  );
}

Bugs.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Bugs.defaultProps = {
  title: null,
  handleClose: null,
};

export default Bugs;
