const findSubTree = (
  formattedAssetsNode,
  assetTypeId,
  assetSubTypeId,
  parentAssetPlaceholderId
) => {
  if (
    (formattedAssetsNode?.assetType?.id === assetTypeId &&
      formattedAssetsNode.parentAssetPlaceholderId ===
        parentAssetPlaceholderId &&
      formattedAssetsNode?.assetSubType?.id === assetSubTypeId) ||
    parentAssetPlaceholderId === null
  ) {
    return formattedAssetsNode;
  }

  // Recursive case: traverse the children using array iteration
  if (formattedAssetsNode.children && formattedAssetsNode.children.length > 0) {
    const resultFormat = formattedAssetsNode.children.map(child =>
      findSubTree(child, assetTypeId, assetSubTypeId, parentAssetPlaceholderId)
    );
    return resultFormat.find(result => result);
  }
  return null;
};

export default findSubTree;
