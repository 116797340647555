import { langNotInDocs, langNotToConvertFormat } from 'i18n/languages';
import config from '../../config';

export const links = [
  {
    name: 'Github',
    href: config.url.repoFrontend,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'Sharepoint',
    href: config.url.sharepoint,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'Global Automation Sharepoint',
    href: config.url.globalAutoSharepoint,
    text: 'gaSharepoint',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'Yammer',
    href: config.url.yammerGroup,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'Mailing List',
    href: config.url.mailingList,
    text: 'mailingList',
    target: '_blank',
    rel: 'noreferrer',
  },
];

export const resources = lang => {
  const docsLang = langNotToConvertFormat.includes(lang)
    ? lang
    : `${lang.substring(0, 2)}-${lang.substring(0, 2).toUpperCase()}`;

  return [
    {
      text: 'footer:devDiaries',
      href: config.url.devDiaries,
      name: 'devDiaries',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      text: 'api-docs:apiDocs',
      href: langNotInDocs.includes(lang)
        ? config.url.docs('')
        : config.url.docs(docsLang),
      name: 'documentation',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      text: 'api-docs:integrations',
      href: langNotInDocs.includes(lang)
        ? config.url.docsConnectors('')
        : config.url.docsConnectors(docsLang),
      name: 'integrations',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      text: 'api-docs:userGuide',
      href: langNotInDocs.includes(lang)
        ? config.url.docsUserGuide('')
        : config.url.docsUserGuide(docsLang),
      name: 'userGuide',
      target: '_blank',
      rel: 'noreferrer',
    },
  ];
};

export const legal = [
  {
    text: 'footer:termsAndConditions',
    href: config.url.policiesAUP,
    name: 'polices-termsAndConditions',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'footer:privacyPolicy',
    href: config.url.policiesPrivacy,
    name: 'polices-privacyPolicy',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'footer:cookiesPolicy',
    href: config.url.policiesCookies,
    name: 'polices-cookiesPolicy',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'footer:arupModernSlaveryPolicy',
    href: config.url.policiesModernSlavery,
    name: 'modern-slavery-policy',
    target: '_blank',
    rel: 'noreferrer',
  },
];
