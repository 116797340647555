import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';
import CookieConsent from './accept-cookies';
import CookieWarning from './internal-cookies-warning';
import TermsConditionsConsent from './terms-and-conditions';

function AcceptableUseCookiesPopup() {
  const { openTermsConditions, openCookiesConsent, openCookiesWarning } =
    useGetCookies();
  return (
    <>
      {openCookiesConsent && <CookieConsent />}
      {openTermsConditions && <TermsConditionsConsent />}
      {openCookiesWarning && <CookieWarning />}
    </>
  );
}
export default AcceptableUseCookiesPopup;
