import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Link, Grid, Typography } from '@mui/material';
import { StyledStraplineTypography } from './styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const Description = () => {
  const { t } = useTranslation(['home']);

  return (
    <>
      <StyledStraplineTypography variant="h5" component="p" color="inherit">
        {t('home:strapline')}
      </StyledStraplineTypography>
      <Grid container>
        <Grid item sx={{ paddingBottom: theme => theme.spacing(4) }}>
          <Button
            component={ProjectsLink}
            sx={{ marginTop: 4 }}
            variant="contained"
            data-cy="go-to-projects"
            color="primary"
          >
            <Typography variant="body2">{t('home:goToProjects')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Description;
