import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  float: 'right',
}));

export const StyledContainer = styled(Container)(({ theme, ...props }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(6),
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: { padding: theme.spacing(1.5) },
  ...props.className,
}));
