import PT from 'prop-types';
import { MenuItem, Menu, ListItemIcon } from '@mui/material';
import {
  LockOpen,
  ExitToApp,
  ManageAccounts as ManageAccountsIcon,
} from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

function UserMenu({ anchorEl, handleClose, navigate, userLogout, userLogin }) {
  const { t } = useTranslation(['common', 'header']);
  const isAuthenticated = useIsAuthenticated();
  return (
    <Menu
      id="menu-user-account"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        data-cy="go-manage-account"
        onClick={() => {
          handleClose();
          navigate('/user-settings/details');
        }}
      >
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        {t('header:manageAccount')}
      </MenuItem>
      <MenuItem
        onClick={isAuthenticated ? userLogout : userLogin}
        data-cy="logout-button"
      >
        <ListItemIcon>
          {isAuthenticated ? <ExitToApp /> : <LockOpen />}
        </ListItemIcon>
        {isAuthenticated
          ? t('common:buttons.logout')
          : t('common:buttons.login')}
      </MenuItem>
    </Menu>
  );
}

UserMenu.propTypes = {
  anchorEl: PT.oneOfType([PT.object, PT.func]),
  handleClose: PT.func.isRequired,
  navigate: PT.func.isRequired,
  userLogout: PT.func.isRequired,
  userLogin: PT.func.isRequired,
};

UserMenu.defaultProps = {
  anchorEl: null,
};

export default UserMenu;
