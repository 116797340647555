import { styled } from '@mui/material/styles';
import { Grid, CardContent } from '@mui/material';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
}));

export const StyledCard = styled(CardContent)(`
padding: 0;
&:last-child {
  padding-bottom: 0;
}
`);
