import { Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import PageLayout from 'components/layout';
import Sidebar from 'components/sidebar';
import { SourcesProvider } from 'context';
import SourcesPanel from './sources-panel';
import SourcesContent from './sources-content/sources-content';

function SourcesPage() {
  return (
    <main>
      <PageLayout aria-label="page-layout">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Suspense fallback={null}>
                  <Sidebar id="sources" />
                </Suspense>
                <Suspense fallback={null}>
                  <SourcesProvider>
                    <SourcesContent />
                    <Outlet />
                  </SourcesProvider>
                </Suspense>
              </>
            }
          >
            <Route
              path=":sourceId"
              element={
                <Suspense fallback={null}>
                  <SourcesPanel />
                  <Outlet />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </PageLayout>
    </main>
  );
}

export default SourcesPage;
