import { Delete } from '@mui/icons-material';
import PT from 'prop-types';
import StyledIconButton from '../styled';
import useButton from '../../hooks/use-button';

function DeleteButton({ onClick, type, disabled, disabledTootlip }) {
  const { renderTooltipDeleteIcon } = useButton();

  return (
    <StyledIconButton
      type="delete"
      title={disabled ? disabledTootlip : renderTooltipDeleteIcon(type)[0]}
      variant="outlined"
      onClick={onClick}
      disabled={disabled || renderTooltipDeleteIcon()[1]}
      icon={<Delete />}
    />
  );
}

DeleteButton.propTypes = {
  onClick: PT.func.isRequired,
  type: PT.string,
  disabled: PT.bool,
  disabledTootlip: PT.string,
};
DeleteButton.defaultProps = {
  type: '',
  disabled: false,
  disabledTootlip: '',
};

export default DeleteButton;
