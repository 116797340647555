/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Link } from '@mui/material';
import { TextField, DatePicker } from 'components/pickers/final-form-fields';
import { SourcesContext } from 'context';
import { isValidUrl, formatDate } from 'utils';

import FinalForm from 'components/forms';
import useEditSource from './hooks/use-edit-source';

const SourcesForm = () => {
  const { selectedSource, openEditingForm } = useContext(SourcesContext);
  const { t, i18n } = useTranslation(['sources']);
  const { patchSelectedSource, validateSourceInputs } = useEditSource();

  const sourceWithoutUrl = [
    'f266bbf1-0126-40b9-8fda-9f92a2731af9',
    '6a1292c7-a245-41cf-8872-46feb9a7fd11',
  ];

  const revDate =
    selectedSource.dateDay === null ||
    selectedSource.dateMonth === null ||
    selectedSource.dateYear === null
      ? t('common:invalidDate')
      : formatDate(
          new Date(
            selectedSource.dateYear,
            selectedSource.dateMonth - 1,
            selectedSource.dateDay
          ),
          undefined,
          i18n.language
        );

  const details = [
    {
      key: 'sourceName',
      label: t('sources:sourceName'),
      value: selectedSource.title,
      editable: true,
      type: 'text',
    },
    {
      key: 'sourceReference',
      label: t('sources:sourceReference'),
      value: selectedSource.reference,
      editable: true,
      type: 'text',
    },
    {
      key: 'sourceType',
      label: t('sources:sourceType'),
      value: selectedSource.sourceType.name,
      editable: false,
      type: 'text',
    },
    {
      label: t('sources:sourceDate'),
      key: 'revisionDate',
      editable: true,
      type: 'date',
      value: new Date(revDate),
    },
  ];

  if (!sourceWithoutUrl.includes(selectedSource.sourceType.id)) {
    details.push({
      label: t('sources:sourceDirectory'),
      key: 'url',
      value: selectedSource.url || null,
      editable: !sourceWithoutUrl.includes(selectedSource.sourceType.id),
      type: 'text',
    });
  }

  const initialValues = {
    sourceName: selectedSource.title,
    sourceReference: selectedSource.reference,
    sourceType: selectedSource.sourceType.name,
    url: selectedSource.url,
    revisionDate: new Date(revDate),
  };

  return (
    <FinalForm
      title={null}
      onSubmit={patchSelectedSource}
      initialValues={initialValues}
      isPanel
      validate={validateSourceInputs}
      isEditing={openEditingForm}
      formFields={details.map(field => {
        return {
          field: (
            <Grid
              item
              xs={12}
              sx={{ paddingBottom: theme => theme.spacing(0.5) }}
            >
              {!openEditingForm && (
                <Typography variant="subtitle1">{field.label}</Typography>
              )}
              {openEditingForm && field.type === 'text' ? (
                <TextField
                  data-cy="edit-source-text-field"
                  label={field.label}
                  name={field.key}
                  placeholder={field.value}
                  multiline
                  maxRows={4}
                  required={field.required || false}
                  disabled={!field.editable}
                />
              ) : openEditingForm && field.type === 'date' ? (
                <DatePicker
                  placeholder={t('common:selectDate')}
                  InputLabelProps={{ shrink: true }}
                  label={field.label}
                  name={field.key}
                />
              ) : (
                <Typography
                  data-cy="source-text-field"
                  sx={{
                    padding: theme => theme.spacing(0.25),
                    color: theme => theme.palette.text.secondary,
                  }}
                  variant="body1"
                >
                  {isValidUrl(field.value) ? (
                    <Link
                      aria-label={`link-to-${field.value}`}
                      underline="always"
                      color="primary"
                      target="_blank"
                      variant="body1"
                      href={field.value}
                    >
                      {field.value}
                    </Link>
                  ) : field.type === 'date' ? (
                    revDate
                  ) : (
                    field.value
                  )}
                </Typography>
              )}
            </Grid>
          ),
        };
      })}
    />
  );
};

export default SourcesForm;
