import { styled, darken } from '@mui/material/styles';
import { Box } from '@mui/material';

export default styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? darken(theme.palette.secondary.main, 0.9)
      : theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider.main}`,
  height: `calc(100vh - 64px - 56px)`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 58px - 56px)`,
  },
}));
