/* eslint-disable react-hooks/exhaustive-deps */
import PT from 'prop-types';
import { useState, useCallback } from 'react';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { StyledBox, StyledBoxWithIcon, StyledInputBase } from './search-styles';

export default function SearchAppBar({
  handleSearch,
  searchValue,
  type,
  placeholder,
  disabled,
  endAdornment,
  maxLength,
  ...rest
}) {
  const { t } = useTranslation(['common']);
  const [searchPhrase, setSearchPhrase] = useState(searchValue);
  const [loading, setLoading] = useState(false);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
        setLoading(false);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const dbounce = useCallback(debounce(handleSearch, 1500), []);

  const handleChange = e => {
    setLoading(true);
    const { value: nextPhrase } = e.target;
    setSearchPhrase(nextPhrase);
    dbounce(nextPhrase);
  };

  return (
    <StyledBox>
      <StyledBoxWithIcon>
        {loading ? (
          <CircularProgress size={25} />
        ) : (
          <Search
            color={disabled ? 'disabled' : 'inherit'}
            aria-label="search-icon"
          />
        )}
      </StyledBoxWithIcon>
      <StyledInputBase
        {...rest}
        placeholder={placeholder || `${t('search')}...`}
        inputProps={{
          'aria-label': t('search'),
          maxLength,
        }}
        sx={{
          '& .MuiInputBase-input': { width: 'inherit' },
        }}
        value={searchPhrase}
        type={type}
        onChange={handleChange}
        endAdornment={endAdornment}
        fullWidth
        disabled={disabled}
      />
    </StyledBox>
  );
}

SearchAppBar.propTypes = {
  handleSearch: PT.func.isRequired,
  searchValue: PT.string.isRequired,
  type: PT.string,
  placeholder: PT.string,
  maxLength: PT.number,
  disabled: PT.bool,
  endAdornment: PT.node,
};

SearchAppBar.defaultProps = {
  type: 'text',
  placeholder: null,
  endAdornment: null,
  disabled: false,
  maxLength: null,
};
