import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Box, Slide } from '@mui/material';
import {
  Close as CloseIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Minimize as MinimizeIcon,
} from '@mui/icons-material';
import { UserContext, ThemeContext } from 'context';
import { useLocation } from 'react-router-dom';
import { StyledIconButton } from '../buttons';
import config from '../../config';

const Chatbot = () => {
  const { t } = useTranslation(['header', 'common']);
  const { user } = useContext(UserContext);
  const { localTheme } = useContext(ThemeContext);
  const [chatbotOpen, setChatbotOpen] = useState(true);
  const [isMinimized, setIsMinimized] = useState(true);
  const location = useLocation();

  const toggleChatbot = () =>
    setChatbotOpen(open => {
      if (!open) {
        setIsMinimized(false);
      }
      return !open;
    });
  const minimizeChatbot = () => {
    setIsMinimized(!isMinimized);
  };

  const getProjectId = () => {
    const pathSegments = location.pathname.split('/');
    const projectIndex = pathSegments.indexOf('projects');
    if (projectIndex !== -1 && pathSegments.length > projectIndex + 1) {
      return pathSegments[projectIndex + 1];
    }
    return null;
  };

  const projectId = getProjectId();
  return (
    <>
      <StyledIconButton
        title={t('header:chatbot')}
        color="inherit"
        onClick={toggleChatbot}
        aria-label="chatbot-header-button"
        data-testid="chatbot-header-button"
        data-cy="chatbot-button"
        icon={<QuestionAnswerIcon />}
      />
      <Slide
        direction={chatbotOpen ? 'left' : 'down'}
        in={chatbotOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 300, exit: 300 }}
        data-testid="chatbotPanel"
        aria-label="chatbotPanel"
      >
        <Box
          sx={{
            position: 'fixed',
            right: isMinimized ? '50px' : '30px',
            bottom: isMinimized ? '50px' : '30px',
            top: isMinimized ? 'auto' : '90px',
            height: isMinimized ? 'auto' : 'calc(100% - 120px)',
            width: isMinimized ? 'auto' : '600px',
            transition: 'all 0.3s ease',
            display: 'flex',
            flexDirection: 'column',
            // eslint-disable-next-line no-confusing-arrow
            backgroundColor: theme =>
              theme.palette.mode === 'dark'
                ? theme.palette.common.black
                : theme.palette.common.white,
            // eslint-disable-next-line no-confusing-arrow
            boxShadow: theme =>
              theme.palette.mode === 'dark'
                ? '0px 0px 10px rgba(255, 255, 255, 0.25)' // Lighter shadow for dark mode
                : '0px 0px 10px rgba(0, 0, 0, 0.25)',
            transform: chatbotOpen ? 'translateX(0)' : 'translateY(0)',
          }}
        >
          {isMinimized ? (
            <StyledIconButton
              title={t('common:buttons.expandButton')}
              color="primary"
              onClick={minimizeChatbot}
              sx={{
                position: 'fixed',
                bottom: '50px',
                right: '50px',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                backgroundColor: theme => theme.palette.primary.main,
                color: theme => theme.palette.common.white,
                '&:hover': {
                  backgroundColor: theme => theme.palette.primary.dark,
                },
              }}
              icon={<QuestionAnswerIcon sx={{ fontSize: '33px' }} />}
              data-testid="maximiseChatbotButton"
            />
          ) : (
            <Button
              title={t('common:buttons.minimise')}
              variant="contained"
              color="primary"
              fullWidth
              component="span"
              size="large"
              sx={{
                whiteSpace: 'nowrap',
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 0,
              }}
              onClick={minimizeChatbot}
              data-cy="chatbotMinimise"
              data-testid="chatbotTitleBar"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <QuestionAnswerIcon sx={{ marginRight: 1 }} />
                {t('common:chatbot')}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledIconButton
                  title={t('common:buttons.minimise')}
                  size="small"
                  sx={{
                    color: theme => theme.palette.common.white,
                  }}
                  onClick={minimizeChatbot}
                  icon={<MinimizeIcon />}
                  data-testid="minimizeButton"
                />
                <StyledIconButton
                  title={t('common:buttons.close')}
                  size="small"
                  sx={{
                    color: theme => theme.palette.common.white,
                  }}
                  onClick={toggleChatbot}
                  icon={<CloseIcon />}
                />
              </Box>
            </Button>
          )}
          <Container
            maxWidth="sm"
            tabIndex={-1}
            sx={{
              borderWidth: 0,
              padding: theme => theme.spacing(1),
              flexGrow: 1,
              display: isMinimized ? 'none' : 'flex',
              flexDirection: 'column',
              bottom: '90px',
            }}
          >
            <iframe
              style={{
                padding: theme => theme.spacing(0.5),
                width: '100%',
                height: '100%',
                borderWidth: 0,
                flexGrow: 1,
                display: isMinimized ? 'none' : 'block',
              }}
              name="embed-feed"
              title="Chatbot"
              src={`${config.url.api.base}/chatbot?user=${user.email}&theme=${localTheme}&projectId=${projectId}`}
            />
          </Container>
        </Box>
      </Slide>
    </>
  );
};

export default Chatbot;
