import { darken, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: 25,
  borderRadius: 4,
  backgroundColor:
    theme.palette.mode !== 'dark'
      ? theme.palette.grey[100]
      : darken(theme.palette.grey[500]),
}));

export const FixedBox = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
}));
