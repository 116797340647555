/* eslint-disable max-depth */
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { parametersApi, referenceDataApi } from 'api';

export default () => {
  const { parameterId } = useParams();
  const { state } = useLocation();
  const initialData = state ? state.parameter : {};
  const [parameter, setParameter] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    const getSourceComment = async sourceId => {
      setError(undefined);
      setCommentLoading(true);
      try {
        const apiResponse = await referenceDataApi(
          'getSources',
          {
            source_id: sourceId,
          },
          source.token
        );
        if (!didCancel && apiResponse) {
          setParameter({
            ...parameter,
            selectedEntry: {
              ...parameter.selectedEntry,
              source: {
                ...parameter.selectedEntry.source,
                comment: apiResponse.sources[0].comment,
              },
            },
          });
        }
      } catch (err) {
        setError(err.response.data);
      }
      setCommentLoading(false);
    };

    if (parameter?.selectedEntry?.source?.id) {
      getSourceComment(parameter?.selectedEntry?.source.id);
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameter.selectedEntry?.source?.id]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    const getParameter = async () => {
      setLoading(true);
      setError(undefined);
      try {
        const apiResponse = await parametersApi(
          'getParameter',
          {
            parameter_id: parameterId,
          },
          source.token
        );
        if (!didCancel && apiResponse) {
          const { parameter: response } = apiResponse;
          setParameter(response);
        }
      } catch (err) {
        setError(err.response.data);
      }
      setLoading(false);
    };

    if (state?.parameter) {
      setParameter(state.parameter);
      window.history.replaceState({}, '');
    } else if (parameterId) getParameter();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameterId, state]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    error,
    parameter,
    setParameter,
    loading,
    commentLoading,
  };
};
