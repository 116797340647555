import PT from 'prop-types';
import { useState } from 'react';
import { History as HistoryIcon } from '@mui/icons-material';
import {
  Skeleton,
  Box,
  Collapse,
  Typography,
  IconButton,
  Popover,
  Tooltip,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parameterType } from 'types';
import { DataGrid } from 'components/tables';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import StatusCircle from 'components/status-circle';
import { Person, PersonCard } from 'components/microsoft';
import { formatDate } from 'utils';
import DataDrawerGrid from '../grid';
import useVersionHistory from './hooks';

function History({ parameter, header, setSelectedHistoryRowId }) {
  const { t, i18n } = useTranslation(['common']);
  const { open, handleChangeOpen, history, loading } = useVersionHistory(
    parameter,
    setSelectedHistoryRowId
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const { dataSetId } = useParams();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEmail(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const columns = [
    {
      flex: 0.4,
      field: 'status',
      headerName: t('common:dataDrawer.status.title'),
      maxWidth: 80,
      renderCell: ({ value }) => <StatusCircle status={value} />,
    },
    {
      flex: 0.5,
      hideable: false,
      field: 'date',
      type: 'dateTime',
      maxWidth: 100,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: ({ value }) =>
        formatDate(value, undefined, i18n.language) || null,
      headerName: t('common:dataDrawer.date'),
    },
    {
      minWidth: 75,
      field: 'changed',
      headerName: t('common:dataDrawer.history.lastChanged'),
      valueGetter: ({ value }) => value?.email,
      renderCell: ({ value }) => {
        return (
          <Tooltip aria-label={`person-${value}`} title={value}>
            <IconButton aria-describedby={id} onClick={handleClick}>
              <Person
                personQuery={value}
                fallbackDetails={{
                  userPrincipalName: value,
                  mail: value,
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      flex: 1.5,
      hideable: false,
      field: 'value',
      headerName: t('common:dataDrawer.history.value'),
      valueGetter: ({ value: current }) =>
        current?.value || (current?.value === false ? 'false' : ''),
    },
    {
      flex: 0.5,
      field: 'units',
      headerName: t('common:dataDrawer.history.units'),
      hideable: false,
    },
    {
      flex: 1.5,
      field: 'source',
      headerName: t('common:source'),
      valueGetter: ({ value }) => value?.title || '',
    },
    {
      flex: 1.5,
      field: 'comment',
      headerName: t('common:comment'),
    },
  ];

  return (
    <Box width="100%" aria-label="historyComponent">
      <DataDrawerGrid
        icon={
          <HistoryIcon
            sx={{
              color: theme =>
                parameter?.deletedAt && theme.palette.secondary.dark,
            }}
          />
        }
        caption={
          <Typography variant="caption" color="textSecondary">
            {t('common:dataDrawer.history.versionHistory')}
          </Typography>
        }
        content={
          <Box>
            {loading ? (
              <Skeleton height={30} width="100%" display="inline" />
            ) : (
              <>
                <ExpandOrCollapseButton
                  open={open}
                  onClick={handleChangeOpen}
                  disabled={header === 'Not defined'}
                  sx={{ marginLeft: dataSetId && '-12px' }}
                />
                <Typography
                  component="body"
                  variant="subtitle1"
                  display="inline"
                  sx={{
                    color: theme =>
                      parameter?.deletedAt && theme.palette.secondary.dark,
                  }}
                >
                  {header}
                </Typography>
              </>
            )}
            <Collapse in={open}>
              <DataGrid
                columnBuffer={7}
                columns={columns}
                rows={history}
                autoHeight
                columnVisibilityModel={{
                  ...columnVisibilityModel,
                  units: !!parameter?.parameterType?.unitTypeId,
                }}
                onColumnVisibilityModelChange={newModel =>
                  setColumnVisibilityModel(newModel)
                }
                aria-label="detailsTable"
                onCellClick={({ value }) => setEmail(value)}
                exportName={`project-${parameter?.projectId}-parameter-${parameter?.id}-version-history`}
                disableSelectionOnClick
                hideFooter
                loading={loading}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
                setSelectedRowId={setSelectedHistoryRowId}
              />
            </Collapse>
          </Box>
        }
      />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PersonCard isExpanded={false} personQuery={email} />
      </Popover>
    </Box>
  );
}

History.propTypes = {
  parameter: parameterType,
  header: PT.string.isRequired,
  setSelectedHistoryRowId: PT.func,
};
History.defaultProps = {
  setSelectedHistoryRowId: () => {},
  parameter: {},
};
export default History;
