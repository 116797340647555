import { styled } from '@mui/material/styles';

export default styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(-30),
  marginTop: theme.spacing(-30),
  height: theme.spacing(90),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(40),
  },
}));
