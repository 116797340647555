import PT from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers-pro';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { localeDateMap } from 'i18n/languages';

function DatePicker({ label, format, value, onChange, variant }) {
  const { i18n } = useTranslation(['parameters']);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeDateMap[i18n.language]}
    >
      <MuiDatePicker
        renderInput={props => (
          <TextField label={label} variant={variant} {...props} />
        )}
        inputFormat={format}
        value={value}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  label: PT.string.isRequired,
  format: PT.string,
  variant: PT.string,
  value: PT.oneOfType([PT.string, PT.instanceOf(Date)]).isRequired,
  onChange: PT.func.isRequired,
};

DatePicker.defaultProps = {
  format: 'dd/MM/yyyy',
  variant: 'standard',
};

export default DatePicker;
