import { useEffect, useState, useContext } from 'react';
import { isEmpty, uniq } from 'lodash';
import { DataTemplatesContext } from 'context';
import { useTranslation } from 'react-i18next';

export default (assetNodes, selectedAssets) => {
  const { templateAssets } = useContext(DataTemplatesContext);
  const [requiredAssetsSelected, setRequiredAssetsSelected] = useState([]);
  const [requirementsCount, setRequirementsCount] = useState(0);
  const [validationError, setValidationError] = useState([]);
  const { t } = useTranslation(['templates']);

  const orderAssetsHierarchically = assets => {
    const orderedAssets = [];

    const addAssetAndChildren = asset => {
      orderedAssets.push(asset);
      assets
        .filter(child => child.parentAssetPlaceholderId === asset.id)
        .forEach(addAssetAndChildren);
    };

    assets
      .filter(asset => asset.parentAssetPlaceholderId === null)
      .forEach(addAssetAndChildren);

    return orderedAssets;
  };

  const templateAssetsOrdered = orderAssetsHierarchically(templateAssets);

  const validateDataSetTree = (templateAssetsArr, selectedAssetsArr) => {
    const topPlaceholder = templateAssetsArr.find(
      templateAsset => templateAsset.parentAssetPlaceholderId === null
    );
    const validationErrorsArray = [];
    templateAssetsArr.forEach(templateAsset => {
      // if the asset is the top level of the template, validate only one top level asset is selected
      if (templateAsset.id === topPlaceholder.id) {
        if (templateAsset.assetType) {
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (assetsAtLevel.length !== 1) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: `${t(
                'templates:wizardValidationMessages.assetsAssignedToPlaceholder',
                {
                  templateAssetName: !isEmpty(templateAsset.assetSubType)
                    ? templateAsset.assetSubType.name
                    : templateAsset.name,
                }
              )} ${t('templates:wizardValidationMessages.mustHaveLength')} ${
                templateAsset.count
              }`,
            });
          }
        }
      } else if (templateAsset.assetType) {
        // if the asset is the top (non-project level of the template, validate the correct number are selected
        const topAssetLevelPlaceholder = templateAssetsArr.find(
          asset => asset.parentAssetPlaceholderId === topPlaceholder.id
        );
        if (
          templateAsset.assetType.id === topAssetLevelPlaceholder.assetType.id
        ) {
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (
            topAssetLevelPlaceholder.count &&
            assetsAtLevel.length !== topAssetLevelPlaceholder.count
          ) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: `${t(
                'templates:wizardValidationMessages.assetsAssignedToPlaceholder',
                {
                  templateAssetName: !isEmpty(templateAsset.assetSubType)
                    ? templateAsset.assetSubType.name
                    : templateAsset.name,
                }
              )} ${t('templates:wizardValidationMessages.mustHaveLength')} ${
                templateAsset.count
              }`,
            });
          } else if (
            !topAssetLevelPlaceholder.count &&
            assetsAtLevel.length === 0
          ) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: `${t(
                'templates:wizardValidationMessages.assetsAssignedToPlaceholder',
                {
                  templateAssetName: !isEmpty(templateAsset.assetSubType)
                    ? templateAsset.assetSubType.name
                    : templateAsset.name,
                }
              )} ${t('templates:wizardValidationMessages.mustHaveAtLeast')}`,
            });
          }
        } else {
          const assetsAtParent = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId ===
              templateAsset.parentAssetPlaceholderId
          );
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (assetsAtParent.length) {
            assetsAtParent.forEach(asset => {
              const children = assetsAtLevel.filter(
                child => child.parent === asset.id
              );
              if (
                templateAsset.count &&
                children.length !== templateAsset.count
              ) {
                validationErrorsArray.push({
                  assetTypeId: templateAsset.assetType.id,
                  assetSubTypeId: templateAsset.assetSubType?.id,
                  parentAssetPlaceholderId:
                    templateAsset.parentAssetPlaceholderId,
                  details: `${t(
                    'templates:wizardValidationMessages.assetsAssignedToPlaceholderAndParent',
                    {
                      templateAssetName: !isEmpty(templateAsset.assetSubType)
                        ? templateAsset.assetSubType.name
                        : templateAsset.name,
                      parentAssetName: asset.name,
                    }
                  )} ${t(
                    'templates:wizardValidationMessages.mustHaveLength'
                  )} ${templateAsset.count}`,
                });
              } else if (!templateAsset.count && !children.length) {
                validationErrorsArray.push({
                  assetTypeId: templateAsset.assetType.id,
                  assetSubTypeId: templateAsset.assetSubType?.id,
                  parentAssetPlaceholderId:
                    templateAsset.parentAssetPlaceholderId,
                  details: `${t(
                    'templates:wizardValidationMessages.assetsAssignedToPlaceholderAndParent',
                    {
                      templateAssetName: !isEmpty(templateAsset.assetSubType)
                        ? templateAsset.assetSubType.name
                        : templateAsset.name,
                      parentAssetName: asset.name,
                    }
                  )} ${t(
                    'templates:wizardValidationMessages.mustHaveAtLeast'
                  )}`,
                });
              }
            });
          } else if (!assetsAtLevel.length) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: `${t(
                'templates:wizardValidationMessages.assetsMustBeAssigned'
              )} ${
                !isEmpty(templateAsset.assetSubType)
                  ? templateAsset.assetSubType.name
                  : templateAsset.name
              }`,
            });
          }
        }
      }
    });
    return validationErrorsArray;
  };

  useEffect(() => {
    const validationResult = validateDataSetTree(
      templateAssetsOrdered,
      selectedAssets
    );
    setValidationError(validationResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets]);

  useEffect(() => {
    const filterAssetsForRequirements = assetNode => {
      if (!isEmpty(selectedAssets)) {
        return assetNode?.children.reduce((acc, assetChild) => {
          if (!isEmpty(assetChild.children)) {
            acc.push(...filterAssetsForRequirements(assetChild));
          }
          if (
            selectedAssets.find(
              selectedAsset => selectedAsset.id === assetChild.id
            )
          ) {
            acc.push(assetChild);
          }
          return acc;
        }, []);
      }
      return [];
    };

    const selected = filterAssetsForRequirements(assetNodes[0]);
    setRequiredAssetsSelected(curr => uniq([...curr, ...selected]));
  }, [assetNodes, selectedAssets]);

  const requiredCount = assetsArr => {
    let count = 0;
    assetsArr?.forEach(assetType => {
      if (
        assetType?.assetType !== null &&
        (assetType.count === null || assetType.count === 1)
      ) {
        count += 1;
      }
    });
    return count;
  };

  useEffect(() => {
    const result = requiredCount(templateAssets);
    setRequirementsCount(result);
    templateAssets.forEach(templateAsset => {
      const matchAssetsWithTypes = requiredAssetsSelected.filter(
        selectedAsset =>
          selectedAsset.assetType.id === templateAsset?.assetType?.id &&
          selectedAsset?.assetSubType?.id === templateAsset?.assetSubType?.id
      );
      if (
        matchAssetsWithTypes.length === 1 &&
        templateAsset.count === 1 &&
        matchAssetsWithTypes[0].assetTypeCount === 1
      ) {
        setRequirementsCount(prevCount => {
          return prevCount - 1;
        });
      } else if (
        matchAssetsWithTypes.length >= 1 &&
        templateAsset.count === null &&
        matchAssetsWithTypes[0].assetTypeCount === null
      ) {
        setRequirementsCount(prevCount => {
          return prevCount - 1;
        });
      } else if (
        matchAssetsWithTypes.length > 1 &&
        templateAsset.count === 1 &&
        templateAsset?.assetSubType?.id
      ) {
        setRequirementsCount(prevCount => {
          return prevCount - 1;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredAssetsSelected]);

  return {
    requirementsCount,
    requiredAssetsSelected,
    validationError,
  };
};
