/* eslint-disable max-nested-callbacks */
import { useState, useEffect, useContext } from 'react';
import {
  useSearchParams,
  useNavigate,
  useParams,
  createSearchParams,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { DataTemplatesContext } from 'context';
import { formatValue, formatCurrentFilters } from '../helper';

export default (handleFilterActive, setFilterCount, isFilterActive) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId, dataTemplateId, dataSetId } = useParams();
  const navigate = useNavigate();
  const { dataTemplates, loading } = useContext(DataTemplatesContext);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (isFilterActive) {
      setFilters({
        sort_by: { sortBy: searchParams.get('sort_by') || 'created_at' },
        parameter_set_type_id: formatValue(
          searchParams.getAll('parameter_set_type_id'),
          'id'
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterActive]);
  const handleOnChange = (value, key) =>
    setFilters({ ...filters, [key]: value });

  const clearFilters = () => {
    setFilters({
      sort_by: { sortBy: 'created_at' },
      parameter_set_type_id: [],
    });
  };

  const sortByOptions = [
    { label: 'parameterList.createdBy', sortBy: 'created_by' },
    { label: 'parameterList.createdAt', sortBy: 'created_at' },
  ];

  const filtersMap = [
    {
      loading,
      id: 'dataTemplates',
      key: 'parameter_set_type_id',
      options: dataTemplates,
      isOptionEqualToValue: (opt, val) => opt?.id === val?.id,
    },
  ];

  const handleOnCancel = () => {
    handleFilterActive();
  };

  useEffect(() => {
    if (!loading && isFilterActive) {
      setFilters(curr => {
        const newFilters = { ...curr };
        Object.entries(newFilters).forEach(filterType => {
          const filterTypeName = filterType[0];
          const filterTypeList = filterType[1];
          const filterSet = filtersMap.find(
            filter => filter.key === filterTypeName
          );
          if (
            filterTypeName !== 'sort_by' &&
            !isEmpty(filterTypeList && filterSet?.options)
          ) {
            if (filterTypeName !== 'qa_status') {
              const withName = filterTypeList.map(value => {
                return filterSet?.options.find(tag => tag.id === value.id);
              });
              newFilters[filterTypeName] = withName;
            }
          }
        });
        return newFilters;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isFilterActive]);

  const countFilters = availableFilters => {
    let count = 0;
    Object.keys(availableFilters).forEach(key => {
      if (key !== 'sort_by') {
        count += availableFilters[key].length;
      }
    });
    setFilterCount(count);
  };

  const applyFilters = () => {
    const availableFilters = formatCurrentFilters(filters);
    const defaultSearchParams = {
      order: searchParams.get('order') || 'asc',
    };
    countFilters(availableFilters);
    if (dataTemplateId && dataSetId)
      navigate({
        pathname: `/projects/${projectId}/templates/template/${dataTemplateId}/set/${dataSetId}`,
        search: `?${createSearchParams({
          ...defaultSearchParams,
          ...availableFilters,
        })}`,
      });
    else setSearchParams({ ...defaultSearchParams, ...availableFilters });

    handleFilterActive();
  };

  return {
    filters,
    sortByOptions,
    applyFilters,
    filtersMap,
    handleOnChange,
    clearFilters,
    handleOnCancel,
    countFilters,
  };
};
