import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextSnippetOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'context';
import DataDrawerGrid from '../../common/grid';

function Value({ internationalizedValue, valueWithUnit }) {
  const { t } = useTranslation(['common']);
  const { localTheme } = useContext(ThemeContext);
  return (
    <div aria-label="value component">
      <DataDrawerGrid
        icon={<TextSnippetOutlined data-testid="icon" />}
        caption={
          <Typography
            variant="caption"
            color="textSecondary"
            data-testid="label"
          >
            {t('common:dataDrawer.content.value')}
          </Typography>
        }
        content={
          <Typography
            data-testid="value"
            color={
              // eslint-disable-next-line no-nested-ternary
              internationalizedValue
                ? 'textPrimary'
                : localTheme === 'dark'
                ? theme => theme.palette.error.light
                : 'error'
            }
          >
            {valueWithUnit || t('common:dataDrawer.notDefined')}
          </Typography>
        }
      />
    </div>
  );
}

Value.propTypes = {
  internationalizedValue: PT.oneOfType([PT.string, PT.bool, PT.number])
    .isRequired,
  valueWithUnit: PT.string.isRequired,
};

export default Value;
