import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import AppFooter from 'components/footer';
import {
  StyledContainer,
  StyledGrid,
  StyledTypography,
} from './unauthenticated-styles';

function Authorizing() {
  const { t } = useTranslation('home');

  return (
    <>
      <main id="main-content" tabIndex="-1" data-testid="authorising-page">
        <StyledContainer maxWidth="lg">
          <StyledGrid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item sm={12} md={6}>
              <StyledTypography
                variant="h3"
                component="h1"
                color="inherit"
                gutterBottom
              >
                Digital Design Brief
              </StyledTypography>
              <Typography variant="h5" component="p" color="inherit">
                {t('home:strapline')}
              </Typography>
              <Button
                variant="outlined"
                disabled
                data-cy="login-in-progress"
                startIcon={<CircularProgress size={30} thickness={6} />}
                sx={{ marginTop: theme => theme.spacing(4) }}
              >
                {t('home:loginInProgress')}
              </Button>
            </Grid>
            <Grid item xs>
              <Typography>{t('home:signInTips')}</Typography>
              <ul>
                <li>
                  <Typography>{t('home:blockedPopups')}</Typography>
                </li>
                <li>
                  <Typography>{t('home:inviteToLogin')}</Typography>
                </li>
              </ul>
            </Grid>
          </StyledGrid>
        </StyledContainer>
      </main>
      <AppFooter />
    </>
  );
}

export default Authorizing;
