import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(FormHelperText)(() => ({
  margin: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  whiteSpace: 'nowrap',
  display: 'flex',
}));
