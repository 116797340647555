import PT from 'prop-types';
import { useContext, useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterList, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import qs from 'qs';
import { Grid, Divider, Badge } from '@mui/material';
import { StyledIconButton, AddButton } from 'components/buttons';
import {
  ProjectPermissionContext,
  AssetContext,
  DataSetsWizardProvider,
  DataTemplatesProvider,
  AssetProvider,
} from 'context';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

import { isEmpty } from 'lodash';
import AddParameters from '../../parameters-wizard';

function Header({
  handleFilterActive,
  isFilterActive,
  filterCount,
  setFilterCount,
}) {
  const { isLoading, theAsset } = useContext(AssetContext);
  const { projectId, assetId, parameterId } = useParams();
  const navigate = useNavigate();
  const [parameterWizardOpen, setParameterWizardOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleParameterWizard = () =>
    setParameterWizardOpen(!parameterWizardOpen);
  const { writePermission } = useContext(ProjectPermissionContext);
  const { t } = useTranslation(['parameters, common']);
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  useEffect(() => {
    if (!isEmpty(parsedSearch) && setFilterCount) {
      // eslint-disable-next-line camelcase
      const { sort_by, order, ...filtersOnly } = { ...parsedSearch };
      let filtersLength = 0;
      if (filtersOnly.show_deleted_parameters === 'true') {
        filtersLength += 1;
      }
      delete filtersOnly.show_deleted_parameters;
      delete filtersOnly.project_parameter;
      delete filtersOnly.show_deleted_assets;
      filtersLength += Object.values(filtersOnly).flatMap(num => num).length;
      setFilterCount(filtersLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearch]);

  const handleSortDirection = () => {
    const search = {
      ...parsedSearch,
      order: parsedSearch.order === 'asc' ? 'desc' : 'asc',
    };
    if (parameterId)
      navigate({
        pathname: assetId
          ? `/projects/${projectId}/asset/${assetId}/parameters`
          : `/projects/${projectId}/parameters`,

        search: `?${createSearchParams(search)}`,
      });
    else setSearchParams(search);
  };

  const disabled = !!(
    !(parsedSearch.project_parameter || assetId) ||
    theAsset?.deletedAt ||
    isLoading
  );

  const renderToolTip = () => {
    if (!writePermission) return t('common:permission.notAllowed');
    if (theAsset?.deletedAt) return t('parameters:deletedAsset');
    if (disabled) return t('parameters:selectAsset');
    return t('parameters:addParameters');
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        data-testid="parameterListHeader"
        id="paramListHeader"
        sx={{
          backgroundColor: theme => theme.palette.action.hover,
        }}
      >
        <Grid item>
          <Badge
            aria-label="filters-badge"
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={filterCount}
            overlap="circular"
          >
            <StyledIconButton
              title={
                filterCount === 0
                  ? t('common:filterAndSort')
                  : t('common:filtersApplied', { filterCount })
              }
              onClick={handleFilterActive}
              aria-label="Filter and Sort"
              color="default"
              icon={
                <FilterList
                  data-testid="filterIcon"
                  sx={{
                    // eslint-disable-next-line no-confusing-arrow
                    color: theme =>
                      isFilterActive
                        ? theme.palette.action.main
                        : theme.palette.primary.active,
                  }}
                />
              }
            />
          </Badge>
          <StyledIconButton
            title={t('common:sortDirection')}
            onClick={handleSortDirection}
            aria-label="Sort direction"
            color="default"
            icon={
              parsedSearch.order === 'asc' ? (
                <ArrowUpward data-testid="asc" />
              ) : (
                <ArrowDownward data-testid="desc" />
              )
            }
          />
        </Grid>
        <Grid item>
          <AddButton
            disabled={disabled}
            tooltip={renderToolTip()}
            onClick={toggleParameterWizard}
            type="parameter"
          />
        </Grid>
      </Grid>
      <Divider light />
      {parameterWizardOpen && (
        <Suspense fallback={null}>
          <AssetProvider>
            <DataTemplatesProvider>
              <DataSetsWizardProvider>
                <AddParameters
                  open={parameterWizardOpen}
                  handleToggleOpen={toggleParameterWizard}
                />
              </DataSetsWizardProvider>
            </DataTemplatesProvider>
          </AssetProvider>
        </Suspense>
      )}
    </>
  );
}

Header.propTypes = {
  handleFilterActive: PT.func.isRequired,
  isFilterActive: PT.bool.isRequired,
  filterCount: PT.number,
  setFilterCount: PT.func,
};

Header.defaultProps = {
  filterCount: 0,
  setFilterCount: () => {},
};

export default Header;
