import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { StyledIconButton } from 'components/buttons';
import config from 'config';
import NotificationsButton from './button';

export default function Notifications({
  title,
  handleClose,
  lastSeen,
  handleNotificationsToggle,
  notificationList,
  notificationsOpen,
}) {
  const { t } = useTranslation(['header']);
  const [cookies] = useCookies(['ddbCookiesPolicyAccepted']);

  return title ? (
    <MenuItem
      component="a"
      color="inherit"
      onClick={() => {
        handleClose();
        handleNotificationsToggle();
      }}
      aria-label={t('header:notifications')}
    >
      <NotificationsButton
        lastSeen={lastSeen}
        notificationList={notificationList}
      />
      <Typography
        variant="button"
        sx={{ paddingLeft: theme => theme.spacing(1) }}
      >
        {title}
      </Typography>
    </MenuItem>
  ) : (
    <StyledIconButton
      title={
        !cookies?.ddbCookiesPolicyAccepted?.functional &&
        config.featureFlags.arePoliciesEnabled
          ? t('cookies:requiresCookies', {
              name: t('cookies:seenNotification'),
            })
          : t('header:notifications')
      }
      color="inherit"
      aria-label="notifications"
      aria-controls={notificationsOpen ? 'notifications-popup' : undefined}
      aria-haspopup="true"
      onClick={handleNotificationsToggle}
      icon={
        <NotificationsButton
          lastSeen={lastSeen}
          notificationList={notificationList}
        />
      }
    />
  );
}

Notifications.propTypes = {
  lastSeen: PT.number,
  title: PT.string,
  handleClose: PT.func,
  handleNotificationsToggle: PT.func.isRequired,
  notificationList: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
      createdBy: PT.string.isRequired,
      photo: PT.string,
      title: PT.string.isRequired,
      text: PT.string.isRequired,
      date: PT.string.isRequired,
      link: PT.string,
    })
  ),
  notificationsOpen: PT.bool.isRequired,
};

Notifications.defaultProps = {
  title: null,
  handleClose: null,
  lastSeen: null,
  notificationList: null,
};
