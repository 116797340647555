/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

export const formatAssetNavigation = (
  allAssets,
  parent,
  parentType,
  topAsset,
  parentPlaceholderId
) => {
  const assetTypes = [];
  const response = [];
  if (allAssets.length) {
    allAssets.reduce((acc, asset) => {
      if (
        asset.parent === parent ||
        (topAsset && asset.assetType?.id === parentType) ||
        asset?.parent === parentPlaceholderId
      ) {
        if (!assetTypes.includes(asset.assetType?.id)) {
          assetTypes.push(asset.assetType?.id);
          response.push({
            id: `${asset.assetType?.id}_${parent}`,
            name: asset.assetType?.name,
            assetType: {
              ...asset?.assetType,
              parent: asset?.assetType?.parent || parentType,
            },
            parent,
            setInfo: asset.isTypeOnly
              ? { ...asset }
              : { ...asset?.typeIndex } || null,
            assetSubType: {
              ...asset?.assetSubType,
            },
            isAssetInstance: false,
            deletedAt: null,
            children: [],
            isTypeOnly: asset.isTypeOnly || false,
            hasChildren: !isEmpty(asset.children),
          });
        }
        const index = assetTypes.indexOf(asset.assetType?.id);
        if (!asset.isTypeOnly) {
          response[index].children.push({
            id: asset?.id,
            hasChildren: !isEmpty(asset.children),
            parent: asset.parent,
            name: asset?.name,
            deletedAt: asset?.deletedAt || null,
            isAssetInstance: !asset.isTypeOnly,
            assetType: { ...asset?.assetType },
            assetSubType: { ...asset?.assetSubType },
            edited: asset?.edited,
            new: asset?.new,
            assetTypeCount: asset?.typeIndex?.count,
            assetPlaceholderId: asset?.typeIndex?.id,
            children: formatAssetNavigation(
              allAssets,
              asset?.id,
              asset?.assetType?.id,
              null,
              asset?.typeIndex?.id
            ),
          });
        }
      }
      return acc;
    }, []);
  }
  return response;
};
