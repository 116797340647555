import { isEmpty } from 'lodash';

const createTemplateParametersLookup = nestedObj => {
  const lookupTable = {};
  if (!isEmpty(nestedObj.parameters)) {
    lookupTable[nestedObj.id] = nestedObj.parameters;
  }
  if (nestedObj.children) {
    nestedObj.children.forEach(child => {
      const childLookupTable = createTemplateParametersLookup(child);
      Object.assign(lookupTable, childLookupTable);
    });
  }
  return lookupTable;
};

export default createTemplateParametersLookup;
