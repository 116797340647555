import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useIsAuthenticated } from '@azure/msal-react';
import { getSharepointInfo } from 'api/graph';
import config from 'config';

export default () => {
  const isAuthenticated = useIsAuthenticated();

  const [cookies, setCookie] = useCookies([
    'lastSeenNotification',
    'ddbCookiesPolicyAccepted',
  ]);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [lastSeen, setLastSeen] = useState(null);

  const notificationList = notifications
    ? notifications
        .filter(message => {
          if (
            message.userLanguage &&
            // to add in user language to notifications at a later date
            // message.userLanguage !== userLanguage &&
            message.userLanguage !== navigator.language.substring(0, 2)
          ) {
            return false;
          }
          return true;
        })
        .reverse()
    : null;

  useEffect(() => {
    let active = true;
    const source = axios.CancelToken.source();

    // Prevent search engines from indexing the notification.
    if (/glebot/.test(navigator.userAgent) || notifications) {
      return undefined;
    }

    const getNotificationList = async () => {
      let newNotifications = [];
      try {
        const { value } = await getSharepointInfo(
          '67e2bc0a-d145-4835-ad7e-a25f728d029a'
        );
        newNotifications = await Promise.all(
          value.map(async ({ createdBy, fields }) => {
            let link = null;
            if (fields?.Link?.Url) {
              if (fields.Link.Description.startsWith('/'))
                link = fields.Link.Description;
              else link = fields.Link.Url;
            }

            return {
              id: +fields.id,
              createdBy: createdBy.user.displayName,
              photo: createdBy.user.id,
              title: fields.Title,
              text: fields.Text,
              date: fields.Created,
              link,
            };
          })
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        if (!axios.isCancel(err)) console.error(err);
      }
      if (active) {
        const seen =
          cookies?.lastSeenNotification &&
          (cookies?.ddbCookiesPolicyAccepted?.functional ||
            !config.featureFlags.arePoliciesEnabled)
            ? cookies.lastSeenNotification
            : '';
        const lastSeenNotification = seen === '' ? 0 : parseInt(seen, 10);
        setNotifications(newNotifications);
        setLastSeen(lastSeenNotification);
      }
    };

    if (isAuthenticated) getNotificationList();

    return () => {
      source.cancel();
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleNotificationsToggle = () => {
    if (notificationList && notificationList.length > 0) {
      setLastSeen(notificationList[0].id);
      if (
        cookies?.ddbCookiesPolicyAccepted?.functional ||
        !config.featureFlags.arePoliciesEnabled
      ) {
        setCookie('lastSeenNotification', notificationList[0].id, {
          path: '/',
          maxAge: 31536000,
        });
      }
    }
    setOpen(prevOpen => !prevOpen);
  };

  return {
    handleNotificationsToggle,
    notificationsOpen: open,
    lastSeen,
    notificationList,
  };
};
