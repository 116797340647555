import PropTypes from 'prop-types';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './app-insights-config';

export default function AppInsightsProvider({ children }) {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
}

AppInsightsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
