import { useRef } from 'react';
import PT from 'prop-types';
import { Add, ArrowDropDown } from '@mui/icons-material';

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';

function SplitButton({
  options,
  buttonTitle,
  handleMenuItemClick,
  selectedIndex,
  open,
  setOpen,
  disabled,
}) {
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        data-cy={buttonTitle.id}
        variant="contained"
        ref={anchorRef}
        aria-label={buttonTitle.name}
        disableElevation
        data-testid="add-dropdown-button"
        color="primary"
      >
        <Button
          color="primary"
          aria-label={buttonTitle.name}
          data-testid="addDropdownButton"
          data-cy="addDropdownButton"
          onClick={() => {
            handleMenuItemClick(0);
          }}
          disabled={disabled}
        >
          <Add />
          <Typography variant="body1" pl={1}>
            {buttonTitle.name}
          </Typography>
        </Button>
        {options.length > 1 && (
          <Button
            disabled={disabled}
            size="small"
            color="primary"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            data-cy="split-button-menu"
            data-testid="split-button-menu"
            onClick={handleToggle}
          >
            <ArrowDropDown />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      data-cy={`button-menu-${option.id}`}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SplitButton.propTypes = {
  options: PT.arrayOf(PT.shape({})).isRequired,
  buttonTitle: PT.objectOf(PT.shape({ id: PT.string, name: PT.string }))
    .isRequired,
  handleMenuItemClick: PT.func.isRequired,
  selectedIndex: PT.number.isRequired,
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  disabled: PT.bool,
};

SplitButton.defaultProps = {
  disabled: false,
};

export default SplitButton;
