import {
  Receipt,
  Settings,
  LibraryBooks,
  HistoryEdu,
  Topic,
  History,
} from '@mui/icons-material';
import { useContext } from 'react';
import { UserContext } from 'context';
import config from '../../../config';

const useSideNavigation = (projectId, pathname) => {
  const { user } = useContext(UserContext);

  const sideNavigationTabs = [
    {
      id: 'parameters-nav',
      label: 'parameters:title',
      path: `/projects/${projectId}/parameters`,
      icon: <Receipt aria-label="Go to parameter page button" />,
      value: pathname.includes('/parameters'),
      userAccess: true,
      dataCy: 'side-nav-Parameters',
      justifyContent: 'flex-start',
    },
    {
      id: 'templates',
      label: 'parameters:templates',
      path: `/projects/${projectId}/templates`,
      icon: <LibraryBooks aria-label="Go to parameter templates page button" />,
      value: pathname.includes('/templates'),
      userAccess: config.featureFlags.areTemplatesEnabled,
      dataCy: 'side-nav-Sets',
      justifyContent: 'flex-start',
    },
    {
      id: 'reporting',
      label: 'reporting:reports',
      path: `/projects/${projectId}/reporting`,
      icon: <HistoryEdu aria-label="Go to reporting" />,
      value: pathname.includes('/reporting'),
      userAccess: config.featureFlags.isReportingEnabled,
      justifyContent: 'flex-start',
      dataCy: 'side-nav-Reporting',
      disabled: !user?.roles?.includes('App.Internal'),
    },
    {
      id: 'sources',
      label: 'sources:sources',
      path: `/projects/${projectId}/sources`,
      icon: <Topic aria-label="Go to sources" />,
      value: pathname.includes('/sources'),
      userAccess: config.featureFlags.areSourcesEnabled,
      justifyContent: 'flex-start',
      dataCy: 'side-nav-Sources',
      disabled: !user?.roles?.includes('App.Internal'),
    },
    {
      id: 'history',
      label: 'histories:history',
      path: `/projects/${projectId}/history`,
      icon: <History aria-label="Go to history" />,
      value: pathname.includes('/history'),
      userAccess: config.featureFlags.isProjectHistoryTabEnabled,
      justifyContent: 'flex-start',
      dataCy: 'side-nav-History',
      disabled: !user?.roles?.includes('App.Internal'),
    },
    {
      id: 'project-settings',
      label: 'settings:label',
      path: `/projects/${projectId}/settings/roles`,
      icon: <Settings aria-label="Go to settings page button" />,
      value: pathname.includes('/settings'),
      userAccess: config.featureFlags.isProjectSettingsEnabled,
      dataCy: 'side-nav-Settings',
      disabled: !user?.roles?.includes('App.Internal'),
    },
  ].filter(tab => tab.userAccess);

  const tabsLookup = Object.fromEntries(
    sideNavigationTabs.map((tab, index) => [tab.id, index])
  );

  return { sideNavigationTabs, tabsLookup };
};

export default useSideNavigation;
