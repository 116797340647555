import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { getUserGuideInfo } from 'api/graph';

export default () => {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const [introLoading, setIntroLoading] = useState(true);
  const [intro, setIntro] = useState('');

  useEffect(() => {
    const fallbackPath = `/user-guide/intro.md`;
    let didCancel = false;
    setIntroLoading(true);

    const getFiles = async path => {
      let introContent;
      try {
        introContent = await getUserGuideInfo(path);
      } catch (err) {
        if (err?.response?.status === 404) {
          await getFiles(fallbackPath);
        }
      }
      if (!didCancel && introContent) {
        setIntro(introContent);
        setIntroLoading(false);
      }
    };

    if (isAuthenticated) getFiles(`/user-guide/intro.md`);

    return () => {
      didCancel = true;
    };
  }, [i18n.language, isAuthenticated]);

  return { intro, introLoading };
};
