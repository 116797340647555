import { styled } from '@mui/material/styles';
import { Container, Drawer } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme, ...props }) => ({
  flexShrink: 0,
  width: props.width,
  '& .MuiDrawer-paper': {
    width: props.open ? props.width : theme.spacing(7),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: props.open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

export const ContainerMdUp = styled(Container)(({ theme }) => ({
  overflowY: 'hidden',
  height: `calc(100vh - 56px)`,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: `calc(100vh - 48px)`,
  },
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - 48px)`,
  },
}));

export const ContainerMdDown = styled(Container)(({ theme }) => ({
  overflowY: 'hidden',
  height: `calc(100vh - 56px - 56px)`,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: `calc(100vh - 48px - 56px)`,
  },
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - 64px - 56px)`,
  },
}));
