import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Skeleton,
  Divider,
  Chip,
  Tooltip,
} from '@mui/material';
import Highlighted from 'components/highlighted';
import { lighten, darken } from '@mui/material/styles';
import { useContext } from 'react';
import { ThemeContext } from 'context';
import { isEmpty } from 'lodash';

function MainContent({ project, loading, searchValue }) {
  const { t } = useTranslation(['projects', 'home']);
  const { localTheme } = useContext(ThemeContext);
  const { confidential, scopeOfWorks, shortTitle, number, roles } = {
    ...project,
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {loading ? (
        <>
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
              width: '40%',
            }}
            data-testid="loading"
          />
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
              width: '40%',
            }}
            data-testid="loading"
          />
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
              width: '40%',
            }}
            data-testid="loading"
          />
        </>
      ) : (
        <Tooltip title={shortTitle} placement="bottom">
          <Grid container sx={{ height: 100 }}>
            <Grid item xs={confidential ? 8 : 12}>
              <Typography gutterBottom variant="body2" color="textSecondary">
                <Highlighted
                  text={`${t('projects:projectNumber')}: ${number}`}
                  highlight={searchValue}
                />
              </Typography>
            </Grid>
            {confidential && (
              <Grid item xs={4} data-cy="confidential-project-chip">
                <Typography variant="body2" color="error" fontWeight="bold">
                  {t('home:confidential')}
                </Typography>
              </Grid>
            )}
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              <Highlighted text={shortTitle} highlight={searchValue} />
            </Typography>
          </Grid>
        </Tooltip>
      )}
      {loading ? (
        <>
          <br />
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
            }}
            data-testid="loading"
          />
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
            }}
            data-testid="loading"
          />
          <Skeleton
            sx={{
              height: theme => theme.spacing(2),
              width: '40%',
            }}
            data-testid="loading"
          />
        </>
      ) : (
        <Grid item sx={{ height: 140 }}>
          <Divider />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            sx={{
              paddingTop: theme => theme.spacing(1),
            }}
          >
            {isEmpty(scopeOfWorks) || scopeOfWorks.length < 175
              ? scopeOfWorks
              : `${scopeOfWorks.slice(0, 175)}...`}
          </Typography>
        </Grid>
      )}
      {!loading && (
        <Grid item sx={{ height: 100 }}>
          {roles?.map(role => {
            return (
              <Chip
                aria-label="roleChip"
                sx={{
                  margin: theme => theme.spacing(0.25),
                  color: theme => theme.palette.textSecondary,
                  backgroundColor:
                    localTheme === 'dark'
                      ? theme => darken(theme.palette.primary.main, 0.3)
                      : theme => lighten(theme.palette.primary.main, 0.8),
                }}
                label={role.name}
                key={role.name}
              />
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
MainContent.propTypes = {
  project: PT.shape({
    projectDirectorName: PT.string,
    centreCode: PT.string,
    projectManagerName: PT.string,
    scopeOfWorks: PT.string,
  }),
  loading: PT.bool.isRequired,
  searchValue: PT.string,
};

MainContent.defaultProps = {
  project: {
    projectDirectorName: '',
    centreCode: '',
    projectManagerName: '',
    scopeOfWorks: '',
  },
  searchValue: '',
};

export default MainContent;
