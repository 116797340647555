import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';
import CookiesItems from '../cookies-items';
import CookiesHeader from '../cookies-header';

function CookieConsent() {
  const {
    openCookiesConsent,
    onAcceptAllCookiesClick,
    onManageCookiesClick,
    managePreferences,
    onAcceptSelectedCookiesClick,
    cookieTypes,
  } = useGetCookies();
  const { t } = useTranslation(['cookies', 'footer']);

  return (
    <Dialog
      open={openCookiesConsent}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        component="span"
        id="scroll-dialog-title"
        sx={{ padding: theme => theme.spacing(4) }}
      >
        <CookiesHeader />
      </DialogTitle>
      {managePreferences && (
        <DialogContent dividers sx={{ padding: theme => theme.spacing(4) }}>
          <DialogContentText
            component="span"
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {cookieTypes.map(type => {
              return <CookiesItems key={type.type} cookieList={type} />;
            })}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container width="100%">
          <Grid
            display="flex"
            justifyContent="flex-end"
            item
            xs={12}
            sx={{ marginTop: theme => theme.spacing(1) }}
          >
            {!managePreferences && (
              <Button
                sx={{ width: '35%', margin: theme => theme.spacing(1) }}
                aria-label="manage-cookie-button"
                variant="contained"
                onClick={onManageCookiesClick}
              >
                {t('cookies:manageCookies')}
              </Button>
            )}
            <Button
              sx={{ width: '35%', margin: theme => theme.spacing(1) }}
              aria-label="accept-all-cookie-button"
              variant="contained"
              onClick={onAcceptAllCookiesClick}
            >
              {t('cookies:acceptAll')}
            </Button>
            {managePreferences && (
              <Button
                sx={{ width: '40%', margin: theme => theme.spacing(1) }}
                aria-label="accept-selected-cookie-button"
                variant="contained"
                onClick={onAcceptSelectedCookiesClick}
              >
                {t('cookies:acceptSelection')}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default CookieConsent;
