import { useTranslation } from 'react-i18next';
import { AssetContext, GlobalTypesContext } from 'context';
import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import Alert from 'components/alerts';
import AddAssets from '../add-assets';
import Summary from '../summary';

export default toggleOpen => {
  const { setAssetTypeTrees } = useContext(GlobalTypesContext);
  const {
    handleError,
    setAssetManipulation,
    setIndex,
    setIsLoading,
    setTheAsset,
    postAssets,
    patchAssets,
    setAssetWizardColumns,
  } = useContext(AssetContext);

  const { t } = useTranslation(['common', 'parameters', 'errors']);
  const [error, setError] = useState(undefined);

  const openOrClose = () => {
    setError(undefined);
    toggleOpen();
    setAssetManipulation({
      isOpen: {},
      isSelected: {},
      isEdited: {},
      isAddingNew: {},
      hasSubTypes: {},
      allSubTypesUsed: {},
    });
  };

  const handleClose = () => {
    setError(undefined);
    setIndex(0);
    setTheAsset({});
    setAssetTypeTrees([]);
    setAssetWizardColumns([]);
    openOrClose();
    handleError();
  };

  const submit = async () => {
    setError(undefined);
    setIsLoading(true);
    try {
      await postAssets();
      await patchAssets();
      setIndex(0);
      setAssetWizardColumns([]);
      setAssetTypeTrees([]);
      setTheAsset({});
      openOrClose();
      setIsLoading(false);
    } catch (err) {
      setError(err?.response?.data);
      setIsLoading(false);
    }
  };

  const steps = [
    {
      label: t('parameters:assets'),
      optional: false,
      header: t('parameters:addAssetToTree'),
      content: props => <AddAssets {...props} />,
    },
    {
      label: t('parameters:summary'),
      optional: false,
      header: t('parameters:addAssetConfirmation'),
      content: props => (
        <>
          {!!error && (
            <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
              <Alert
                color="error"
                title={error?.msg || t('errors:submitError')}
                text={error?.details || t('errors:resubmit')}
                onClose={() => {
                  setError(undefined);
                }}
              />
            </Box>
          )}
          <Summary {...props} />
        </>
      ),
    },
  ];

  return {
    steps,
    handleClose,
    submit,
    openOrClose,
    setError,
  };
};
