import { Suspense } from 'react';
import { withAppInsights } from 'app/app-insights';
import { Divider } from '@mui/material';
import Sidebar from 'components/sidebar';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { PageLayoutProvider } from 'context/page-layout-context';
import {
  DataTemplatesProvider,
  DataSetsProvider,
  AssetProvider,
  DataSetsWizardProvider,
  ParametersProvider,
} from 'context';
import PageLayout from 'components/layout';
import DataTemplates from './data-templates';
import SetInstances from './set-instances';
import TemplateDetails from './template-details';
import DataSetDetails from './data-set-details';

function TemplatesPage() {
  return (
    <PageLayoutProvider>
      <PageLayout ariaLabel="templates-page-layout">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Suspense fallback={null}>
                  <Sidebar id="templates" />
                </Suspense>
                <Suspense fallback={null}>
                  <DataTemplatesProvider>
                    <DataTemplates />
                    <Outlet />
                  </DataTemplatesProvider>
                </Suspense>
              </>
            }
          >
            <Route
              path=""
              element={
                <Suspense fallback={null}>
                  <Divider orientation="vertical" flexItem />
                  <TemplateDetails />
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                path=""
                element={
                  <Suspense fallback={null}>
                    <AssetProvider>
                      <ParametersProvider>
                        <DataSetsProvider>
                          <DataSetsWizardProvider>
                            <Divider orientation="vertical" flexItem />
                            <SetInstances />
                            <Outlet />
                          </DataSetsWizardProvider>
                        </DataSetsProvider>
                      </ParametersProvider>
                    </AssetProvider>
                  </Suspense>
                }
              >
                <Route
                  path="set/:dataSetId"
                  element={
                    <Suspense fallback={null}>
                      <ParametersProvider>
                        <Divider orientation="vertical" flexItem />
                        <DataSetDetails />
                      </ParametersProvider>
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route
              path="template/:dataTemplateId"
              element={
                <Suspense fallback={null}>
                  <Divider orientation="vertical" flexItem />
                  <TemplateDetails />
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                path=""
                element={
                  <Suspense fallback={null}>
                    <AssetProvider>
                      <DataSetsProvider>
                        <DataSetsWizardProvider>
                          <Divider orientation="vertical" flexItem />
                          <SetInstances />
                          <Outlet />
                        </DataSetsWizardProvider>
                      </DataSetsProvider>
                    </AssetProvider>
                  </Suspense>
                }
              >
                <Route
                  path="set/:dataSetId"
                  element={
                    <Suspense fallback={null}>
                      <ParametersProvider>
                        <Divider orientation="vertical" flexItem />
                        <DataSetDetails />
                      </ParametersProvider>
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
      </PageLayout>
    </PageLayoutProvider>
  );
}

export default withAppInsights(TemplatesPage, 'TemplatesPage');
