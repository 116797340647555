import { KeyboardArrowUp } from '@mui/icons-material';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';

export default function ScrollTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Fab
        size="small"
        aria-label="scroll back to top"
        onClick={handleClick}
        role="button"
        color="primary"
        data-cy="scroll-to-top"
        data-testid="scroll-to-top"
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
        }}
      >
        <KeyboardArrowUp />
      </Fab>
    </Zoom>
  );
}
