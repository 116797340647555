import PT from 'prop-types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SearchedResultMessage = ({ searchValue }) => {
  const { t } = useTranslation(['common']);

  return (
    <Typography
      variant="h4"
      color="primary"
      sx={{
        paddingTop: theme => theme.spacing(10),
        paddingRight: theme => theme.spacing(10),
      }}
    >
      {`${t('showingResultsFor')} "${searchValue}"`}
    </Typography>
  );
};

SearchedResultMessage.propTypes = {
  searchValue: PT.string.isRequired,
};

export default SearchedResultMessage;
