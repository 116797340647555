import React, { useState, useEffect, useContext } from 'react';
import PT from 'prop-types';
import { isEmpty } from 'lodash';
import {
  formatAssetNavigationDataSetWiz,
  processAssetPlaceholders,
  addPhInfoToAssetInstances,
} from 'utils';
import { useParams } from 'react-router-dom';
import { AssetContext, DataTemplatesContext } from '.';

export const DataSetsWizardContext = React.createContext();

export const DataSetsWizardProvider = ({ children }) => {
  const { allAssets, getAssets, setAllAssets } = useContext(AssetContext);
  const { templateAssets, assetTypesWithParentAndSubTypes } =
    useContext(DataTemplatesContext);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetNodes, setAssetNodes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [openDataSetWizard, setOpenDataSetWizard] = useState(false);
  const { dataTemplateId } = useParams();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [topLevelAssetType, setTopLevelAssetType] = useState({
    type: '',
    available: false,
  });
  const handleOpenCloseWizard = () => {
    setOpenDataSetWizard(!openDataSetWizard);
  };
  const getAssetsForTemplate = async () => {
    const templates = [];
    const assetTypesForTemplate = templateAssets.reduce((acc, curr) => {
      if (curr.assetType) {
        templates.push(curr);
        acc.push(curr.assetType.id);
      }
      return acc;
    }, []);
    setAssetTypes([...templates]);
    await getAssets({
      asset_type_id: [...assetTypesForTemplate],
      show_deleted: false,
    });
  };

  useEffect(() => {
    if (templateAssets.length) {
      getAssetsForTemplate();
      setTopLevelAssetType('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId, templateAssets]);

  useEffect(() => {
    if (templateAssets.length) {
      const topAssetType = templateAssets.find(
        placeholder => !placeholder.parentAssetPlaceholderId
      );
      if (topAssetType.assetType) {
        const availableAssets = allAssets.filter(
          asset => asset.assetType?.id === topAssetType.assetType.id
        );
        if (!availableAssets.length) {
          setTopLevelAssetType({
            type: topAssetType.assetType.name,
            available: false,
          });
        } else {
          setTopLevelAssetType({
            type: topAssetType.assetType.name,
            available: true,
          });
        }
      } else {
        setTopLevelAssetType({ type: '', available: true });
      }
    }
  }, [allAssets, templateAssets]);

  useEffect(() => {
    if (openDataSetWizard) {
      setAllAssets([]);
      getAssetsForTemplate();
      setSelectedAssets([]);
      setExpanded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDataSetWizard, dataTemplateId]);

  useEffect(() => {
    if (openDataSetWizard && assetTypes.length) {
      let projectAssetsMatchingTemplateAssetTypeIds = [];

      const topAsset = templateAssets.find(
        asset => !asset.parentAssetPlaceholderId
      );

      if (!isEmpty(allAssets)) {
        const phDict = Object.fromEntries(
          templateAssets.map(a => [a.id, { possibleAssets: [] }])
        );
        const root = templateAssets.find(a => !a.parentAssetPlaceholderId);

        processAssetPlaceholders(phDict, allAssets, templateAssets, root);

        const possibleAssetInstances = templateAssets.flatMap(
          a => phDict[a.id].possibleAssets
        );

        // check if all asset children are in the list of possible assets
        // if not set the children on the asset to an empty array
        const possibleAssetIds = possibleAssetInstances.map(a => a.id);
        const allAssetInstances = possibleAssetInstances.map(
          possibleInstance => {
            const hasAllChildren = possibleInstance.children.every(child =>
              possibleAssetIds.includes(child)
            );

            if (hasAllChildren) {
              return possibleInstance;
            }
            return {
              ...possibleInstance,
              children: [],
            };
          }
        );

        const parDict = Object.fromEntries(
          allAssetInstances.map(a => [a.id, { ...a, typeIndex: {} }])
        );

        addPhInfoToAssetInstances(
          allAssetInstances,
          templateAssets,
          topAsset,
          parDict
        );

        projectAssetsMatchingTemplateAssetTypeIds = Object.values(parDict);
      }

      const assetTypesWithInfo = assetTypes.reduce((acc, curr) => {
        const parent = assetTypes.find(
          asset => asset.id === curr.parentAssetPlaceholderId
        );
        acc.push({
          ...curr,
          assetType: curr.assetType,
          assetSubType: curr.assetSubType,
          parent: parent?.id || null,
          isTypeOnly: true,
        });
        return acc;
      }, []);

      const nodes = formatAssetNavigationDataSetWiz(
        [...projectAssetsMatchingTemplateAssetTypeIds, ...assetTypesWithInfo],
        null,
        topAsset?.assetType?.id ? topAsset.assetType.id : null,
        !!topAsset?.assetType?.id,
        null,
        assetTypesWithParentAndSubTypes
      );
      setAssetNodes(nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssets]);

  useEffect(() => {
    // unused code commented out for now to test with always expanding only 1 level of depth

    // const templateLevels = templateAssets.filter(
    //   templateAsset => !isEmpty(templateAsset.assetType)
    // ).length;

    const depthCounter = 1;

    // if (templateLevels >= 5) depthCounter = 3;
    // else depthCounter = 2;

    const createInitialExpandedNodes = (nodes, counter = depthCounter) => {
      if (counter < 1) return [];
      const expandedNodes = [];
      expandedNodes.push(nodes?.id);
      if (!isEmpty(nodes?.children)) {
        nodes.children.forEach(child => {
          expandedNodes.push(...createInitialExpandedNodes(child, counter - 1));
        });
      }
      return expandedNodes;
    };
    const initialExpandedNodes = createInitialExpandedNodes(assetNodes[0]);
    setExpanded(curr => [...curr, ...initialExpandedNodes]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetNodes]);

  return (
    <DataSetsWizardContext.Provider
      value={{
        assetNodes,
        selectedTemplate,
        setSelectedTemplate,
        openDataSetWizard,
        setOpenDataSetWizard,
        handleOpenCloseWizard,
        templateAssets,
        selectedAssets,
        setSelectedAssets,
        expanded,
        setExpanded,
        allAssets,
        setAllAssets,
        topLevelAssetType,
      }}
    >
      {children}
    </DataSetsWizardContext.Provider>
  );
};

DataSetsWizardProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
