import { useContext } from 'react';
import { Work } from '@mui/icons-material';
import { CardHeader, Typography, useMediaQuery } from '@mui/material';
import { ProjectContext } from 'context';

const Header = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { project } = useContext(ProjectContext);

  return (
    <CardHeader
      aria-label="project-details-header"
      title={
        <Typography variant="h4" component="h2" aria-label="job-name">
          {project.shortTitle}
        </Typography>
      }
      titleTypographyProps={{ color: theme => theme.palette.secondary }}
      avatar={<Work sx={{ fontSize: screenSm ? 30 : 50 }} status="project" />}
    />
  );
};

export default Header;
