import { PermIdentity, VpnKey, Palette, Cookie } from '@mui/icons-material/';
import config from 'config';

const tabs = [
  {
    path: '/user-settings/details',
    text: 'user:details',
    icon: <PermIdentity />,
    userAccess: true,
    'data-cy': 'user-details',
  },
  {
    path: '/user-settings/permissions',
    text: 'user:permissions',
    icon: <VpnKey />,
    userAccess: true,
    'data-cy': 'user-permissions',
  },
  {
    path: '/user-settings/theme',
    text: 'common:theme',
    icon: <Palette />,
    userAccess: true,
    'data-cy': 'user-theme',
  },
];

if (config.featureFlags.arePoliciesEnabled) {
  tabs.push({
    path: '/user-settings/cookies',
    text: 'common:cookies',
    icon: <Cookie />,
    userAccess: true,
    'data-cy': 'user-cookies',
  });
}

export default tabs;
