import PT from 'prop-types';
import { useState } from 'react';

import { TextField, Autocomplete } from '@mui/material';

function BasicDropdown({
  getOptionLabel,
  options,
  label,
  disableCloseOnSelect,
  variant,
  preselectedOptionLabel,
  ...rest
}) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      {...rest}
      disableCloseOnSelect={disableCloseOnSelect}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          inputProps={{
            ...params.inputProps,
            'aria-label': label,
          }}
        />
      )}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') setInputValue(newInputValue);
        if (event?.type === 'blur') setInputValue(newInputValue);
      }}
      inputValue={inputValue || preselectedOptionLabel}
    />
  );
}
BasicDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PT.array.isRequired,
  label: PT.string,
  variant: PT.string,
  getOptionLabel: PT.func,
  disableCloseOnSelect: PT.bool,
  preselectedOptionLabel: PT.string,
};

BasicDropdown.defaultProps = {
  label: null,
  variant: 'standard',
  getOptionLabel: option => option.title,
  disableCloseOnSelect: false,
  preselectedOptionLabel: undefined,
};

export default BasicDropdown;
