import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const LanguageLabel = ({ languages }) => {
  const { i18n } = useTranslation();

  const [lang] = languages.filter(language => language.code === i18n.language);

  return (
    <Typography
      variant="button"
      sx={{ margin: theme => theme.spacing(0, 0.5, 0, 1) }}
      data-cy="language-label"
    >
      {lang?.text || ''}
    </Typography>
  );
};

LanguageLabel.propTypes = {
  languages: PT.arrayOf(
    PT.shape({ code: PT.string.isRequired, text: PT.string.isRequired })
  ).isRequired,
};

export default LanguageLabel;
