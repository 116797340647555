import { Suspense } from 'react';
import PT from 'prop-types';
import { darken } from '@mui/material/styles';
import { Collapse, Skeleton } from '@mui/material';
import { ActionCard } from 'components/cards';
import ProjectCardActions from './actions';
import { MainContent, ExpandPanel } from './content';

function ProjectCard({
  project,
  loading,
  searchValue,
  media,
  expanded,
  setExpanded,
}) {
  const { shortTitle, projectId } = { ...project };

  const cardProps = {
    loading,
    actions: (
      <ProjectCardActions
        loading={loading}
        project={project}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    ),
    actionArea: {
      link: `/projects/${projectId}`,
    },
    media,
    collapseContent: (
      <Collapse
        aria-label="expandPanel"
        in={expanded.includes(projectId) && !loading}
        timeout="auto"
        sx={{
          backgroundColor: theme =>
            darken(theme.palette.background.default, 0.1),
        }}
      >
        <ExpandPanel project={project} searchValue={searchValue} />
      </Collapse>
    ),
  };
  if (!loading) cardProps.actionArea['data-cy'] = 'card-action-area';

  return (
    <ActionCard
      aria-label={`project card ${shortTitle}`}
      data-testid="projectCard"
      {...cardProps}
    >
      <Suspense
        fallback={
          <>
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
          </>
        }
      >
        <MainContent
          loading={loading}
          project={project}
          searchValue={searchValue}
        />
      </Suspense>
    </ActionCard>
  );
}

ProjectCard.propTypes = {
  project: PT.shape({
    shortTitle: PT.string,
    projectUrl: PT.string,
    number: PT.string,
    projectId: PT.string,
    projectDirectorName: PT.string,
    centreCode: PT.string,
    projectManagerName: PT.string,
    scopeOfWorks: PT.string,
    confidential: PT.bool,
  }),
  loading: PT.bool,
  screenSize: PT.bool,
  searchValue: PT.string.isRequired,
  media: PT.node.isRequired,
  expanded: PT.arrayOf(PT.string),
  setExpanded: PT.func,
};

ProjectCard.defaultProps = {
  loading: true,
  project: {
    shortTitle: '',
    projectUrl: '',
    number: null,
    projectId: '',
    projectDirectorName: '',
    centreCode: '',
    projectManagerName: '',
    scopeOfWorks: '',
    confidential: null,
  },
  screenSize: false,
  expanded: [],
  setExpanded: () => {},
};

export default ProjectCard;
