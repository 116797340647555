import PT from 'prop-types';
import { Info, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@mui/material';
import { StyledIconButton } from 'components/buttons';

function Title({ handleClose }) {
  const { t } = useTranslation(['common', 'projects']);
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        {t('projects:addProject')}
        <Tooltip
          arrow
          title={t('projects:addProjectTooltip')}
          placement="right"
        >
          <Info color="disabled" data-testid="info" />
        </Tooltip>
      </Grid>
      <Grid item>
        <StyledIconButton
          title={t('common:buttons.close')}
          onClick={handleClose}
          icon={<Close />}
        />
      </Grid>
    </Grid>
  );
}

Title.propTypes = {
  handleClose: PT.func.isRequired,
};

export default Title;
