import PT from 'prop-types';
import { SwipeableDrawer } from '@mui/material';

export default function Drawer({
  children,
  open,
  anchor,
  onClose,
  onOpen,
  ...rest
}) {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}
      swipeAreaWidth={0}
      {...rest}
    >
      {children}
    </SwipeableDrawer>
  );
}

Drawer.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  open: PT.bool.isRequired,
  anchor: PT.string,
  onClose: PT.func.isRequired,
  onOpen: PT.func,
};

Drawer.defaultProps = {
  anchor: 'right',
  onOpen: () => {},
};
