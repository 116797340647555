/* eslint-disable react/no-array-index-key */
import PT from 'prop-types';
import { Grid, useMediaQuery, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WarningDialog from 'components/dialogs/warning';
import { GoBackButton } from 'components/buttons';
import ActionHeader from 'components/cards/action-header';
import StatusCircle from 'components/status-circle';
import useHeaders from './hooks';
import HeaderBreadcrumbs from '../../common/header-breadcrumb-tree/headerBreadcrumbs';
import HeaderButtons from '../../common/header-buttons/headerButtons';

function DataCardHeader({
  loading,
  setError,
  asset,
  setAsset,
  openEditingForm,
  setOpenEditingForm,
}) {
  const { t } = useTranslation(['common', 'parameters']);
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const {
    assetList,
    assetsLoading,
    deleteLoading,
    displayWarning,
    headerActions,
  } = useHeaders(
    setError,
    asset,
    setAsset,
    openEditingForm,
    setOpenEditingForm
  );

  return (
    <div>
      <ActionHeader
        style={{
          name: 'assetCardHeader',
          backgroundColor: theme => theme.palette.action.hover,
        }}
        dataCy="assetHeader"
        renderAvatar={
          <Grid container>
            {screenXs && (
              <Grid item xs={6} sm={6}>
                <GoBackButton
                  sx={{ marginTop: theme => theme.spacing(2.5) }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6} sm={12} md={12}>
              <StatusCircle
                status={asset?.deletedAt ? 'deleted' : !asset?.id || 'answered'}
                size={screenMd ? 40 : 60}
                loading={loading}
              />
            </Grid>
          </Grid>
        }
        renderTitle={
          loading ? (
            <Skeleton
              variant="text"
              width="100%"
              height="40px"
              aria-label="loading"
            />
          ) : (
            asset?.name
          )
        }
        titleTypographyProps={{
          sx: {
            paddingLeft: theme => theme.spacing(1),
            paddingBottom: theme => theme.spacing(1),
            marginRight: theme => theme.spacing(1),
            fontSize: '20px',
            color: theme => asset?.deletedAt && theme.palette.secondary.dark,
          },
        }}
        renderSubheader={
          <Grid container width="100%">
            <Grid item xs={6} sm={12} md={12}>
              <HeaderBreadcrumbs
                assetsLoading={assetsLoading}
                assetList={assetList}
              />
            </Grid>
          </Grid>
        }
        subheaderTypographyProps={{
          sx: {
            paddingLeft: theme => theme.spacing(1),
          },
        }}
        isLoading={loading}
        renderAction={
          <Grid
            container
            sx={{
              marginTop: theme => theme.spacing(1),
            }}
          >
            <Grid container>
              <HeaderButtons
                headerActions={headerActions}
                deleteLoading={deleteLoading}
              />
            </Grid>
            <WarningDialog
              open={displayWarning?.open}
              handleAction={displayWarning.handleAccept}
              handleClose={displayWarning.handleClose}
              title={t(displayWarning?.title)}
              body={t(displayWarning.body)}
              secondaryButtonText={t(displayWarning?.secondaryButtonText)}
              primaryButtonText={t(displayWarning?.primaryButtonText)}
            />
          </Grid>
        }
      />
    </div>
  );
}

DataCardHeader.propTypes = {
  loading: PT.bool.isRequired,
  openEditingForm: PT.bool.isRequired,
  setError: PT.func.isRequired,
  setOpenEditingForm: PT.func.isRequired,
  setAsset: PT.func.isRequired,
  asset: PT.shape({
    id: PT.string,
    name: PT.string,
    new: PT.bool,
    assetTypeId: PT.string,
    children: PT.arrayOf(PT.string),
    deletedAt: PT.string,
    assetType: PT.shape({ assetSubType: PT.bool }),
    parent: PT.string,
    editedName: PT.string,
  }),
};

DataCardHeader.defaultProps = {
  asset: {},
};

export default DataCardHeader;
