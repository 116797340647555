const eventCategories = {
  Parameter: {
    category: 'Parameter',
    color: '#F2E8CD',
    events: {
      'parameter.event.entry.replaced': 'Edited Parameter:',
      'parameter.event.parameter.created': 'Created Parameter:',
      'parameter.event.entry.revision.updated': 'Edited Parameter:',
      'parameter.event.parameter.removed': 'Deleted Parameter:',
    },
  },
  Asset: {
    category: 'Asset',
    color: '#EBFBBB',
    events: {
      'parameter.event.asset.created': 'Created Asset:',
      'parameter.event.asset.updated': 'Edited Asset:',
      'parameter.event.asset.removed': 'Deleted Asset:',
    },
  },
  Source: {
    category: 'Source',
    color: '#CDE5FB',
    events: {
      'reference.event.source.created': 'Created Source:',
      'reference.event.source.updated': 'Edited Source:',
      'reference.event.source.removed': 'Deleted Source:',
    },
  },
};

const getEventDetails = eventType => {
  const categoryKey = Object.keys(eventCategories).find(key =>
    Object.hasOwn(eventCategories[key].events, eventType)
  );

  if (categoryKey) {
    const { category, color, events } = eventCategories[categoryKey];
    return {
      title: events[eventType],
      category,
      color,
    };
  }

  return {};
};

export default getEventDetails;
