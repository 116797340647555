import { Helmet } from 'react-helmet';

export default function MetaHeader() {
  return (
    <Helmet
      defaultTitle="DDB"
      titleTemplate="DDB | %s"
      // TODO: update lang from i18n
      // {locales.map((locale) => {
      //   <link rel="alternate" href="http://example.com/{locale}" hrefLang={locale} key={locale}/>
      // })}
    >
      <link rel="canonical" href={process.env.REACT_APP_URL} />
    </Helmet>
  );
}
