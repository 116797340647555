import { Suspense } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BeachAccess } from '@mui/icons-material';
import { Skeleton, Link, Grid, Container, Divider } from '@mui/material';
import config from 'config';
import Resources from './resources';
import Community from './community';
import Release from './release';
import Legal from './legal';

function AppFooter() {
  return (
    <>
      <Divider />
      <Container maxWidth="md" sx={{ padding: '5rem' }}>
        <footer>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme => theme.spacing(2),
                '& img': {
                  marginRight: theme => theme.spacing(2),
                },
              }}
            >
              {config.env === 'ut' ? (
                <BeachAccess fontSize="large" color="primary" />
              ) : (
                <img src="/logos/logo32.png" alt="DDB logo" />
              )}
              <Link
                underline="none"
                component={RouterLink}
                variant="body1"
                color="textSecondary"
                to="/"
              >
                Digital Design Brief
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{
                marginBottom: theme => theme.spacing(6),
                '& ul': {
                  margin: 0,
                  padding: 0,
                  listStyle: 'none',
                },
                '& li': {
                  padding: theme => theme.spacing(0.75),
                  paddingLeft: 0,
                },
              }}
            >
              <Suspense fallback={<Skeleton width={60} height={150} />}>
                <Community />
              </Suspense>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{
                marginBottom: theme => theme.spacing(6),
                '& ul': {
                  margin: 0,
                  padding: 0,
                  listStyle: 'none',
                },
                '& li': {
                  padding: theme => theme.spacing(0.75),
                  paddingLeft: 0,
                },
              }}
            >
              <Suspense fallback={<Skeleton width={60} height={150} />}>
                <Resources />
              </Suspense>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{
                marginBottom: theme => theme.spacing(6),
                '& ul': {
                  margin: 0,
                  padding: 0,
                  listStyle: 'none',
                },
                '& li': {
                  padding: theme => theme.spacing(0.75),
                  paddingLeft: 0,
                },
              }}
            >
              <Suspense fallback={<Skeleton width={60} height={150} />}>
                <Legal />
              </Suspense>
            </Grid>
          </Grid>
          <Suspense fallback={<Skeleton />}>
            <Release />
          </Suspense>
        </footer>
      </Container>
    </>
  );
}

export default AppFooter;
