import { Box, Grid, useMediaQuery, Button, Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import CookiesHeader from 'routes/home/cookies/cookies-header';
import CookiesItems from 'routes/home/cookies/cookies-items';
import useGetCookies from './hooks/use-get-cookies';

function Cookies() {
  const { t } = useTranslation(['cookies', 'footer']);
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { cookieTypes, onAcceptSelectedCookiesClick, cookiesClean } =
    useGetCookies();

  return (
    <Box width={screenMd ? '90%' : '60%'} margin={theme => theme.spacing(5)}>
      <Grid container>
        <CookiesHeader />
      </Grid>
      <Grid container marginTop={theme => theme.spacing(3)}>
        {isEmpty(cookieTypes) ? (
          <Skeleton />
        ) : (
          cookieTypes.map(type => {
            return <CookiesItems key={type.type} cookieList={type} />;
          })
        )}
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          sx={{ width: '40%', margin: theme => theme.spacing(1) }}
          aria-label="accept-selected-cookie-button"
          variant="contained"
          onClick={onAcceptSelectedCookiesClick}
          disabled={cookiesClean}
          data-cy="cookies-accept-button"
        >
          {t('cookies:updateSelection')}
        </Button>
      </Grid>
    </Box>
  );
}
export default Cookies;
