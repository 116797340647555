import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Button, Container } from '@mui/material';
import config from '../../config';
import { SwipeableDrawer } from '../drawer';
import { StyledIconButton } from '../buttons';

export default function Yammer() {
  const { t } = useTranslation(['header', 'common']);

  const [yammerOpen, setYammerOpen] = useState(false);

  const toggleYammer = () => setYammerOpen(!yammerOpen);

  return (
    <>
      <StyledIconButton
        title={t('header:yammer')}
        color="inherit"
        onClick={toggleYammer}
        aria-label={t('header:yammer')}
        data-cy="yammer-button"
        icon={
          <Icon>
            <img src="/logos/yammer.svg" alt="y" />
          </Icon>
        }
      />
      <SwipeableDrawer
        open={yammerOpen}
        onClose={toggleYammer}
        data-testid="yammerPanel"
        ModalProps={{ keepMounted: yammerOpen }}
      >
        <Container
          component="main"
          id="main-content"
          maxWidth="sm"
          tabIndex={-1}
          sx={{
            borderWidth: 0,
            padding: theme => theme.spacing(1),
            height: '95%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={toggleYammer}
            data-cy="yammer-close"
          >
            {t('common:buttons.close')}
          </Button>
          <iframe
            style={{
              padding: theme => theme.spacing(1),
              width: '100%',
              height: '100%',
              borderWidth: 0,
              minWidth: 480,
            }}
            name="embed-feed"
            title="Yammer"
            src={config.url.yammerGroupIFrame}
          />
        </Container>
      </SwipeableDrawer>
    </>
  );
}
