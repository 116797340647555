/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { styled, lighten, darken } from '@mui/material/styles';
import { Box, Collapse } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { TreeItem } from '@mui/lab';
import { treeItemClasses } from '@mui/lab/TreeItem';

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 5}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export const StyledTreeItem = styled(props => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme, ...props }) => ({
  height: 'auto',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.background.paper,
  [`& .${treeItemClasses.content}`]: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    '&:hover, &:not(:hover)': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
  },
  [`& .Mui-selected .${treeItemClasses.label}`]: {
    backgroundColor:
      props.mode === 'dark'
        ? darken(theme.palette.primary.main, 0.7)
        : lighten(theme.palette.primary.main, 0.8),
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  [`& .${treeItemClasses.label}`]: {
    backgroundColor: theme.palette.background.paper,
    '&.instanceLabel': {
      '&:hover, &:focus, &:focus-within': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    '&.typeLabel': {
      '&:hover': {
        cursor: 'initial',
      },
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    backgroundColor: theme.palette.background.paper,
    '&.dataSetInstanceLabel': {
      width: `${theme.spacing(7.5)} !important`,
    },
    '&.dataSetTypeLabel': {
      width: `${theme.spacing(3.5)} !important`,
    },
    '&.templateDetailsTree': {
      width: `${theme.spacing(4.25)} !important`,
    },
  },
  [`& .MuiTreeItem-iconContainer .MuiGrid-root.MuiGrid-container`]: {
    alignItems: 'center',
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 5,
    paddingLeft: 4,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  minWidth: '100%',
  border: 'none',
  textAlign: 'start',
  p: 0,
  minHeight: '32px',
  backgroundColor: 'transparent',
  height: 'auto',
  outline: 'none',
}));
