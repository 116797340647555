import isString from 'lodash/isString';

export default isoCode => {
  return typeof String.fromCodePoint !== 'undefined' &&
    isString(isoCode) &&
    isoCode.length === 2
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : '🏳';
};
