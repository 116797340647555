import PT from 'prop-types';

const parameterEventsPropTypes = PT.shape({
  triggeredBy: PT.shape({
    email: PT.string.isRequired,
  }).isRequired,
  eventType: PT.string.isRequired,
  updatedAt: PT.string.isRequired,
  projectId: PT.string.isRequired,
  parameterId: PT.string.isRequired,
  parameterName: PT.string,
  parents: PT.oneOfType([
    PT.arrayOf(
      PT.shape({
        id: PT.string.isRequired,
        name: PT.string.isRequired,
      })
    ),
    PT.oneOf([null]),
  ]),
  value: PT.oneOfType([PT.string, PT.number, PT.bool]),
  unit: PT.shape({
    symbol: PT.string,
  }),
  source: PT.shape({
    id: PT.string,
    title: PT.string,
  }),
  comment: PT.shape({
    id: PT.string,
    content: PT.string,
  }),
  parentName: PT.string,
  parentId: PT.string,
  status: PT.string,
});

const assetEventsPropTypes = PT.shape({
  triggeredBy: PT.shape({
    email: PT.string.isRequired,
  }).isRequired,
  eventType: PT.string.isRequired,
  updatedAt: PT.string.isRequired,
  projectId: PT.string.isRequired,
  assetId: PT.string.isRequired,
  name: PT.string.isRequired,
  parents: PT.arrayOf(
    PT.shape({
      id: PT.string,
    })
  ).isRequired,
});

const sourcesEventsPropTypes = PT.shape({
  eventType: PT.string.isRequired,
  triggeredBy: PT.shape({
    email: PT.string.isRequired,
  }).isRequired,
  updatedAt: PT.string.isRequired,
  projectId: PT.string.isRequired,
  sourceId: PT.string.isRequired,
  title: PT.string.isRequired,
  reference: PT.string,
  sourceType: PT.string,
  fileDirectoryUrl: PT.oneOfType([PT.string, PT.oneOf([null])]),
  publicationRevisionDate: PT.shape({
    dateYear: PT.string,
    dateMonth: PT.string,
    dateDay: PT.string,
  }),
});

const historyPropTypes = PT.oneOfType([
  parameterEventsPropTypes,
  assetEventsPropTypes,
  sourcesEventsPropTypes,
]);

export {
  historyPropTypes,
  parameterEventsPropTypes,
  assetEventsPropTypes,
  sourcesEventsPropTypes,
};
