import { Suspense } from 'react';
import {
  GlobalTypesProvider,
  ProjectProvider,
  ProjectPermissionProvider,
  ReportingProvider,
} from 'context';

import ProjectMetadataTitle from 'routes/parameters-page/metadata-title';

function withProject(Component) {
  function WithProject(props) {
    return (
      <GlobalTypesProvider>
        <ProjectProvider>
          <ProjectPermissionProvider>
            <Suspense fallback={null}>
              <ReportingProvider>
                <ProjectMetadataTitle />
                <Component {...[props]} />
              </ReportingProvider>
            </Suspense>
          </ProjectPermissionProvider>
        </ProjectProvider>
      </GlobalTypesProvider>
    );
  }
  return WithProject;
}

export default withProject;
