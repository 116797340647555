import { useTranslation } from 'react-i18next';
import config from 'config';
import WarningDialog from 'components/dialogs/warning';
import { Grid, Typography, Link } from '@mui/material';
import { Cookie } from '@mui/icons-material';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';

function CookieWarning() {
  const { openCookiesWarning, onAcceptAnalyticCookies } = useGetCookies();
  const { t } = useTranslation(['cookies', 'footer']);

  return (
    <WarningDialog
      open={openCookiesWarning === true}
      disableClose
      handleAction={onAcceptAnalyticCookies}
      title={
        <Grid container aria-label="cookies-header">
          <Grid item xs={1}>
            <Cookie fontSize="large" aria-label="cookie-icon" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" component="h2">
              {t('cookies:ddbCookiesTitle')}
            </Typography>
          </Grid>
        </Grid>
      }
      body={
        <Grid container aria-label="cookies-body">
          <Grid>{t('cookies:ddbCookiesInternalWarning')}</Grid>
          <Grid sx={{ paddingTop: theme => theme.spacing(1) }}>
            {t('cookies:analyticCookiesDescription')}
          </Grid>
          <Typography sx={{ paddingTop: theme => theme.spacing(1) }}>
            {t('cookies:ddbCookiesLink')}{' '}
            <Link
              aria-label="link-to-cookies"
              underline="always"
              color="primary"
              target="_blank"
              variant="body2"
              href={config.url.policiesCookies}
            >
              {t('footer:cookiesPolicy')}
            </Link>
          </Typography>
        </Grid>
      }
      primaryButtonText={t('common:accept')}
    />
  );
}
export default CookieWarning;
