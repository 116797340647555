import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';

export default styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  '& ': {
    ...theme.typography.body2,
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  '& blockquote': {
    borderLeft:
      theme.palette.mode === 'light'
        ? '5px solid #ffe564'
        : `5px solid ${theme.palette.primary.main}`,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(255,229,100,0.2)'
        : theme.palette.background.default,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    '& p': {
      marginTop: theme.spacing(2),
      ...theme.typography.body2,
    },
  },
  '& h1': {
    ...theme.typography.h4,
    fontSize: 40,
    margin: theme.spacing(2),
  },
  '& h2': {
    ...theme.typography.h6,
    fontSize: 40,
    margin: theme.spacing(2),
  },
  '& ol': {
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
  },
  '& ol[type="a"]': {
    ...theme.typography.body2,
    paddingLeft: theme.spacing(4),
  },
}));
