import PropTypes from 'prop-types';
import { Suspense } from 'react';
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from 'auth/auth-provider';
import Authorizing from './unauthenticated';
import ErrorComponent from './error';

export default function PrivateRoute({ children }) {
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Popup}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
      >
        {children}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Suspense fallback={null}>
          <Authorizing />
        </Suspense>
      </UnauthenticatedTemplate>
    </>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
