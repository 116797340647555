import PT from 'prop-types';
import { StyledChip, tagTypeLookup } from './tag-chip-styles';

function TagChip({ label, icon: Icon, tagType, holder, palette, ...rest }) {
  return (
    <StyledChip
      palette={palette}
      data-cy={tagType}
      tagtype={tagType}
      label={label}
      holder={holder.toString()}
      clickable={!!rest?.onClick}
      aria-label={rest?.onClick ? `add ${tagType}` : label}
      icon={Icon || tagTypeLookup[tagType]?.icon || null}
      size="small"
      variant="filled"
      {...rest}
    />
  );
}

TagChip.propTypes = {
  label: PT.string,
  icon: PT.node,
  tagType: PT.string,
  holder: PT.bool,
  palette: PT.string,
};

TagChip.defaultProps = {
  tagType: '',
  label: null,
  icon: null,
  holder: false,
  palette: 'light',
};

export default TagChip;
