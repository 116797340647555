/* eslint-disable max-depth */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { uniqBy, isEmpty } from 'lodash';
import { handleApiError, parametersApi } from 'api';
import { DataTemplatesContext } from 'context';
import useSummary from 'routes/templates/template-details/asset-types/hooks/use-summary';
import { createTemplateParametersLookup } from 'utils';

export default () => {
  const { dataSetId } = useParams();
  const [dataSetAssets, setDataSetAssets] = useState([]);
  const [outputsLoading, setOutputsLoading] = useState(true);
  const [parametersLoading, setParametersLoading] = useState({});
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [openAddSource, setOpenAddSource] = useState(false);
  const [selectedSource, setSelectedSource] = useState({});
  const [formValues, setFormValues] = useState({});
  const [heldFormValues, setHeldFormValues] = useState({});
  const [updatedParameter, setUpdatedParameter] = useState({});
  const [dataSetError, setDataSetError] = useState(undefined);
  const [paramUpdated, setParamUpdated] = useState(false);
  const { templateAssets } = useContext(DataTemplatesContext);
  const { formattedAssets } = useSummary();

  const getDataSetAssetParameters = async (assetId, after) => {
    setParametersLoading({ [assetId]: true });
    const didCancel = false;
    const source = axios.CancelToken.source();
    const query = {
      data_set_id: dataSetId,
      asset_id: assetId,
      project_parameter: !!isEmpty(assetId),
    };
    if (after) query.after = after;
    try {
      const response = await parametersApi(
        'getAllParameters',
        query,
        source.token
      );
      if (!didCancel && response) {
        const { parameters, paging } = response;
        setDataSetAssets(curr => {
          const assetToUpdate = dataSetAssets.find(
            asset => asset.id === assetId
          );
          if (assetToUpdate) {
            assetToUpdate.parameters = uniqBy(
              [...assetToUpdate.parameters, ...parameters],
              'id'
            );
          }
          return curr;
        });
        if (paging?.cursors?.after) {
          await getDataSetAssetParameters(assetId, paging?.cursors?.after);
        }
        setParametersLoading({ [assetId]: false });
      }
    } catch (err) {
      handleApiError(err, []);
      setParametersLoading({ [assetId]: false });
    }
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getDataSetAssets = async (after, assets = []) => {
      const query = {
        data_set_id: dataSetId,
      };
      if (after) query.after = after;
      try {
        const response = await parametersApi(
          'getDataSetAssets',
          query,
          source.token
        );
        if (!didCancel && response) {
          const { dataSetAssets: dataSetAssetResponse } = response;
          const newAssets = uniqBy([...assets, ...dataSetAssetResponse], 'id');
          if (response.paging?.cursors?.after) {
            return getDataSetAssets(response.paging.cursors.after, newAssets);
          }
          return newAssets;
        }
      } catch (err) {
        handleApiError(err, []);
      }
      return [];
    };

    const processDataSetAssets = async () => {
      setOutputsLoading(true);
      try {
        const allDataSetAssets = await getDataSetAssets();
        if (
          !didCancel &&
          !isEmpty(allDataSetAssets) &&
          !isEmpty(templateAssets) &&
          !isEmpty(formattedAssets)
        ) {
          const projectLevelAsset = templateAssets.filter(
            templateAsset => templateAsset.assetType === null
          );

          const templateParametersLookup =
            createTemplateParametersLookup(formattedAssets);

          const assetsAndParams = allDataSetAssets.map(asset => {
            const templateAssetPlaceholder = templateAssets.find(
              templateAsset => templateAsset.id === asset.assetPlaceholderId
            );
            return {
              ...asset,
              assetTypeName: templateAssetPlaceholder?.name,
              hasParameters:
                !!templateParametersLookup[asset.assetPlaceholderId],
              parameters: [],
            };
          });

          if (!isEmpty(projectLevelAsset)) {
            const projectLevelSet = {
              id: '',
              name: 'Project level',
            };
            assetsAndParams.unshift({
              ...projectLevelSet,
              hasParameters:
                !!templateParametersLookup[projectLevelAsset[0].id],
              parameters: [],
            });
          }

          const findAssetIndex = (array, id) =>
            array.findIndex(asset => asset.id === id);

          const findParentAsset = (asset, index, array) => {
            const parentIndex = findAssetIndex(array, asset.parent);
            if (parentIndex === -1) {
              const parent = assetsAndParams.find(
                assetAndParams => assetAndParams.id === asset.parent
              );
              if (parent) {
                array.splice(index, 0, parent);
                findParentAsset(parent, index, array);
              }
            }
          };

          const orderedAssetsAndParams = [];

          assetsAndParams.forEach(assetAndParams => {
            const orderedIndex = findAssetIndex(
              orderedAssetsAndParams,
              assetAndParams.id
            );
            const parentIndex = findAssetIndex(
              orderedAssetsAndParams,
              assetAndParams.parent
            );

            if (orderedIndex === -1) {
              if (isEmpty(orderedAssetsAndParams) || parentIndex === -1) {
                orderedAssetsAndParams.push(assetAndParams);
                findParentAsset(
                  assetAndParams,
                  orderedAssetsAndParams.length - 1,
                  orderedAssetsAndParams
                );
              } else {
                orderedAssetsAndParams.splice(
                  parentIndex + 1,
                  0,
                  assetAndParams
                );
              }
            }
          });
          setDataSetAssets(orderedAssetsAndParams);
          setOutputsLoading(false);
        } else if (
          !didCancel &&
          isEmpty(allDataSetAssets) &&
          !isEmpty(formattedAssets)
        ) {
          setOutputsLoading(false);
        }
      } catch (err) {
        handleApiError(err, []);
      }
    };

    if (dataSetId) {
      processDataSetAssets();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [dataSetId, formattedAssets]);

  useEffect(() => {
    if (!isEmpty(updatedParameter)) {
      const updatedSet = dataSetAssets.map(asset => {
        const newParameters = asset?.parameters?.map(param => {
          if (param.id === updatedParameter.id) {
            return updatedParameter;
          }
          return param;
        });
        return { ...asset, parameters: newParameters };
      });
      setDataSetAssets(updatedSet);
      setParamUpdated(true);
    }
  }, [updatedParameter]);

  return {
    dataSetAssets,
    outputsLoading,
    setOpenEditingForm,
    setOpenAddSource,
    selectedSource,
    setSelectedSource,
    formValues,
    setFormValues,
    heldFormValues,
    setHeldFormValues,
    setDataSetError,
    setUpdatedParameter,
    openEditingForm,
    openAddSource,
    dataSetError,
    paramUpdated,
    setParamUpdated,
    parametersLoading,
    getDataSetAssetParameters,
  };
};
