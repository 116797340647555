/* eslint-disable react/no-array-index-key */
import { Form, FormSpy } from 'react-final-form';
import { useState } from 'react';
import PT from 'prop-types';
import arrayMutators from 'final-form-arrays';
import {
  Paper,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { indexOf, isEmpty } from 'lodash';
import { StyledIconButton } from '../buttons';

function FinalForm({
  title,
  initialValues,
  formFields,
  validate,
  onClose,
  onSubmit,
  isLoading,
  conditions,
  submitButtonLabel,
  onChange,
  isPanel,
  isEditing,
  enableSubmitButton,
  selectedSourceId,
}) {
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submittingButton = () => {
    if (isSubmitting)
      return (
        <CircularProgress
          size={25}
          thickness={6}
          color="secondary"
          aria-label="submitting progress icon"
        />
      );
    return submitButtonLabel || t('buttons.submit');
  };

  if (isLoading)
    return (
      <Paper style={{ padding: 16 }} elevation={2}>
        <Skeleton title="loading" width="100%" height={35} />
      </Paper>
    );
  return (
    <Form
      onSubmit={onSubmit}
      aria-label="form"
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        errors,
      }) => {
        return (
          <form onSubmit={handleSubmit} noValidate aria-label="form">
            <Paper
              data-cy="form-component"
              sx={{
                padding: theme => theme.spacing(2),
                boxShadow: 'none',
                overflowY: isPanel ? 'auto' : 'none',
                maxHeight: isPanel && !isEditing ? '50vh' : 'auto',
              }}
              elevation={3}
            >
              <Grid container>
                <Grid item xs={11} style={{ marginTop: 16 }}>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
                {!isPanel && (
                  <Grid item xs={1}>
                    <StyledIconButton
                      data-cy="close"
                      aria-label="close"
                      title="Close"
                      icon={<CloseIcon />}
                      onClick={onClose}
                      disabled={submitting}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={2}
              >
                {formFields.map((item, idx) => (
                  <Grid item md={item?.size} xs={12} key={idx}>
                    <div style={{ width: '10px', padding: 0 }}>
                      {item?.icon}
                    </div>
                    <div style={{ padding: 0 }}>{item?.field}</div>
                  </Grid>
                ))}
                {onChange && <FormSpy onChange={onChange} />}
                {conditions &&
                  conditions
                    .filter(item => {
                      const answeredFields = Object.keys(values);
                      const answeredValues = Object.values(values);
                      if (item.type === 'filter') {
                        return (
                          values[item.when] &&
                          !item.is.some(
                            isAnswered =>
                              isAnswered ===
                              answeredValues[indexOf(answeredFields, item.when)]
                          )
                        );
                      }
                      return item.is.some(
                        isAnswered =>
                          isAnswered ===
                          answeredValues[indexOf(answeredFields, item.when)]
                      );
                    })
                    .map((item, idx) => {
                      return (
                        <Grid item md={item?.size} xs={12} key={idx}>
                          {item.field}
                        </Grid>
                      );
                    })}
              </Grid>
              {isEditing && (
                <Grid
                  sx={{ marginTop: theme => theme.spacing(1) }}
                  container
                  alignItems="flex-start"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      aria-label="reset"
                      type="button"
                      variant="contained"
                      onClick={form.reset}
                      disabled={submitting || (pristine && !selectedSourceId)}
                    >
                      {t('buttons.reset')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="edit-source-submit-button"
                      aria-label="submit"
                      variant="contained"
                      disabled={
                        !enableSubmitButton &&
                        ((pristine && !selectedSourceId) || !isEmpty(errors))
                      }
                      color="primary"
                      type="submit"
                      onClick={() => {
                        setIsSubmitting(submitting);
                      }}
                    >
                      {submittingButton()}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </form>
        );
      }}
    />
  );
}

FinalForm.propTypes = {
  title: PT.string || null,
  initialValues: PT.shape({}).isRequired,
  formFields: PT.arrayOf(PT.shape({ size: PT.number, field: PT.node }))
    .isRequired,
  validate: PT.func.isRequired,
  onClose: PT.func,
  onSubmit: PT.func.isRequired,
  isLoading: PT.bool,
  conditions: PT.arrayOf(
    PT.shape({
      size: PT.number,
      field: PT.node,
      when: PT.string.isRequired,
      is: PT.arrayOf(PT.string),
    })
  ),
  submitButtonLabel: PT.string,
  onChange: PT.func,
  isPanel: PT.bool,
  isEditing: PT.bool,
  enableSubmitButton: PT.bool,
  selectedSourceId: PT.string,
};

FinalForm.defaultProps = {
  isLoading: false,
  conditions: [],
  submitButtonLabel: '',
  onChange: () => {},
  onClose: () => {},
  isPanel: false,
  isEditing: true,
  title: null,
  enableSubmitButton: false,
  selectedSourceId: '',
};

export default FinalForm;
