const parseCookie = () => {
  const cookieValue = decodeURIComponent(
    document?.cookie
      ?.split('; ')
      ?.find(row => row.startsWith('ddbCookiesPolicyAccepted='))
      ?.split('=')[1]
      ?.trim()
  );
  let parsedCookie = {};
  if (cookieValue !== 'undefined') {
    parsedCookie = JSON.parse(cookieValue);
  }
  return parsedCookie;
};

export default parseCookie;
