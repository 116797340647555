import { Grid } from '@mui/material';
import { styled, darken } from '@mui/material/styles';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: 250,
    maxWidth: 250,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 270,
    maxWidth: 270,
  },
  borderColor: theme.palette.divider,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? darken(theme.palette.secondary.main, 0.9)
      : theme.palette.background.paper,
}));

export const StyledScrolledGrid = styled(Grid)(() => ({
  overflowY: 'auto',
  height: '50vh',
}));
