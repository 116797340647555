import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppFooter from 'components/footer';
import ContactOnboarding from 'components/contact-onboarding';
import ImageInformationSection from 'components/information-sections/image-information-section';
import { StyledDivider } from '../../home/styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const TrackChanges = () => {
  const { t } = useTranslation(['home']);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: t('home:trackChanges'),
      description: t('home:trackChangesDescription'),
      imageSrc: '/illustrations/landing-page/changes.svg',
      imageAlt: 'changes',
      buttonText: t('home:goToProjects'),
      titleSection: true,
    },
    {
      title: t('home:designChanges'),
      description: t('home:designChangesDescription'),
      imageSrc: '/illustrations/landing-page/designChangesHistory.svg',
      imageAlt: 'design changes history',
      leftSection: true,
    },
    {
      title: t('home:qualityAssurance'),
      description: t('home:qualityAssuranceDescription'),
      imageSrc: '/illustrations/landing-page/qualityAssurance.svg',
      imageAlt: 'quality assurance',
    },
    {
      title: t('home:accessPermissions'),
      description: t('home:accessPermissionsDescription'),
      imageSrc: '/illustrations/landing-page/users.svg',
      imageAlt: 'users',
      leftSection: true,
    },
  ];

  return (
    <>
      <main id="main-content" tabIndex="-1" style={{ minHeight: 650 }}>
        <Container maxWidth="lg">
          {sections.map((section, index) => (
            <div key={section.title}>
              <ImageInformationSection
                title={section.title}
                description={section.description}
                imageSrc={section.imageSrc}
                imageAlt={section.imageAlt}
                buttonText={section.buttonText}
                titleSection={section.titleSection}
                isMdUp={isMdUp}
                leftSection={section.leftSection}
              />
              {index < sections.length - 1 && <StyledDivider />}
            </div>
          ))}
          <ContactOnboarding isMdUp={isMdUp} />
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default TrackChanges;
