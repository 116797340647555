import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledGrid = styled(Grid)(({ props }) => ({
  direction: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  ...props,
}));
