import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { orderBy, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { ParametersContext, AssetContext } from 'context';
import { referenceDataApi, handleApiError, parametersApi } from 'api';
import {
  emojiValidation,
  numberValidation,
  intValidation,
  stringValidation,
} from 'utils';

export default (
  setParameter,
  parameter,
  setOpenEditingForm,
  setActionError
) => {
  const { t } = useTranslation(['common', 'parameters']);
  const { updateParametersList } = useContext(ParametersContext);
  const { allAssets } = useContext(AssetContext);
  const { parameterId, projectId, assetId } = useParams();

  const [unitTypes, setUnitTypes] = useState([]);
  const [allSources, setAllSources] = useImmer([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areSourcesLoading, setAreSourceLoading] = useState(true);
  const [searchSources, setSearchSources] = useState(null);
  const [valueOptions, setValueOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [error, setError] = useState(false);

  const asset = allAssets?.find(existing => existing.id === assetId);
  const assetType = asset?.assetType?.id;

  useEffect(() => {
    setValueOptions([]);
    const getValueOptions = async currentField => {
      const response = await parametersApi('getOptions', {
        item_type_id: currentField.itemTypeId,
      });
      if (response) {
        const { options } = response;
        setValueOptions([...options]);
        setOptionsLoading(false);
      }
    };
    getValueOptions(parameter);
  }, [parameter, assetId, assetType]);

  useEffect(() => {
    let didCancel = false;
    const getUnitTypes = async () => {
      const { unitType } = await parametersApi('getUnitType', {
        unit_type_id: parameter.parameterType.unitTypeId,
      }).catch(err => handleApiError(err, []));
      if (!didCancel) {
        const unitsMenu = unitType?.unitSystems?.map(system => {
          const unitSystem = {
            name: system.shortTitle,
            id: system.id,
            disabled: true,
          };
          const units = system.units.map(unit => {
            return { id: unit.id, name: unit.symbol, disabled: false };
          });
          return [unitSystem, ...units];
        });
        const allUnits = unitsMenu.flat();
        setUnitTypes(allUnits);
        setIsLoading(false);
      }
    };

    if (parameter?.parameterType?.unitTypeId) {
      getUnitTypes();
    } else {
      setIsLoading(false);
    }
    return () => {
      didCancel = true;
    };
  }, [allSources, parameter.parameterType.unitTypeId]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const loadAllSources = async after => {
      const query = {
        reference_id: projectId,
        include_global: 'true',
        search: searchSources,
      };
      if (after) query.after = after;
      const response = await referenceDataApi(
        'getSources',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { sources, paging } = response;
        const sortedByType = orderBy(sources, res => res.sourceType.name, [
          'asc',
        ]);
        const filterAutomatedProcesses = sortedByType.filter(
          res => res.sourceType?.name !== 'Auto'
        );
        setAllSources(curr => [...curr, ...filterAutomatedProcesses]);
        if (paging?.cursors?.after) {
          loadAllSources(paging?.cursors?.after);
        }

        setAreSourceLoading(false);
      }
    };
    if (projectId) {
      setAllSources([]);
      loadAllSources();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [projectId, searchSources]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendUpdatedValues = ({ sourceId, value, unitId, comment }) => {
    setActionError(undefined);
    const paramId = parameterId || parameter.id;
    const updateRevision = async () => {
      try {
        const response = await parametersApi('putEntries', {
          body: {
            entries: [
              {
                parameter_id: paramId,
                entry_id: parameter.selectedEntry?.id || uuidv4(),
                source_id: sourceId,
                values: [
                  {
                    unit_id: unitId || null,
                    value,
                  },
                ],
                ...(comment && { comment }),
              },
            ],
          },
        });
        if (response) {
          const { entries } = response;
          setOpenEditingForm(false);
          setParameter({ ...parameter, selectedEntry: entries[0] });
          updateParametersList(
            { ...parameter, selectedEntry: entries[0] },
            parameterId
          );
        }
      } catch (err) {
        setActionError(err?.response?.data);
      }
    };
    updateRevision();
  };

  const validateEditing = values => {
    let dateYear = '';
    if (values.value instanceof Date) dateYear = values.value.getFullYear();
    const errors = {};
    if (
      (parameter.parameterType.dataType === 'float' ||
        parameter.parameterType.dataType === 'number') &&
      !numberValidation(values.value)
    ) {
      errors.value = t('invalid');
    }

    if (
      parameter.selectedEntry?.values[0]?.value?.toString() ===
        values.value?.toString() &&
      parameter.selectedEntry?.values[0]?.unit?.id?.toString() ===
        values.unitId &&
      parameter.selectedEntry?.source?.id === values.sourceId &&
      (values.comment === undefined || values.comment === '')
    ) {
      errors.valuesNoChange = 'noValuesChanged';
    }

    if (
      parameter.parameterType.dataType === 'integer' &&
      !intValidation(values.value)
    ) {
      errors.value = t('invalid');
    }
    if (
      parameter.parameterType.dataType === 'date' &&
      !(
        values.value instanceof Date &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(values.value)
      )
    ) {
      errors.value = t('common:invalidDate');
    }
    if (
      parameter.parameterType.dataType === 'date' &&
      values.value instanceof Date &&
      (dateYear < 1000 || dateYear > 2500)
    ) {
      errors.value = t('common:invalidYear');
    }
    if (emojiValidation(values.value)) {
      errors.value = t('invalid');
    }
    if (
      parameter.parameterType.dataType === 'string' &&
      !stringValidation(values.value)
    ) {
      errors.value = t('invalid');
    }
    if (values.value !== false && !values.value) {
      errors.value = t('required');
    }
    if (!isEmpty(unitTypes) && !values.unitId) {
      errors.unitId = t('required');
    }
    if (!values.sourceId) {
      errors.sourceId = t('required');
    }
    return errors;
  };

  return {
    optionsLoading,
    unitTypes,
    sendUpdatedValues,
    allSources,
    isLoading,
    areSourcesLoading,
    setSearchSources,
    valueOptions,
    validateEditing,
    error,
    setError,
  };
};
