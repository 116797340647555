import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default (
  { projectId, shortTitle, number, confidential },
  cookies,
  setCookie,
  removeCookie
) => {
  const { t } = useTranslation(['projects']);
  const { enqueueSnackbar } = useSnackbar();
  const favorite = cookies?.ddbFavoriteProjects
    ? cookies?.ddbFavoriteProjects[projectId]
    : null;

  const addFavorite = () => {
    const currentCookies = cookies?.ddbFavoriteProjects || {};
    if (Object.keys(currentCookies).length <= 20) {
      removeCookie('ddbFavoriteProjects');
      setCookie(
        'ddbFavoriteProjects',
        {
          [projectId]: { jobName: shortTitle, number, confidential },
          ...currentCookies,
        },
        {
          path: '/',
          maxAge: 31536000,
        }
      );
    } else {
      enqueueSnackbar(t('projects:maxFavourites'), {
        variant: 'info',
        preventDuplicate: true,
      });
    }
  };

  const removeFavorite = () => {
    const updatedCookies = omit(cookies.ddbFavoriteProjects, [projectId]);
    removeCookie('ddbFavoriteProjects');
    if (!isEmpty(updatedCookies))
      setCookie('ddbFavoriteProjects', updatedCookies, {
        path: '/',
        maxAge: 31536000,
      });
  };

  useEffect(() => {
    if (favorite) {
      const currentCookies = cookies?.ddbFavoriteProjects || {};
      removeCookie('ddbFavoriteProjects');
      setCookie(
        'ddbFavoriteProjects',
        {
          ...currentCookies,
          [projectId]: { jobName: shortTitle, number, confidential },
        },
        {
          path: '/',
          maxAge: 31536000,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confidential]);

  return { addFavorite, removeFavorite, favorite };
};
