import { Suspense } from 'react';
import PT from 'prop-types';
import { Skeleton } from '@mui/material';
import Chatbot from 'components/chatbot';
import LanguageMenu from './language';
import Feedback from './feedback';
import Notifications from './notifications';
import Docs from './docs';
import Bugs from './bugs';

function MainMenu({ notifications }) {
  return (
    <div data-testid="mainMenu">
      <Suspense fallback={<Skeleton width={80} height={40} />}>
        <LanguageMenu />
        <Chatbot />
        <Docs />
        <Feedback />
        <Bugs />
        <Notifications {...notifications} />
      </Suspense>
    </div>
  );
}

export default MainMenu;

MainMenu.propTypes = {
  toggleUserGuide: PT.func.isRequired,
  notifications: PT.shape({
    handleNotificationsToggle: PT.func.isRequired,
    notificationsOpen: PT.bool.isRequired,
    lastSeen: PT.number,
    notificationList: PT.arrayOf(PT.shape({ id: PT.number })),
  }).isRequired,
};
