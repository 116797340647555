import { Description } from '@mui/icons-material';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';
import { langNotInDocs, langNotToConvertFormat } from 'i18n/languages';
import { StyledIconButton } from '../../../buttons';
import config from '../../../../config';

function Docs({ title, handleClose }) {
  const { t, i18n } = useTranslation(['api-docs']);

  const docsLang = langNotToConvertFormat.includes(i18n.language)
    ? i18n.language
    : `${i18n.language.substring(0, 2)}-${i18n.language
        .substring(0, 2)
        .toUpperCase()}`;

  const docsUrl =
    langNotInDocs.includes(i18n.language) || !i18n.language
      ? config.url.docs('')
      : config.url.docs(docsLang);

  return title ? (
    <MenuItem
      component="a"
      color="inherit"
      data-cy="docs-button"
      data-testid="docs-button"
      onClick={() => {
        window.open(docsUrl, '_blank', 'noreferrer');
        handleClose();
      }}
      aria-label={t('api-docs:apiDocs')}
    >
      <Description data-testid="docs" />
      <Typography
        variant="button"
        sx={{ paddingLeft: theme => theme.spacing(1) }}
      >
        {title}
      </Typography>
    </MenuItem>
  ) : (
    <StyledIconButton
      title={t('api-docs:apiDocs')}
      component="a"
      color="inherit"
      aria-label={t('api-docs:apiDocs')}
      icon={<Description data-testid="docs" />}
      data-testid="docs-button"
      data-cy="docs-button"
      onClick={() => {
        window.open(docsUrl, '_blank', 'noreferrer');
      }}
    />
  );
}

Docs.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Docs.defaultProps = {
  title: null,
  handleClose: null,
};

export default Docs;
