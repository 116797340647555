import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectPermissionContext } from 'context';

export default () => {
  const {
    writePermission,
    checkPermission,
    approvePermission,
    adminPermission,
  } = React.useContext(ProjectPermissionContext);

  const { t } = useTranslation(['common', 'parameters', 'sources']);

  const renderTooltipDeleteIcon = type => {
    if (!writePermission) {
      return [t('common:permission.notAllowed'), true];
    }
    if (type === 'asset') {
      return [t('common:buttons.removeAsset'), false];
    }
    if (type === 'source') {
      return [t('common:buttons.removeSource'), false];
    }
    if (type === 'dataSet') {
      return [t('common:buttons.removeDataSet'), false];
    }
    return [t('common:buttons.remove'), false];
  };

  const renderTooltipEditCloseIcon = (isEditing, type) => {
    if (!writePermission) {
      return [t('common:permission.notAllowed'), true];
    }
    if (isEditing) {
      return [t('common:buttons.closeEdit'), false];
    }
    if (type === 'Asset') {
      return [t('common:dataDrawer.header.editAssetTooltip'), false];
    }
    if (type === 'Source') {
      return [t('common:dataDrawer.header.editSourceTooltip'), false];
    }
    if (type === 'dataSet') {
      return [t('common:dataDrawer.header.editDataSetTooltip'), false];
    }
    return [t('common:dataDrawer.header.editParameterTooltip'), false];
  };

  const renderTooltipReject = status => {
    if (status === 'answered' && (checkPermission || writePermission))
      return [t('common:reject'), false];
    if (status === 'checked' && (approvePermission || writePermission))
      return [t('common:reject'), false];
    if (status === 'approved' && (adminPermission || approvePermission))
      return [t('common:reject'), false];
    return [t('common:permission.notAllowed'), true];
  };

  const renderTooltipDoneIcon = status => {
    if (status === 'answered' && checkPermission) {
      return [t('common:dataDrawer.header.statusTooltipChecked'), false];
    }
    if (status === 'checked' && approvePermission) {
      return [t('common:dataDrawer.header.statusTooltipApproved'), false];
    }
    if (status === 'approved') {
      return [t('common:dataDrawer.header.parameterApproved'), true];
    }
    if (status === 'rejected' && (checkPermission || writePermission)) {
      return [t('common:dataDrawer.header.statusTooltipAnswered'), false];
    }

    return [t('common:permission.notAllowed'), true];
  };

  const renderTooltipRestore = () => {
    if (writePermission) return [t('common:buttons.restore'), false];
    return [t('common:permission.notAllowed'), true];
  };

  return {
    renderTooltipDeleteIcon,
    renderTooltipEditCloseIcon,
    renderTooltipReject,
    renderTooltipDoneIcon,
    renderTooltipRestore,
  };
};
