import PT from 'prop-types';
import { Notifications } from '@mui/icons-material';
import { Badge } from '@mui/material';

function NotificationsButton({ lastSeen, notificationList }) {
  return (
    <Badge
      aria-label="notification-badge"
      role="presentation"
      color="error"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        notificationList
          ? notificationList.reduce(
              // eslint-disable-next-line no-confusing-arrow
              (count, message) => (message.id > lastSeen ? count + 1 : count),
              0
            )
          : 0
      }
    >
      <Notifications
        data-testid="notifications"
        data-cy="notifications-button"
      />
    </Badge>
  );
}

NotificationsButton.propTypes = {
  lastSeen: PT.number,
  notificationList: PT.arrayOf(
    PT.shape({
      id: PT.number,
    })
  ),
};

NotificationsButton.defaultProps = { lastSeen: null, notificationList: null };

export default NotificationsButton;
