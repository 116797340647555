import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const Instructions = () => {
  const { t } = useTranslation(['templates']);

  const steps = [
    {
      primary: t('templates:wizardInstructionSteps.instructions.label'),
      secondary: t('templates:wizardInstructionSteps.instructions.content'),
    },
    {
      primary: t('templates:wizardInstructionSteps.selectAssets.label'),
      secondary: t('templates:wizardInstructionSteps.selectAssets.content'),
    },
    {
      primary: t(
        'templates:wizardInstructionSteps.confirmParametersToAdd.label'
      ),
      secondary: t(
        'templates:wizardInstructionSteps.confirmParametersToAdd.content'
      ),
    },
    {
      primary: t('templates:wizardInstructionSteps.createDataSet.label'),
      secondary: t('templates:wizardInstructionSteps.createDataSet.content'),
    },
  ];

  const Circle = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  }));

  return (
    <Box aria-label="data-set-wizard-instructions" sx={{ width: '100%' }}>
      <Typography aria-label="create-data-set-steps" variant="body1">
        {t('templates:wizard.createDataSetSteps')}
      </Typography>
      <List aria-label="instructions-list">
        {steps.map((step, index) => (
          <div key={step.primary}>
            <ListItem aria-label="instruction-list-item">
              <ListItemIcon aria-label="list-item-icon">
                <Circle>{index + 1}</Circle>
              </ListItemIcon>
              <ListItemText primary={step.primary} secondary={step.secondary} />
            </ListItem>
            {index < steps.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Box>
  );
};

export default Instructions;
