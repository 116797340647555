import { Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.mode !== 'dark' && theme.palette.primary.main,
  height: '65vh',
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  height: '100%',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(10),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: '.7rem',
  fontWeight: theme.typography.fontWeightLight,
  [theme.breakpoints.only('xs')]: {
    fontSize: 24,
  },
}));
