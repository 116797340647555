import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useImmer } from 'use-immer';
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { referenceDataApi, handleApiError, parametersApi } from 'api';
import { isEmpty, uniqBy } from 'lodash';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

export const SourcesContext = React.createContext();

export const SourcesProvider = ({ children }) => {
  const [selectedSource, setSelectedSource] = useState({});
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [allSources, setAllSources] = useImmer([]);
  const [sourceParameters, setSourceParameters] = useImmer([]);
  const [parametersLoading, setParametersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const [sourceErrorOrSuccess, setSourceErrorOrSuccess] = useState({});
  const [openAddSource, setOpenAddSource] = useState(false);
  const [openGlobalSources, setOpenGlobalSources] = useState(false);
  const [newSource, setNewSource] = useState({});
  const [globalSources, setGlobalSources] = useImmer([]);
  const [paramAfter, setParamAfter] = useState();
  const [hasMore, setHasMore] = useState(0);
  const cancelParametersSource = axios.CancelToken.source();
  const { projectId, sourceId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['sources']);
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  useEffect(() => {
    if (!sourceId || isEmpty(allSources)) {
      setSelectedSource({});
      setSourceErrorOrSuccess({});
    } else {
      const selectedSourceRow = allSources.find(
        source => source.id === sourceId
      );
      if (isEmpty(selectedSourceRow)) {
        setSelectedSource({});
        setSourceErrorOrSuccess({
          msg: t('sources:invalidSource'),
          details: t('sources:invalidSourceDetails'),
        });
        setRowSelectionModel([sourceId]);
      } else {
        setSourceErrorOrSuccess({});
        setSelectedSource(selectedSourceRow);
        setRowSelectionModel([sourceId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSources, sourceId]);

  useEffect(() => {
    setSelectedSource({});
    setOpenEditingForm(false);
    setIsLoading(true);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getSources = async after => {
      const query = {
        include_global: 'only',
        sort_by: 'title',
        order: 'asc',
        show_deleted: searchParams.get('show_deleted_sources') || 'false',
      };

      if (!openGlobalSources) {
        query.reference_id = projectId;
        query.include_global = 'true';
      }

      if (after) query.after = after;
      const response = await referenceDataApi(
        'getSources',
        query,
        source.token
      ).catch(handleApiError);
      if (!didCancel && response) {
        const { sources, paging } = response;

        if (openGlobalSources) {
          setGlobalSources(curr => [...curr, ...sources]);
        } else {
          setAllSources(curr => [...curr, ...sources]);
        }

        if (paging?.cursors?.after) {
          await getSources(paging?.cursors?.after);
        }
        setIsLoading(false);
      }
    };

    if (isEmpty(allSources) || openGlobalSources || search) {
      setAllSources([]);
      getSources();
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, searchParams, openGlobalSources]);

  useEffect(() => {
    if (!isEmpty(newSource)) {
      setAllSources(curr => [newSource, ...curr]);
      navigate({
        pathname: `/projects/${projectId}/sources/${newSource.id}`,
        search: `?${createSearchParams({ ...searchString })}`,
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSource]);

  const getSourceParameters = async after => {
    setParametersLoading(true);
    const query = {
      project_id: projectId,
      page_limit: 100,
      sort_by: 'parameter_type_name',
      order: 'asc',
      source_id: sourceId,
      show_deleted: true,
    };
    if (after) query.after = after;

    try {
      const response = await parametersApi(
        'getAllParameters',
        query,
        cancelParametersSource.token
      );
      if (response) {
        const { parameters: params, paging } = response;
        setSourceParameters(currentParameters =>
          uniqBy([...currentParameters, ...params], 'id')
        );
        setParamAfter(paging?.cursors?.after || null);
        setParametersLoading(false);
      }
    } catch (error) {
      setSourceErrorOrSuccess({
        msg: error?.response?.data?.msg,
        details: error?.response?.data?.details,
      });
      setParametersLoading(false);
    }
  };

  useEffect(() => {
    setParamAfter('');
    setParametersLoading(true);
    setSourceParameters([]);
    setHasMore(0);
    if (sourceId) {
      getSourceParameters();
    }
    return () => {
      cancelParametersSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId]);

  useEffect(() => {
    setParametersLoading(true);
    if (sourceId && hasMore) {
      getSourceParameters(paramAfter);
    }
    return () => {
      cancelParametersSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore]);

  return (
    <SourcesContext.Provider
      value={{
        selectedSource,
        setSelectedSource,
        setOpenEditingForm,
        openEditingForm,
        allSources,
        setAllSources,
        rowSelectionModel,
        setRowSelectionModel,
        sourceErrorOrSuccess,
        setSourceErrorOrSuccess,
        sourceParameters,
        isLoading,
        setIsLoading,
        openAddSource,
        setOpenAddSource,
        setNewSource,
        openGlobalSources,
        setOpenGlobalSources,
        globalSources,
        paramAfter,
        getSourceParameters,
        parametersLoading,
        setParametersLoading,
        hasMore,
        setHasMore,
      }}
    >
      {children}
    </SourcesContext.Provider>
  );
};

SourcesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
