import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import Alert from 'components/alerts';

function ErrorHandling({ addingProjectErrors }) {
  const { t } = useTranslation(['projects']);

  const errorHandler = err => {
    if (err.status === 409 && !err.isConfidential)
      return t('projects:addProjectErrors.alreadyInDDB');
    if (err.isConfidential)
      return t('projects:addProjectErrors.alreadyInDDBConfidential');
    return err.msg || err.message;
  };
  return (
    <>
      <br />
      <Alert text={errorHandler(addingProjectErrors)} />
    </>
  );
}

ErrorHandling.propTypes = {
  addingProjectErrors: PT.shape({
    status: PT.number,
    message: PT.string,
    msg: PT.string,
  }).isRequired,
};

export default ErrorHandling;
