import { SvgIcon } from '@mui/material';
import { forwardRef } from 'react';

const RemovedAssetIcon = forwardRef(function RemovedAssetIcon(props, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      data-testid="ArchiveIcon"
      aria-label="archive-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.57022 23.8441L23.8441 1.57022L22.4299 0.156006L20.3729 2.21296C20.2064 2.16348 20.0347 2.13698 19.8631 2.13698H3.86311C2.86311 2.13698 1.86311 3.03698 1.86311 4.13698V7.14698C1.86311 7.86698 2.29311 8.48698 2.86311 8.83698V19.7228L0.156006 22.4299L1.57022 23.8441ZM4.86311 17.7228V9.13698H13.4489L10.4489 12.137H8.86311V13.7228L4.86311 17.7228ZM15.4489 7.13698L18.4489 4.13698H3.86311V7.13698H15.4489ZM8.09755 20.137H18.8631V9.37141L21.8631 6.37141V7.14698C21.8631 7.86698 21.4331 8.48698 20.8631 8.83698V20.137C20.8631 21.237 19.7631 22.137 18.8631 22.137H6.09755L8.09755 20.137ZM14.0975 14.137H14.8631V13.3714L14.0975 14.137Z"
        fillOpacity="0.87"
      />
    </SvgIcon>
  );
});

export default RemovedAssetIcon;
