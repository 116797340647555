import { Typography, Grid, Tooltip, Divider } from '@mui/material';
import { StyledIconButton } from 'components/buttons';
import { DataGrid } from 'components/tables';
import { SourcesContext } from 'context';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkIcon } from '@mui/icons-material';
import { formatInternationalizedValue, formatValueUnit } from 'utils';

const LinkedParameters = () => {
  const { i18n } = useTranslation([], { useSuspense: false });
  const { sourceParameters, parametersLoading, paramAfter, setHasMore } =
    useContext(SourcesContext);
  const { t } = useTranslation([
    'common',
    'sources',
    'parameters',
    'data-grid',
  ]);
  const { projectId } = useParams();

  const CustomFooterStatusComponent = () => {
    return (
      <>
        <Divider />
        <Grid aria-label="customFooter" container justifyContent="flex-end">
          <Typography
            variant="body2"
            sx={{ padding: theme => theme.spacing(1) }}
          >
            {paramAfter
              ? t('data-grid:rowsLoading', { rows: sourceParameters.length })
              : t('data-grid:rowsLoaded', { rows: sourceParameters.length })}
          </Typography>
        </Grid>
      </>
    );
  };

  const columns = [
    {
      field: 'parameterType',
      headerName: t('parameters:paramName'),
      flex: 1,
      type: 'string',
      valueGetter: ({ value }) => value?.name,
    },
    {
      field: 'parents',
      headerName: t('parameters:parentAssets'),
      flex: 1,
      type: 'string',
      valueGetter: ({ value }) => value[0]?.name || t('common:project'),
    },
    {
      flex: 1,
      hideable: false,
      field: 'value',
      headerName: t('common:dataDrawer.content.value'),
      valueGetter: ({ row }) => {
        const internationalizedValue =
          row?.parameterType?.dataType === 'number' &&
          typeof row?.selectedEntry?.values?.[0]?.value === 'number'
            ? formatInternationalizedValue(
                row?.selectedEntry?.values?.[0]?.value,
                i18n.language
              )
            : row?.selectedEntry?.values?.[0]?.value;
        const units = row?.selectedEntry?.values?.[0]?.unit?.symbol || '';
        const valueWithUnit = formatValueUnit(
          internationalizedValue,
          units,
          i18n.language
        );
        return valueWithUnit;
      },
    },
    {
      field: 'Link',
      headerName: t('common:link'),
      align: 'center',
      flex: 0.25,
      renderCell: ({ row }) => {
        return row.deletedAt ? (
          <Tooltip aria-label="removed-parameter" title={t('common:removed')}>
            <RemovedAssetIcon
              sx={{ color: theme => theme.palette.secondary.dark }}
            />
          </Tooltip>
        ) : (
          <StyledIconButton
            title={t('common:buttons.linkTo', {
              link: `Parameter: ${row.parameterType.name}`,
            })}
            tooltipProps={{ placement: 'left' }}
            href={
              row?.parents[0]?.id
                ? `/projects/${projectId}/asset/${row?.parents[0]?.id}/parameters/${row.id}`
                : `/projects/${projectId}/parameters/${row.id}`
            }
            icon={<LinkIcon />}
          />
        );
      },
    },
  ];
  return (
    <Grid container>
      <Typography
        variant="subtitle1"
        sx={{ padding: theme => theme.spacing(1) }}
      >
        {t('sources:linkedParameters')}
      </Typography>
      <Grid
        aria-label="linked-parameters-grid"
        item
        xs={12}
        sx={{ margin: theme => theme.spacing(1) }}
      >
        <DataGrid
          data-cy="linked-parameters-data-grid"
          sx={{
            '& .MuiDataGrid-cell': {
              pt: theme => theme.spacing(0.25),
              pb: theme => theme.spacing(0.25),
            },
          }}
          columnBuffer={4}
          density="standard"
          columns={columns}
          rows={sourceParameters}
          loading={parametersLoading}
          onRowsScrollEnd={() => {
            if (paramAfter) {
              setHasMore(prev => prev + 1);
            }
          }}
          footer={CustomFooterStatusComponent}
        />
      </Grid>
    </Grid>
  );
};

export default LinkedParameters;
