import PT from 'prop-types';
import Wizard from 'components/wizard';
import useAssetWizard from './hooks/use-asset-wizard';

const AssetWizard = ({ open, toggleOpen }) => {
  const { steps, submit, handleClose, setError } = useAssetWizard(toggleOpen);

  return (
    <Wizard
      wizard="assets"
      handleToggleOpen={handleClose}
      open={open}
      steps={steps}
      handleSubmit={submit}
      setError={setError}
    />
  );
};

AssetWizard.propTypes = {
  open: PT.bool.isRequired,
  toggleOpen: PT.func.isRequired,
};

export default AssetWizard;
