import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ExpandedStyledTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: 5,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.getContrastText,
}));

export const FoldedStyledTypography = styled(Typography)(({ theme }) => ({
  writingMode: 'vertical-lr',
  transform: 'rotate(-180deg)',
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.getContrastText,
}));
