import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const StyledGrid = styled(Grid)(({ props }) => ({
  direction: 'row',
  justifyContent: 'center',
  ...props,
}));

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const StyledTypography = styled(Typography)(({ props }) => ({
  fontWeight: 700,
  textTransform: 'capitalize',
  ...props,
}));
