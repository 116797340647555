import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { StyledIconButton } from 'components/buttons';
import Feedback from './feedback';
import LanguageMenu from './language';
import Notifications from './notifications';
import handleMobileMenu from './hooks';
import Docs from './docs';
import Bugs from './bugs';

function MobileMenu({ notifications }) {
  const { t } = useTranslation(['header', 'api-docs']);
  const { anchorEl, handleMenu, handleClose } = handleMobileMenu();

  return (
    <div data-testid="mobileMenu">
      <StyledIconButton
        title={t('header:mobileMenu')}
        color="inherit"
        aria-label={t('header:mobileMenu')}
        onClick={handleMenu}
        icon={<MoreVert />}
      />
      <Menu
        id="menu-mobile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LanguageMenu
          handleClose={handleClose}
          title={t('header:languageSelection')}
        />
        <Docs handleClose={handleClose} title={t('api-docs:apiDocs')} />

        <Feedback
          title={t('header:provideFeedback')}
          handleClose={handleClose}
        />
        <Bugs title={t('header:reportBug')} handleClose={handleClose} />
        <Notifications
          title={t('header:notifications')}
          handleClose={handleClose}
          {...notifications}
        />
      </Menu>
    </div>
  );
}

MobileMenu.propTypes = {
  toggleUserGuide: PT.func.isRequired,
  notifications: PT.shape({
    handleNotificationsToggle: PT.func.isRequired,
    notificationsOpen: PT.bool.isRequired,
    lastSeen: PT.number,
    notificationList: PT.arrayOf(PT.shape({ id: PT.number })),
  }).isRequired,
};

export default MobileMenu;
