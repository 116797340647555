/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable max-nested-callbacks */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { isEmpty, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { documentApi } from 'api';
import { ProjectContext } from 'context';

export const ReportingContext = React.createContext();

export const ReportingProvider = ({ children }) => {
  const { project } = useContext(ProjectContext);
  const { projectId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [report, setReport] = useState();
  const [content, setContent] = useState({});
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [reportResponse, setReportResponse] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [assetsSelected, setAssetsSelected] = useState(0);
  const [parametersSelected, setParametersSelected] = useState(0);
  const { t } = useTranslation(['reporting', 'projects']);
  const [remap, setRemap] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchTemplates = async () => {
      try {
        const response = await documentApi('getReportTemplates');
        if (response) {
          const { reportTemplates } = response;
          setTemplates(reportTemplates);
          setIsLoading(false);
        }
      } catch (err) {
        setError(err);
      }
    };

    if (projectId) fetchTemplates();
    return () => {
      source.cancel();
    };
  }, [projectId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setError();
    setContent({});
    setReportResponse({});
    setIsContentLoading(true);
    const fetchTemplateContent = async () => {
      setAssetsSelected(0);
      setParametersSelected(0);
      try {
        const response = await documentApi('getContent', {
          project_id: projectId,
          template_id: report.id,
        });
        if (response) {
          const { content: reportContent } = response;
          let valuesMissing = reportContent.nonRepeatRegions.filter(
            parameter =>
              parameter.value === null && !parameter.name.includes('_ units')
          );
          let parametersMissing = reportContent.nonRepeatRegions.filter(
            parameter =>
              parameter.value === undefined &&
              !parameter.name.includes('_ units')
          );
          reportContent.nonRepeatRegions.valuesMissing = valuesMissing;
          reportContent.nonRepeatRegions.parametersMissing = parametersMissing;

          if (!isEmpty(reportContent.repeatRegions)) {
            reportContent.repeatRegions.forEach(list =>
              list.assets.forEach(asset => {
                if (!asset.id) {
                  setError({
                    msg: t('reporting:repeatRegionError'),
                    details: t('reporting:repeatRegionErrorDetails'),
                  });
                }

                valuesMissing = asset.parameters.filter(
                  parameter =>
                    parameter.value === null &&
                    !parameter.name.includes('_ units')
                );
                parametersMissing = asset.parameters.filter(
                  parameter =>
                    parameter.value === undefined &&
                    !parameter.name.includes('_ units')
                );

                asset.valuesMissing = valuesMissing;
                asset.parametersMissing = parametersMissing;
                valuesMissing.length === asset.parameters.length
                  ? (asset.checked = false)
                  : (asset.checked = true);
              })
            );
          }
          setContent(reportContent);
          setIsContentLoading(false);
        }
      } catch (err) {
        setIsContentLoading(false);
        setError(err);
      }
    };
    if (report?.name && remap) {
      fetchTemplateContent();
    }
    return () => {
      source.cancel();
    };
  }, [report, remap]);
  useEffect(() => {
    const selectionCounts = () => {
      if (content?.repeatRegions) {
        const assetsSelectedList = content.repeatRegions.map(region => {
          return region.assets.filter(asset => {
            return asset.checked === true && !!asset.id;
          });
        });
        const assetsSelectedCount = assetsSelectedList.reduce(
          (length, curr) => length + curr.length,
          0
        );

        const parametersSelectedCount = assetsSelectedList.reduce(
          (tot, curr) => {
            return (
              tot +
              curr.reduce(
                (total, current) =>
                  total +
                  current.parameters.filter(param => {
                    return !param.name.includes('_ units');
                  }).length,
                0
              )
            );
          },
          0
        );

        setAssetsSelected(assetsSelectedCount);
        setParametersSelected(parametersSelectedCount);
      }
    };

    selectionCounts();
  }, [content]);

  const updateSelection = (assetId, name) => {
    const repeatArray = [...content.repeatRegions];
    const parentIndex = findIndex(repeatArray, repeat => repeat.name === name);
    const valIndex = findIndex(
      repeatArray[parentIndex].assets,
      repeat => repeat.id === assetId
    );
    setContent(curr => {
      const updated = { ...curr };
      updated.repeatRegions[parentIndex].assets[valIndex].checked =
        !updated.repeatRegions[parentIndex].assets[valIndex].checked;
      return updated;
    });
  };

  const downloadReport = async () => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    setError();
    const source = axios.CancelToken.source();

    const putTemplateContent = async body => {
      try {
        const response = await documentApi('putContent', {
          body,
          template_id: content.templateId,
          response: { responseType: 'arraybuffer', responseEncoding: 'binary' },
        });
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${report.name}-${project.shortTitle}-${date}.docx`;
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
        });
        setDownloadLoading(false);
      } catch (err) {
        setDownloadLoading(false);
        setError(err);
      }
    };
    setDownloadLoading(true);
    const nonRepeat = content.nonRepeatRegions.filter(region => {
      return region.value !== '';
    });
    const repeating = content.repeatRegions.map(list => {
      return {
        ...list,
        assets: list.assets.reduce((acc, curr) => {
          if (curr.checked) {
            acc.push({
              id: curr.id,
              name: curr.name,
              parameters: curr.parameters.filter(param => {
                return param.value !== '';
              }),
            });
          }
          return acc;
        }, []),
      };
    });
    const reportBody = {};
    reportBody.project_id = content.projectId;
    reportBody.version_id = content.versionId;
    reportBody.non_repeat_regions = [...nonRepeat];
    reportBody.repeat_regions = [...repeating];
    if (report.name && content) {
      putTemplateContent(reportBody);
    }
    return () => {
      source.cancel();
    };
  };

  return (
    <ReportingContext.Provider
      value={{
        templates,
        isLoading,
        error,
        setError,
        report,
        setReport,
        setContent,
        updateSelection,
        downloadReport,
        downloadLoading,
        content,
        isContentLoading,
        setIsContentLoading,
        reportResponse,
        setActiveStep,
        activeStep,
        setRemap,
        assetsSelected,
        parametersSelected,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

ReportingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
