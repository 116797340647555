import { useContext, useEffect, useState } from 'react';
import { AssetContext } from 'context';
import { isEmpty, uniq } from 'lodash';

export default (setExpanded, expanded) => {
  const {
    allAssets,
    theAsset,
    getAssets,
    assetsLoading,
    isExpanding,
    setIsExpanding,
  } = useContext(AssetContext);
  const [openSwitchMenu, setOpenSwitchMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandAll = async () => {
    setIsExpanding(true);
    await getAssets({ descendants_of: theAsset.id });
  };

  useEffect(() => {
    const getAssetChildren = childArray => {
      const array = [];
      const newChildren = [];
      const children = allAssets.filter(asset => {
        return childArray.includes(asset.id);
      });
      children.forEach(child => {
        array.push(child.id);
        array.push(`${child.assetType.id}_${child.parent}`);
        if (child.children) newChildren.push(...child.children);
      });
      setExpanded(curr => [...curr, ...array]);
      if (newChildren.length) getAssetChildren(newChildren);
    };
    if (isExpanding && !assetsLoading) {
      const path = [];
      path.push(theAsset.id);
      path.push(`${theAsset.assetType.id}_${theAsset.parent}`);
      if (!isEmpty(theAsset.children)) {
        setExpanded(curr => uniq([...path, ...curr]));
        getAssetChildren(theAsset.children);
      }
      setIsExpanding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanding, assetsLoading]);

  const handleCollapseAll = () => {
    if (expanded.length) {
      setExpanded([]);
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenSwitchMenu(!openSwitchMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenSwitchMenu(!openSwitchMenu);
  };

  return {
    handleExpandAll,
    handleCollapseAll,
    handleClick,
    handleClose,
    openSwitchMenu,
    anchorEl,
  };
};
