import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useImmer } from 'use-immer';
import { handleApiError, parametersApi } from 'api';
import { AssetContext, ParametersContext } from 'context';

export default open => {
  const { projectId, assetId } = useParams();
  const [parametersLoading, setParametersLoading] = useState(true);
  const [allLoading, setAllLoading] = useState(true);
  const [parameterTypes, setParameterTypes] = useImmer([]);
  const { theAsset, isLoading } = useContext(AssetContext);
  const [openImportDrawer, setOpenImportDrawer] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [allParameterTypes, setAllParameterTypes] = useImmer([]);
  const { parameters } = useContext(ParametersContext);
  const [error, setError] = useState(undefined);

  const handleOpen = () => setOpenImportDrawer(!openImportDrawer);

  useEffect(() => {
    let didCancel = false;
    const getParameterTypes = async after => {
      const query = {
        not_project_id: projectId,
        sort_by: 'name',
        order: 'asc',
        scope_id: projectId,
        show_local: true,
        show_global: true,
      };

      if (assetId) {
        if (theAsset?.assetSubType?.id)
          query.asset_sub_type_id = theAsset?.assetSubType?.id;
        else query.asset_type_id = theAsset?.assetType?.id;
        query.not_asset_id = theAsset?.id;
      } else query.asset_type_id = '';

      if (after) {
        query.after = after;
      }
      const response = await parametersApi('getAllParameterTypes', query).catch(
        err => {
          handleApiError(err, []);
          setError(err);
          setParametersLoading(false);
        }
      );
      if (!didCancel && response) {
        const { parameterTypes: data, paging } = response;
        const filteredResponse = data.map(type => {
          return {
            id: uuidv4(),
            parameterTypeId: type.id,
            dataType: type.dataType,
            status: 'unanswered',
            value: null,
            unitTypeId: type.unitType?.id || null,
            unit: null,
            name: type.name,
            source: null,
            tags: [],
            disciplines: [],
            checked: true,
          };
        });
        setParameterTypes(curr => [...curr, ...filteredResponse]);
        if (paging?.cursors?.after) {
          getParameterTypes(paging.cursors.after);
        } else {
          setParametersLoading(false);
        }
      }
    };

    if (projectId && open && !isLoading) {
      setParameterTypes([]);
      getParameterTypes();
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, open, theAsset, isLoading]);

  const getAllParameterTypes = async after => {
    const query = {
      page_limit: 500,
      sort_by: 'name',
      order: 'asc',
      scope_id: projectId,
      show_local: true,
      show_global: true,
    };

    if (after) {
      query.after = after;
    }
    if (theAsset?.id) {
      query.not_asset_id = theAsset?.id;
    }
    try {
      const response = await parametersApi('getAllParameterTypes', query);

      if (response) {
        const { parameterTypes: data, paging } = response;
        let types;
        // removes existing project level parameter instance types from list
        // not possible to query by all parameter types not existing at project level of a specific project
        if (!theAsset?.id) {
          const existingTypes = parameters.map(
            parameter => parameter.parameterType.id
          );
          types = data.filter(type => {
            return !existingTypes.includes(type.id);
          });
        } else {
          types = data;
        }
        const filteredResponse = types.map(type => {
          return {
            id: uuidv4(),
            parameterTypeId: type.id,
            parameterTypeName: type.name,
            dataType: type.dataType,
            globalParameter: type.globalParameter,
            status: 'unanswered',
            value: null,
            unitTypeId: type.unitType?.id || null,
            unit: null,
            name: type.name,
            source: null,
            tags: [],
            disciplines: [],
            checked: true,
            currentAvailableParameter: !!parameterTypes.find(
              param => param.parameterTypeId === type.id
            ),
          };
        });
        setAllParameterTypes(curr => [...curr, ...filteredResponse]);
        if (paging?.cursors?.after) {
          getAllParameterTypes(paging.cursors.after);
        } else {
          setAllLoading(false);
        }
      }
    } catch (err) {
      setError(err);
      setAllLoading(false);
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      open &&
      !isLoading &&
      openImportDrawer &&
      !allParameterTypes.length
    ) {
      setAllLoading(true);
      getAllParameterTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, theAsset, isLoading, open, isLoading, openImportDrawer]);

  return {
    parametersLoading,
    setParametersLoading,
    parameterTypes,
    openImportDrawer,
    setOpenImportDrawer,
    handleOpen,
    inputValue,
    setInputValue,
    error,
    allParameterTypes,
    allLoading,
    setAllLoading,
  };
};
