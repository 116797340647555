/* eslint-disable no-param-reassign */
import { Grid, Typography, Skeleton, Box } from '@mui/material';
import Alert from 'components/alerts';
import { useContext } from 'react';
import { ReportingContext } from 'context';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useMapping from './hooks/useMapping';
import MappedRegion from '../mapped-region';

const Mapping = () => {
  const { t } = useTranslation('reporting');
  const {
    report,
    content,
    isContentLoading,
    error,
    assetsSelected,
    parametersSelected,
  } = useContext(ReportingContext);
  const { open, setOpen } = useMapping();

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignContent="left"
      spacing={2}
      paddingTop={5}
      paddingBottom={10}
    >
      <Grid item container direction="row" alignItems="center">
        <Typography variant="h5">{report?.name}</Typography>
        <Grid
          sx={{ marginLeft: 'auto' }}
          color="primary"
          display="flex"
          alignItems="center"
        >
          <Typography variant="h6" paddingRight={1}>
            {t('reporting:hotdocs')}
          </Typography>
          <img src="/logos/hotdocs.png" alt="hotdocs logo" />
        </Grid>
      </Grid>
      <Grid item pb={2}>
        <Grid item xs={12}>
          <Typography color="GrayText">
            {t('reporting:mappingDescription')}
          </Typography>
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={1} sx={{ mb: 1 }}>
          <Box>
            <Alert
              title={
                error.name === 'ApiError' && !error.msg
                  ? error.name
                  : error?.msg
              }
              text={
                error.name === 'ApiError' && !error.msg
                  ? t('reporting:errorMessage')
                  : error?.details
              }
            />
          </Box>
        </Grid>
      )}
      <Grid item pb={2}>
        <Grid item xs={12}>
          <Typography color="GrayText">
            {t('reporting:instancesSelectedMessage', {
              assetInstances: assetsSelected,
              parametersSelected,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" width="100%" spacing={2}>
        {isContentLoading && <Skeleton key="simplePlaceholders" />}
        {!isContentLoading && !isEmpty(content.nonRepeatRegions) && (
          <MappedRegion
            name={t('reporting:placeholders')}
            id="simplePlaceholders"
            open={open}
            parameters={content.nonRepeatRegions}
            setOpen={setOpen}
            valuesMissing={content.nonRepeatRegions.valuesMissing}
            parametersMissing={content.nonRepeatRegions.parametersMissing}
          />
        )}
        {!isContentLoading &&
          !isEmpty(content.repeatRegions) &&
          content.repeatRegions.map(({ name, assets }, i) => {
            return (
              <MappedRegion
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                id={name}
                name={name}
                open={open}
                setOpen={setOpen}
                assets={assets}
                missingRepeatRegionAssets={!assets[0].id}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

export default Mapping;
