/* eslint-disable max-nested-callbacks */
import { useState, useEffect } from 'react';
import qs from 'qs';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  useSearchParams,
  useParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parameterMetadataApi, handleApiError } from 'api';
import { formatCurrentFilters, formatValue } from 'components/filters/helper';

export default () => {
  const [openSwitchMenu, setOpenSwitchMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const { projectId } = useParams();
  const [tagOptions, setTagOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const disciplineTagType = '188ed00e-5ea7-4781-8609-ccab012c019d';
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();
    let didCancel = false;
    setLoading(true);
    const getTags = async (typeId, after) => {
      const { tags, paging } = await parameterMetadataApi(`getTags`, {
        sort_by: 'name',
        order: 'asc',
        tag_type_id: typeId,
        after,
      }).catch(err => {
        handleApiError(err, []);
        setLoading(false);
      });

      if (tags && !didCancel) {
        setTagOptions(curr => [...curr, ...tags]);
        if (paging?.cursors?.after) getTags(typeId, paging.cursors.after);
      } else {
        setLoading(false);
      }
    };
    setTagOptions([]);
    getTags(disciplineTagType);

    return () => {
      source.cancel();
      didCancel = true;
    };
  }, [projectId]);

  const clearFilters = () => {
    setFilters({
      sort_by: { sortBy: 'name' },
      tag_id: [],
    });
  };

  const sortByOptions = [
    { label: 'name', sortBy: 'name' },
    { label: 'parameterList.createdAt', sortBy: 'created_at' },
  ];

  const handleOnChange = (value, key) =>
    setFilters({ ...filters, [key]: value });

  const { t } = useTranslation(['common', 'templates']);

  const [searchParams, setSearchParams] = useSearchParams({
    show_drafts: 'false',
    show_published: 'true',
    show_active_instances: 'false',
    sort_by: 'name',
    tag_id: [],
  });
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  const filtersMap = [
    {
      loading,
      id: 'common:disciplines',
      key: 'tag_id',
      options: tagOptions || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
  ];

  const setFilterFromParams = () => {
    const filtersFromSearch = {
      sort_by: {
        sortBy: searchParams.get('sort_by') || 'name',
      },
      tag_id: formatValue(searchParams.getAll('tag_id'), 'id'),
    };
    setFilters(() => {
      const newFilters = { ...filtersFromSearch };
      Object.entries(newFilters).forEach(filterType => {
        const filterTypeName = filterType[0];
        const filterTypeList = filterType[1];
        const filterSet = filtersMap.find(
          filter => filter.key === filterTypeName
        );
        if (
          filterTypeName !== 'sort_by' &&
          !isEmpty(filterTypeList && filterSet.options)
        ) {
          const withName = filterTypeList.map(value => {
            return filterSet.options.find(tag => tag.id === value.id);
          });
          newFilters[filterTypeName] = withName;
        }
      });
      return newFilters;
    });
    setOpenSwitchMenu(!openSwitchMenu);
  };

  useEffect(() => {
    const {
      show_published: showPublished,
      show_drafts: showDrafts,
      show_active_instances: showActive,
      sort_by: sortBy,
      tag_id: tagId,
    } = parsedSearch;
    const defaults = {
      show_published: showPublished,
      show_drafts: showDrafts,
      show_active_instances: showActive,
      sort_by: sortBy,
      tag_id: tagId,
    };
    defaults.show_published =
      showPublished ?? searchParams.get('show_published');
    defaults.show_drafts = showDrafts ?? searchParams.get('show_drafts');
    defaults.show_active_instances =
      showActive ?? searchParams.get('show_active_instances');
    defaults.sort_by = sortBy ?? searchParams.get('sort_by');
    defaults.tag_id = tagId ?? searchParams.getAll('tag_id');

    setSearchParams(defaults, { replace: true });
    setFilterCount(searchParams.getAll('tag_id').length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onToggle = (e, type) => {
    const types = {
      draft: 'show_drafts',
      published: 'show_published',
      active: 'show_active_instances',
    };
    searchParams.set(types[type], e.target.checked ? 'true' : 'false');
    navigate({
      pathname: `/projects/${projectId}/templates`,
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const filterMenu = [
    {
      switchLabel: t('templates:showDrafts'),
      handleChange: e => {
        onToggle(e, 'draft');
      },
      isChecked: parsedSearch?.show_drafts === 'true',
    },
    {
      switchLabel: t('templates:showPublished'),
      handleChange: e => {
        onToggle(e, 'published');
      },
      isChecked: parsedSearch?.show_published === 'true',
    },
    {
      switchLabel: t('templates:showActiveTemplates'),
      handleChange: e => {
        onToggle(e, 'active');
      },
      isChecked: parsedSearch?.show_active_instances === 'true',
    },
  ];

  const handleClick = () => {
    setOpenSwitchMenu(!openSwitchMenu);
  };

  const countFilters = availableFilters => {
    let count = 0;
    Object.keys(availableFilters).forEach(key => {
      if (key !== 'sort_by') {
        count += availableFilters[key].length;
      }
    });
    setFilterCount(count);
  };

  const applyFilters = () => {
    const availableFilters = formatCurrentFilters(filters);

    const defaultSearchParams = {
      show_published: searchParams.get('show_published') || 'true',
      show_drafts: searchParams.get('show_drafts') || 'false',
      show_active_instances:
        searchParams.get('show_active_instances') || 'false',
      sort_by: searchParams.get('sort_by') || 'name',
      tag_id: searchParams.getAll('tag_id') || [],
    };
    countFilters(availableFilters);
    navigate({
      pathname: `/projects/${projectId}/templates`,
      search: `?${createSearchParams({
        ...defaultSearchParams,
        ...availableFilters,
      })}`,
    });
    setOpenSwitchMenu(false);
  };

  const handleOnCancel = () => {
    setFilterFromParams();
  };

  return {
    setOpenSwitchMenu,
    openSwitchMenu,
    filterCount,
    setFilterCount,
    onToggle,
    filterMenu,
    handleClick,
    filters,
    sortByOptions,
    handleOnChange,
    clearFilters,
    applyFilters,
    handleOnCancel,
    filtersMap,
    countFilters,
    handleFilterActive: setFilterFromParams,
  };
};
