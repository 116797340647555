import PT from 'prop-types';
import { Grid, Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  StyledPriorityHigh,
  StyledCircularProgress,
  StyledCircularProgressStatus,
  StyledDeleteOutline,
} from './status-circle-styles';

function StatusCircle({ status, size, thickness, loading }) {
  const statusCircle = {
    unanswered: 0,
    answered: 100 / 3,
    checked: 200 / 3,
    approved: 100,
    project: 100,
  };
  const { t } = useTranslation();

  const nonCircleStatusReference = {
    rejected: (
      <Grid item>
        <Tooltip wrapper="span" title={status}>
          <StyledPriorityHigh status={status} size={size} />
        </Tooltip>
      </Grid>
    ),
    deleted: (
      <Grid item width={size} height={size}>
        <Tooltip
          wrapper="span"
          title={t('common:removed')}
          width={size}
          height={size}
        >
          <StyledDeleteOutline status={status} size={size} />
        </Tooltip>
      </Grid>
    ),
  };
  return loading ? (
    <Skeleton
      variant="circular"
      width={size}
      height={size}
      data-testid="statusLoading"
    />
  ) : (
    <Grid container sx={{ width: size, height: size, position: 'relative' }}>
      {nonCircleStatusReference[status] ? (
        nonCircleStatusReference[status]
      ) : (
        <Grid item>
          <StyledCircularProgress
            aria-label="progressCircle"
            size={size}
            thickness={thickness}
            variant="determinate"
            value={100}
          />
          <Tooltip wrapper="span" title={status}>
            <StyledCircularProgressStatus
              size={size}
              thickness={thickness}
              variant="determinate"
              value={statusCircle[status]}
              status={status}
            />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

StatusCircle.propTypes = {
  status: PT.string || PT.bool,
  size: PT.number,
  thickness: PT.number,
  loading: PT.bool,
};

StatusCircle.defaultProps = {
  status: 'unanswered',
  size: 30,
  thickness: 10,
  loading: false,
};

export default StatusCircle;
