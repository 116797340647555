import { forwardRef } from 'react';
import PT from 'prop-types';
import { Translate, ArrowDropDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Tooltip as ToolTip,
  useMediaQuery,
} from '@mui/material';
import config from 'config';
import { LANGUAGES_LABELS } from 'i18n/languages';
import { useCookies } from 'react-cookie';
import { countryToFlag } from 'utils';
import LanguageLabel from '../label';
import handleLanguageMenu from './hooks';

const LanguageMenu = forwardRef(({ title, handleClose }, ref) => {
  const [cookies] = useCookies(['ddbCookiesPolicyAccepted']);
  const { t } = useTranslation(['header']);
  const { languageMenu, handleLanguageIconClick, handleLanguageMenuClose } =
    handleLanguageMenu(handleClose);
  const screen = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isMac = navigator.userAgentData?.platform?.includes('mac') || false;
  return (
    <>
      {title ? (
        <MenuItem
          color="inherit"
          onClick={handleLanguageIconClick}
          data-cy="languageMenu"
        >
          <Translate data-testid="languageMenu" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      ) : (
        <ToolTip
          title={
            !cookies?.ddbCookiesPolicyAccepted?.functional &&
            config.featureFlags.arePoliciesEnabled
              ? t('cookies:requiresCookies', {
                  name: t('header:languageSelection'),
                })
              : t('header:languageSelection')
          }
        >
          <Button
            color="inherit"
            aria-owns={languageMenu ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-label={t('header:languageSelection')}
            onClick={handleLanguageIconClick}
            data-cy="languageMenu"
          >
            <Translate data-testid="languageMenu" />
            {screen && <LanguageLabel languages={LANGUAGES_LABELS} />}
            <ArrowDropDown fontSize="small" />
          </Button>
        </ToolTip>
      )}
      <Menu
        ref={ref}
        id="language-menu"
        anchorEl={languageMenu}
        open={Boolean(languageMenu)}
        onClose={handleLanguageMenuClose}
      >
        {LANGUAGES_LABELS.map(language => (
          <MenuItem
            dense
            key={language.code}
            onClick={e => handleLanguageMenuClose(e, language.code)}
            data-cy={language.code}
          >
            {`${language.text} `}
            {isMac && countryToFlag(language.code.split('-')[1])}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

LanguageMenu.displayName = 'LanguageMenu';

LanguageMenu.propTypes = {
  handleClose: PT.func,
  title: PT.string,
};

LanguageMenu.defaultProps = {
  title: null,
  handleClose: null,
};

export default LanguageMenu;
