/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/display-name */
import {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useRef,
  useEffect,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { useMediaQuery, ListSubheader } from '@mui/material';

const listBoxPadding = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + listBoxPadding,
  };

  // eslint-disable-next-line no-prototype-builtins
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  return cloneElement(dataSet, {
    style: inlineStyle,
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListBoxComponent = forwardRef(function ListBoxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach(item => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 52 : 60;

  const getChildSize = child => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 60;
    }
    if (child.key.length > 200 && !smUp) {
      return 220;
    }
    if (child.key.length > 200 || (child.key.length > 100 && !smUp)) {
      return 100;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * listBoxPadding}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListBoxComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
};

export default ListBoxComponent;
