import { lighten, styled } from '@mui/material/styles';
import { Fab } from '@mui/material';

export default styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  margin: theme.spacing(0.95),
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.1),
  },
}));
