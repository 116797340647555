import { useState, useEffect } from 'react';
import axios from 'axios';
import { commentsApi, handleApiError } from 'api';

const useParameterAccordionDetails = (comment, accordionExpanded) => {
  const [isCommentLoaded, setIsCommentLoaded] = useState(!!comment?.content);
  const [commentContent, setCommentContent] = useState(comment?.content);

  useEffect(() => {
    if (!accordionExpanded || isCommentLoaded) return;

    let didCancel = false;
    const source = axios.CancelToken.source();

    const getCommentById = async commentId => {
      try {
        const response = await commentsApi(
          'getCommentById',
          { comment_id: commentId },
          source.token
        );

        if (!didCancel && response) {
          setCommentContent(response.comment.content);
          setIsCommentLoaded(true);
        }
      } catch (error) {
        handleApiError(error);
      }

      return () => {
        didCancel = true;
        source.cancel();
      };
    };

    if (comment?.id && !comment?.content) {
      setIsCommentLoaded(false);
      getCommentById(comment.id);
    } else {
      setIsCommentLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionExpanded]);

  return {
    commentContent,
    isCommentLoaded,
  };
};

export default useParameterAccordionDetails;
