import { useMediaQuery, Grid } from '@mui/material';
import { useContext } from 'react';
import { ProjectContext } from 'context';
import { useTranslation } from 'react-i18next';
import Alert from 'components/alerts';
import { StyledTypography } from '../reporting-page-styles';

const ReportingTitle = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { t } = useTranslation(['reporting']);
  const { project } = useContext(ProjectContext);

  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item>
        <StyledTypography
          aria-label="drawer-title-reporting"
          variant={screenSm ? 'h6' : 'h5'}
          pt={1.2}
        >
          {t('reporting:autoReporting')} - {project?.shortTitle}
        </StyledTypography>
      </Grid>
      <Grid item sx={{ gap: '10px' }} display="grid">
        <Alert title={t('reporting:autoReportingWarning')} severity="warning" />
        <Alert
          title={t('reporting:autoReportingStorageWarning')}
          severity="warning"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingTitle;
