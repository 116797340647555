import PT from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import MarkdownElement from '../../markdown';
import usePanel from './hooks';

export default function ControlledAccordion({ data }) {
  const { expanded, handleChange } = usePanel();

  return (
    <Box sx={{ width: '100%', paddingBottom: theme => theme.spacing(2) }}>
      {data.map((info, i) => (
        <Accordion
          data-testid="accordion"
          key={info.heading}
          expanded={expanded === i}
          onChange={handleChange(i)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`${info.heading} content`}
            id={info.heading}
            data-cy="accordion-button"
          >
            <Typography
              sx={{
                fontSize: theme => theme.typography.pxToRem(15),
                flexBasis: '33.33%',
                flexShrink: 0,
                fontWeight: theme => theme.typography.fontWeightMedium,
              }}
              role="heading"
            >
              {info.heading}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <MarkdownElement md={info.content} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

ControlledAccordion.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      content: PT.string.isRequired,
      heading: PT.string.isRequired,
    }).isRequired
  ).isRequired,
};
