/* eslint-disable no-console */
/* eslint-disable default-case */
import {
  PublicClientApplication,
  LogLevel,
  EventType,
} from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you are a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const msalConfig = {
  auth: {
    clientId: '51e2bcd2-cd52-4ff0-9140-4430f860a417',
    authority:
      'https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea/',
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
    redirectUri: `${window.location.origin}/auth.html`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          /* istanbul ignore next */
          return;
        }
        switch (level) {
          /* istanbul ignore next */
          case LogLevel.Error:
            /* istanbul ignore next */
            console.error(message);
            /* istanbul ignore next */
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          /* istanbul ignore next */
          case LogLevel.Verbose:
            /* istanbul ignore next */
            console.debug(message);
            /* istanbul ignore next */
            return;
          /* istanbul ignore next */
          case LogLevel.Warning:
            /* istanbul ignore next */
            console.warn(message);
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/.default'],
};

export const ddbTokenRequest = {
  scopes: ['api://ddb-api/.default'],
};

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    /* istanbul ignore next */
    const { account } = event.payload;
    /* istanbul ignore next */
    msalInstance.setActiveAccount(account);
  }
});

export default msalInstance;
