import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import config from 'config';

export default handleClose => {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies([
    'i18next',
    'ddbCookiesPolicyAccepted',
  ]);
  const [languageMenu, setLanguageMenu] = useState(null);

  const handleLanguageIconClick = event => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = (event, languageCode) => {
    if (event.currentTarget.nodeName === 'LI') {
      i18n.changeLanguage(languageCode);
      if (
        cookies?.ddbCookiesPolicyAccepted?.functional ||
        !config.featureFlags.arePoliciesEnabled
      ) {
        setCookie('i18next', languageCode, {
          path: '/',
          maxAge: 31536000,
        });
      }
    }
    if (handleClose) handleClose();
    setLanguageMenu(null);
  };

  return { languageMenu, handleLanguageIconClick, handleLanguageMenuClose };
};
