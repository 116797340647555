/* eslint-disable no-unused-vars */
import PT from 'prop-types';
import { useContext } from 'react';
import { DataGrid } from 'components/tables';
import { Box, Chip, Grid } from '@mui/material';
import { lighten, darken, useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import Alert from 'components/alerts';
import { ThemeContext } from 'context';
import { StyledIconButton } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import useParameterTypes from './hooks';

const ParameterTypeImporter = ({
  values,
  setFormAttribute,
  isAssetLoading,
  open,
  setOpenImportDrawer,
  currentAvailableParameter,
  allParameterTypes,
  isLoading,
  error,
  ...rest
}) => {
  const theme = useTheme();
  const { remove, push } = rest;
  const { rowSelectionModel, updateSelectionModel } = useParameterTypes(
    push,
    allParameterTypes,
    values,
    remove
  );
  const { localTheme } = useContext(ThemeContext);
  const { t } = useTranslation(['common', 'parameters']);

  const columns = [
    {
      field: 'parameterTypeName',
      flex: 2,
      headerName: t('common:parameterType'),
      type: 'string',
      valueGetter: params => params.row.parameterTypeName,
    },
    {
      field: 'dataType',
      flex: 1,
      headerName: t('common:dataDrawer.dataType'),
      type: 'string',
      valueGetter: params => params.row.dataType,
    },
    {
      field: 'globalParameter',
      flex: 1,
      headerName: t('parameters:globalParameter'),
      type: 'boolean',
      valueGetter: params => params.row.globalParameter,
    },
    {
      field: 'currentAvailableParameter',
      flex: 2,
      headerName: t('parameters:recommendedAssetType'),
      type: 'boolean',
      valueGetter: params => params.row.currentAvailableParameter,
    },
  ];

  return (
    <>
      {error && (
        <Box sx={{ padding: theme.spacing(3) }} aria-label="import-alert">
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      <Grid container>
        <Grid item xs={11.5}>
          <DataGrid
            sx={{
              marginTop: theme.spacing(2),
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                {
                  display: 'none',
                },
            }}
            aria-label="parameter-type-table"
            exportName="test"
            getRowId={row => row.parameterTypeId}
            showToolbar
            loading={isLoading}
            columnBuffer={5}
            rows={allParameterTypes}
            columns={columns}
            rowBufferPx={100}
            disableRowSelectionOnClick
            hideFooterPagination={false}
            density="standard"
            sortingOrder={['asc', 'desc']}
            height="50vh"
            rowHeight={50}
            noRowsMessage="test"
            searchToolbar
            checkboxSelection
            onRowSelectionModelChange={newRowSelectionModel => {
              updateSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
          />
        </Grid>
        <Grid item xs={0.5}>
          <StyledIconButton
            title={t('common:close')}
            icon={<Close />}
            onClick={() => setOpenImportDrawer(false)}
          />
        </Grid>
        <Grid item xs={12}>
          {values?.parameterTypes?.map(param => {
            return (
              <Chip
                aria-label={`chip-${param.parameterTypeName}`}
                sx={{
                  margin: theme.spacing(0.25),
                  marginTop: theme.spacing(1),
                  color: theme.palette.textSecondary,
                  backgroundColor:
                    localTheme === 'dark'
                      ? darken(theme.palette.primary.main, 0.3)
                      : lighten(theme.palette.primary.main, 0.8),
                }}
                key={param.id}
                label={param.name}
                onDelete={() => {
                  updateSelectionModel(
                    rowSelectionModel.filter(id => id !== param.parameterTypeId)
                  );
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

ParameterTypeImporter.propTypes = {
  values: PT.shape({
    itemsImport: PT.arrayOf(
      PT.shape({ parameterTypeName: PT.string, id: PT.string })
    ),
    parameterTypes: PT.arrayOf(PT.shape({})),
  }).isRequired,
  currentAvailableParameter: PT.arrayOf(PT.shape({})).isRequired,
  setFormAttribute: PT.func.isRequired,
  isAssetLoading: PT.bool.isRequired,
  open: PT.bool.isRequired,
  setOpenImportDrawer: PT.func.isRequired,
  allParameterTypes: PT.arrayOf(PT.shape({})).isRequired,
  error: PT.shape({ msg: PT.string, details: PT.string }),
  isLoading: PT.bool.isRequired,
};

ParameterTypeImporter.defaultProps = { error: undefined };

export default ParameterTypeImporter;
