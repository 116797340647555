import { useState } from 'react';
import PT from 'prop-types';
import {
  IconButton,
  ClickAwayListener,
  Typography,
  Button,
  Tooltip,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TooltipButton = ({ titleHeader, titleBody, onClick, icon }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        onOpen={handleTooltipOpen}
        open={open}
        aria-label="tooltip-icon-button"
        title={
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" aria-label="header">
                {titleHeader}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" aria-label="body">
                {titleBody}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Button
                aria-label="go-button"
                variant="contained"
                onClick={onClick}
              >
                {t('common:buttons.go')}
              </Button>
            </Grid>
          </Grid>
        }
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton onClick={handleTooltipOpen}>{icon}</IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

TooltipButton.propTypes = {
  titleHeader: PT.string.isRequired,
  titleBody: PT.string.isRequired,
  onClick: PT.func.isRequired,
  icon: PT.element.isRequired,
};

export default TooltipButton;
