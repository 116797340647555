import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  fontStyle: 'italic',
  textDecoration: 'underline',
}));

const CookiesButton = props => {
  const { t } = useTranslation();
  return (
    <StyledButton {...props} variant="contained">
      {t('buttons.accept')}
    </StyledButton>
  );
};
export default CookiesButton;
