import { styled, darken } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

const getStatusColor = (theme, status) => {
  const statusLookup = {
    unanswered: theme.palette.secondary.dark,
    answered: theme.palette.error.main,
    checked: theme.palette.warning.light,
    approved: theme.palette.success.light,
    rejected: theme.palette.error.dark,
  };
  return statusLookup[status];
};

export default styled(LinearProgress)(({ theme, ...props }) => ({
  padding: 1,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? darken(theme.palette.secondary.dark, 0.5)
      : theme.palette.secondary.main,
  height: 12,
  borderRadius: 5,
  '& .MuiLinearProgress-bar': {
    backgroundColor: getStatusColor(theme, props.status),
    borderRadius: 5,
  },
}));
