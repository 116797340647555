/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { IconButton, Tooltip as ToolTip } from '@mui/material';

const StyledIconButton = ({
  title,
  tooltipProps,
  icon,
  'data-testid': dataTestId,
  type,
  buttonSize,
  ...rest
}) => {
  return (
    <ToolTip title={title} {...tooltipProps}>
      <span data-testid={dataTestId}>
        <IconButton
          color="primary"
          size={buttonSize || 'large'}
          {...rest}
          data-cy={rest['data-cy'] || type}
          aria-label={title}
        >
          {icon}
        </IconButton>
      </span>
    </ToolTip>
  );
};

StyledIconButton.propTypes = {
  onClick: PT.func,
  title: PT.oneOfType([PT.string, PT.node]),
  tooltipProps: PT.object,
  icon: PT.any.isRequired,
  'data-testid': PT.string,
  type: PT.string,
  buttonSize: PT.string,
};

StyledIconButton.defaultProps = {
  onClick: () => {},
  title: '',
  tooltipProps: {},
  'data-testid': null,
  type: '',
  buttonSize: '',
};

export default StyledIconButton;
