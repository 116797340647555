import { NotInterested } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Container } from '@mui/material';

function NoProjects() {
  const { t } = useTranslation();
  return (
    <Container
      maxWidth="lg"
      sx={{
        [theme => theme.breakpoints.up('md')]: {
          height: '65vh',
        },
      }}
      data-testid="noProjects"
      data-cy="noProjects"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          height: '100%',
          paddingTop: theme => theme.spacing(22),
          [theme => theme.breakpoints.down('lg')]: {
            paddingTop: theme => theme.spacing(16),
          },
        }}
      >
        <Grid item>
          <NotInterested fontSize="large" />
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            color="inherit"
            gutterBottom
            sx={{
              letterSpacing: '.7rem',
              fontWeight: theme => theme.typography.fontWeightLight,
              [theme => theme.breakpoints.only('xs')]: {
                fontSize: 24,
              },
            }}
          >
            {t('projects:noProjects')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" color="inherit">
            {t('projects:noProjectsDescription')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NoProjects;
