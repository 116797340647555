import { Box } from '@mui/material';
import PT from 'prop-types';
import ParameterAccordionDetails from './event-type-details/parameter-accordion-details';
import AssetAccordionDetails from './event-type-details/asset-accordion-details';
import SourceAccordionDetails from './event-type-details/source-accordion-details';
import { historyPropTypes } from '../history-content/prop-types/prop-types';

const AuditAccordionDetails = ({ history, expanded }) => {
  return (
    <Box aria-label="accordion-details-container">
      {history.parameterId !== undefined && (
        <ParameterAccordionDetails history={history} expanded={expanded} />
      )}
      {history.assetId !== undefined && (
        <AssetAccordionDetails history={history} />
      )}
      {history.sourceId !== undefined && (
        <SourceAccordionDetails history={history} />
      )}
    </Box>
  );
};

AuditAccordionDetails.propTypes = {
  expanded: PT.bool.isRequired,
  history: historyPropTypes.isRequired,
};

export default AuditAccordionDetails;
