import PT from 'prop-types';
import Wizard from 'components/wizard';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import useDataSetWizard from './hooks/use-data-set-wizard';

const DataSetWizard = ({ open, toggleOpen }) => {
  const {
    steps,
    submitDataSet,
    handleClose,
    validateParameters,
    suggestedName,
  } = useDataSetWizard(toggleOpen);

  return (
    <Form
      key="add-data-set"
      onSubmit={submitDataSet}
      initialValues={{
        dataSet: [],
        newAssets: [],
        requirementsCount: 0,
        validationErrorDetails: [],
        dataSetName: suggestedName,
        postDataSetError: [],
      }}
      validate={validateParameters}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        values,
        handleSubmit,
        form: {
          mutators: { push: pushMutator },
          change,
        },
        ...rest
      }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          aria-label="dataset-wizard-form"
        >
          <Wizard
            wizard="dataSets"
            handleToggleOpen={handleClose}
            open={open}
            steps={steps}
            handleSubmit={handleSubmit}
            pushMutator={pushMutator}
            values={values}
            change={change}
            suggestedName={suggestedName}
            {...rest}
          />
        </form>
      )}
    />
  );
};

DataSetWizard.propTypes = {
  open: PT.bool.isRequired,
  toggleOpen: PT.func.isRequired,
};

export default DataSetWizard;
