import { Typography, Grid, Link, useMediaQuery } from '@mui/material';
import config from 'config';
import { Cookie } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function CookiesHeader() {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { t } = useTranslation(['cookies', 'footer']);
  const headerText = [
    { link: false, key: t('cookies:ddbCookiesBody') },
    {
      key: t('cookies:ddbCookiesLink'),
      link: (
        <Link
          aria-label="link-to-cookies"
          underline="always"
          color="primary"
          target="_blank"
          variant="body2"
          href={config.url.policiesCookies}
        >
          {t('footer:cookiesPolicy')}
        </Link>
      ),
    },
    { link: false, key: t('cookies:editCookies') },
  ];

  return (
    <Grid container aria-label="cookies-header">
      {!screenSm && (
        <Grid item xs={1}>
          <Cookie fontSize="large" aria-label="cookie-icon" />
        </Grid>
      )}
      <Grid item xs={11}>
        <Typography component="h1" variant="h4">
          {t('cookies:ddbCookiesTitle')}
        </Typography>
      </Grid>
      {headerText.map(text => (
        <Typography
          key={text.key}
          variant="body2"
          sx={{ paddingTop: theme => theme.spacing(1) }}
        >
          {text.key}
          {text.link && text.link}
        </Typography>
      ))}
    </Grid>
  );
}

export default CookiesHeader;
