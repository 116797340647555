import { TextField } from 'mui-rff';
import PT from 'prop-types';
import { MenuItem } from '@mui/material';

function Select(props) {
  const { label, name, size, options, error, helperText, required, ...rest } =
    props;
  return (
    <TextField
      variant="standard"
      label={label}
      name={name}
      size={size || 'small'}
      select
      required={required}
      error={error}
      helperText={helperText}
      {...rest}
    >
      {options.map(type => {
        return (
          <MenuItem
            key={type.id}
            value={type.id}
            disabled={type.disabled || false}
          >
            {type.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
Select.propTypes = {
  label: PT.string.isRequired,
  required: PT.bool,
  name: PT.string.isRequired,
  size: PT.string,
  options: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.string, PT.bool]),
      name: PT.string,
      disabled: PT.bool,
    })
  ).isRequired,
  helperText: PT.string,
  error: PT.bool,
};

Select.defaultProps = {
  required: true,
  size: 'small',
  helperText: '',
  error: false,
};
export default Select;
