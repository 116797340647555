import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Link,
  Skeleton,
  Box,
} from '@mui/material';
import Alert from 'components/alerts';
import { Email } from '@mui/icons-material';
import html from 'remark-html';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeHighlight from 'rehype-highlight';
import powershell from 'highlight.js/lib/languages/powershell';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from 'components/buttons';
import { legal } from 'components/footer/links';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';
import { isEmpty } from 'lodash';
import StyledMarkdown from './markdown-styles';

function TermsConditionsConsent() {
  const {
    openTermsConditions,
    onAcceptPolicyClick,
    policyLastUpdated,
    agreeChecked,
    setAgreeChecked,
    apiDocs,
    markdownLoading,
    additionalLinks,
    imageString,
    error,
  } = useGetCookies();

  const { t } = useTranslation(['cookies', 'footer', 'common']);

  const linksList = [...legal];
  linksList.splice(3, 0, ...additionalLinks);

  let dateString;
  if (policyLastUpdated) {
    dateString = policyLastUpdated.toDateString();
  }

  return (
    <Dialog
      maxWidth="md"
      open={openTermsConditions}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        component="span"
        id="scroll-dialog-title"
        sx={{
          padding: theme => theme.spacing(4),
          paddingBottom: theme => theme.spacing(1),
        }}
      >
        <Typography variant="h4">{t('cookies:ddbTermsConditions')}</Typography>
        {markdownLoading && (
          <Skeleton
            height={30}
            width="100%"
            display="inline"
            aria-label="loading-date"
          />
        )}
        {!markdownLoading && !isEmpty(error) && (
          <Box
            sx={{ padding: theme => theme.spacing(3) }}
            aria-label="markdown-error"
          >
            <Alert title={error.message} text={error.response.status} />
          </Box>
        )}
        {!markdownLoading && isEmpty(error) && (
          <>
            <Typography variant="h">
              {t('cookies:ddbTermsConditionsUpdated', {
                date: dateString,
              })}
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingTop: theme => theme.spacing(2) }}
            >
              {t('cookies:ddbTermsConditionsBody')}
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: theme => theme.spacing(1),
          minHeight: theme => theme.spacing(40),
        }}
      >
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={0}
          component="span"
        >
          {markdownLoading || !isEmpty(error) ? (
            <Skeleton
              height={30}
              width="100%"
              display="inline"
              aria-label="loading-markdown"
            />
          ) : (
            <>
              <StyledMarkdown
                remarkPlugins={[html]}
                rehypePlugins={[
                  [rehypeHighlight, { languages: { powershell } }],
                  rehypeRaw,
                  rehypeSlug,
                ]}
              >
                {apiDocs[0]}
              </StyledMarkdown>
              <Grid
                aria-label="downloadImage"
                sx={{ paddingLeft: theme => theme.spacing(10) }}
              >
                <a
                  download
                  href={`data:image/svg+xml;utf8,${encodeURIComponent(
                    imageString
                  )}`}
                  alt="Powered By DDB"
                >
                  <img
                    alt="Powered By DDB"
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      imageString
                    )}`}
                  />
                </a>
              </Grid>
              <StyledMarkdown
                remarkPlugins={[html]}
                rehypePlugins={[
                  [rehypeHighlight, { languages: { powershell } }],
                  rehypeRaw,
                  rehypeSlug,
                ]}
              >
                {apiDocs[1]}
              </StyledMarkdown>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: theme => theme.spacing(4),
          paddingBottom: theme => theme.spacing(1),
          paddingTop: theme => theme.spacing(2),
        }}
      >
        <Grid container width="100%">
          <Grid item>
            <Typography variant="h6">
              {t('cookies:supportingDocuments')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('common:legalMoreLinks')}
            </Typography>
            <Grid container>
              <ul
                style={{
                  listStyle: 'none',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                {linksList?.map(({ text, name, ...rest }) => (
                  <li key={text}>
                    <Link
                      sx={{ paddingRight: theme => theme.spacing(3) }}
                      underline="always"
                      color="primary"
                      variant="body2"
                      aria-label={name}
                      data-cy={name}
                      {...rest}
                    >
                      {text.includes('arup')
                        ? `${t(text)}`
                        : `Digital Design Brief - ${t(text)}`}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(1) }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEmpty(error) || markdownLoading}
                    checked={agreeChecked}
                    onChange={() => {
                      setAgreeChecked(!agreeChecked);
                    }}
                  />
                }
                label={t('cookies:acceptDDBTermsConditions')}
              />
            </FormGroup>
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            item
            xs={12}
            sx={{ marginTop: theme => theme.spacing(1) }}
          >
            <Button
              sx={{ width: '60%', padding: 'auto' }}
              disabled={!agreeChecked}
              aria-label="accept-terms-button"
              variant="contained"
              onClick={onAcceptPolicyClick}
            >
              {t('common:buttons.accept')}
            </Button>
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="baseline"
            item
            xs={12}
            sx={{ marginTop: theme => theme.spacing(2) }}
          >
            <Typography>{t('common:contactUs')}</Typography>
            <StyledIconButton
              type="contactButton"
              title={t('contactUs')}
              variant="outlined"
              onClick={() => {
                window.open(
                  'mailto:ddb@arup.com?subject=DDB Terms and Conditions'
                );
              }}
              icon={<Email />}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default TermsConditionsConsent;
