import PT from 'prop-types';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
} from '@mui/material';

function SortDropdown({
  options,
  label,
  handleSortDirection,
  sortDirection,
  disabled,
  getOptionLabel,
  variant,
  ...rest
}) {
  return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          inputProps={{
            ...params.inputProps,
            'aria-label': rest['aria-label'],
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            disableUnderline: true,
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  color="primary"
                  data-testid="toggleSort"
                  onClick={handleSortDirection}
                  disabled={disabled}
                  aria-label={
                    sortDirection === 'asc'
                      ? 'Ascending button'
                      : 'Descending button'
                  }
                  size="large"
                >
                  {sortDirection === 'asc' ? (
                    <ArrowUpward data-testid="asc" />
                  ) : (
                    <ArrowDownward data-testid="desc" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

SortDropdown.propTypes = {
  options: PT.arrayOf(PT.shape({})).isRequired,
  variant: PT.string,
  label: PT.string,
  sortDirection: PT.string.isRequired,
  handleSortDirection: PT.func.isRequired,
  getOptionLabel: PT.func,
  disabled: PT.bool,
};

SortDropdown.defaultProps = {
  label: null,
  getOptionLabel: option => option.title,
  disabled: false,
  variant: 'standard',
};

export default SortDropdown;
