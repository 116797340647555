/* eslint-disable no-param-reassign */
import { Suspense, useContext } from 'react';
import { Divider, Grid } from '@mui/material';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import NavigationHeader from 'components/cards/navigation-header';

import {
  AssetContext,
  PageLayoutContext,
  DataSetsWizardProvider,
  DataTemplatesProvider,
} from 'context';

import Assets from './assets';
import useAssetNavigation from './hook/use-asset-navigation';
import Filter from './filter';
import Items from './items';
import { StyledBox } from '../parameters-page-styles';
import AssetWizard from '../asset-wizard';

const AssetNavigation = () => {
  const { openDrawers, numberOfOpen } = useContext(PageLayoutContext);
  const { wizardOpen } = useContext(AssetContext);
  const {
    assetNodes,
    loading,
    isProjectAssetHierarchy,
    filterMenu,
    handleOpenCloseDrawer,
    handleOpenCloseWizard,
    expanded,
    setExpanded,
  } = useAssetNavigation();

  return (
    <>
      <FoldingDrawer
        open={openDrawers.openNavigation}
        tooltip="common:drawers.assetNavigation"
        width={numberOfOpen > 2 ? '5%' : '20%'}
        onClick={handleOpenCloseDrawer}
      >
        <PageLayoutBox>
          <StyledBox sx={{ overflowY: 'hidden' }}>
            <Suspense fallback={null}>
              <NavigationHeader
                open={openDrawers.openNavigation}
                handleClick={handleOpenCloseWizard}
                label="parameters"
                hasProjectCard
              />
            </Suspense>
            <Divider light />
            {openDrawers.openNavigation && (
              <>
                <Divider light />
                <Grid container>
                  <Grid item lg={7}>
                    <Items />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Filter
                      menu={filterMenu}
                      setExpanded={setExpanded}
                      expanded={expanded}
                    />
                  </Grid>
                </Grid>
                <Assets
                  loading={loading}
                  assetNodes={assetNodes}
                  isProjectAssetHierarchy={isProjectAssetHierarchy}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </>
            )}
          </StyledBox>
        </PageLayoutBox>
      </FoldingDrawer>
      {wizardOpen && (
        <Suspense fallback={null}>
          <DataTemplatesProvider>
            <DataSetsWizardProvider>
              <AssetWizard
                open={wizardOpen}
                toggleOpen={handleOpenCloseWizard}
              />
            </DataSetsWizardProvider>
          </DataTemplatesProvider>
        </Suspense>
      )}
    </>
  );
};

export default AssetNavigation;
