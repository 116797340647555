import { useContext } from 'react';
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  CircularProgress,
  Alert,
  Skeleton,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withAppInsights } from 'app/app-insights';
import { ProjectContext, ProjectPermissionContext } from 'context';

function ProjectSecurity() {
  const { t } = useTranslation(['settings', 'common']);
  const { adminPermission } = useContext(ProjectPermissionContext);
  const {
    loading,
    project,
    updateProjectConfidentiality,
    requestStatus,
    resetUpdateRequest,
  } = useContext(ProjectContext);

  if (loading) return <Skeleton aria-label="loading" />;

  return (
    <Box
      sx={{ padding: theme => theme.spacing(3) }}
      data-testid="project-settings-security"
    >
      <Typography variant="h5">{t('settings:security')}</Typography>
      <FormGroup row>
        <Tooltip
          title={
            adminPermission
              ? t('common:permission.updateProjectSecurity')
              : t('common:permission.notAllowed')
          }
        >
          <FormControlLabel
            control={
              requestStatus.status === 'loading' ? (
                <CircularProgress
                  size={25}
                  thickness={6}
                  aria-label="loading"
                  sx={{
                    margin: theme => theme.spacing(1),
                    color: theme => theme.palette.primary.main,
                  }}
                />
              ) : (
                <Checkbox
                  data-cy="project-confidential-checkbox"
                  checked={project.confidential}
                  onChange={({ target: { checked } }) =>
                    updateProjectConfidentiality(checked)
                  }
                  name="confidential"
                  disabled={
                    !adminPermission || requestStatus.status === 'loading'
                  }
                />
              )
            }
            label={t('settings:confidential')}
            labelPlacement="start"
          />
        </Tooltip>
      </FormGroup>
      {requestStatus.status === 'error' && (
        <Alert
          severity="error"
          onClose={resetUpdateRequest}
          data-testid="error-alert"
        >
          {requestStatus.error.msg}
          {requestStatus.error.details ? ` ${requestStatus.error.details}` : ''}
        </Alert>
      )}
    </Box>
  );
}

export default withAppInsights(ProjectSecurity, 'ProjectSecurityPage');
