/* eslint-disable react/display-name */
import { Security, GroupAdd } from '@mui/icons-material';

export default [
  {
    path: `roles`,
    text: 'user:roles',
    icon: <GroupAdd />,
    userAccess: true,
    'data-cy': 'settings-roles',
  },
  {
    path: `security`,
    text: 'settings:security',
    icon: <Security />,
    userAccess: true,
    'data-cy': 'settings-security',
  },
];
