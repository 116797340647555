import PT from 'prop-types';
import {
  Skeleton,
  Grid,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatTitle } from 'utils';
import {
  StyledTypographyCardInfo,
  StyledListItem,
  StyledTypography,
  StyledListItemText,
} from '../card-styles';
import StyledGrid from './parameter-card-styles';
import StatusCircle from '../../status-circle';

function ParameterCard({ parameter, loading, selected, handleClick, assetId }) {
  const { t } = useTranslation();

  const gridAreas = ['parameterName', 'asset', 'date', 'value'];

  return (
    <StyledListItem role="presentation">
      <ListItemButton
        sx={{
          height: '100%',
          width: '100%',
        }}
        selected={selected}
        onClick={handleClick}
        data-cy="parameterCard"
        aria-label="parameterCard"
        disabled={parameter?.deletedAt !== null || loading}
      >
        <ListItemIcon
          sx={{ maxWidth: '100%', marginLeft: theme => theme.spacing(1) }}
        >
          <StatusCircle
            status={
              parameter?.deletedAt ? t('common:removed') : parameter?.status
            }
            size={40}
            loading={loading}
          />
        </ListItemIcon>
        {loading ? (
          <StyledGrid>
            {gridAreas.map(area => {
              return (
                <Grid
                  item
                  key={area}
                  data-testid="loading"
                  sx={{ gridArea: area, minWidth: '200px' }}
                >
                  <Skeleton width="90%" />
                </Grid>
              );
            })}
          </StyledGrid>
        ) : (
          <Tooltip
            placement="right"
            followCursor
            title={`${formatTitle(parameter?.name)}: ${
              parameter?.valueWithUnit || t('common:dataDrawer.notDefined')
            }`}
          >
            <StyledListItemText data-cy="parameterCardContent">
              <StyledGrid assetId={assetId}>
                <Grid
                  item
                  aria-label="parameterName"
                  sx={{
                    gridArea: 'parameterName',
                    marginBottom: 'auto',
                  }}
                >
                  <StyledTypographyCardInfo noWrap>
                    {formatTitle(parameter?.name)}
                  </StyledTypographyCardInfo>
                </Grid>
                {!assetId && !loading && (
                  <Grid
                    item
                    aria-label="asset"
                    sx={{
                      gridArea: 'asset',
                      marginBottom: 'auto',
                      textAlign: 'right',
                    }}
                  >
                    <StyledTypography noWrap>
                      {parameter?.assetName
                        ? parameter?.assetName
                        : t('common:project')}
                    </StyledTypography>
                  </Grid>
                )}
                {parameter?.staffNameWithDate && !loading && (
                  <Grid
                    item
                    sx={{
                      gridArea: 'date',
                      marginTop: 'auto',
                      textAlign: 'left',
                    }}
                    aria-label="date"
                  >
                    <StyledTypography noWrap>
                      {parameter?.staffNameWithDate}
                    </StyledTypography>
                  </Grid>
                )}
                {parameter?.valueWithUnit && !loading && (
                  <Grid
                    item
                    aria-label="value"
                    sx={{
                      gridArea: 'value',
                      marginTop: 'auto',
                      textAlign: 'right',
                    }}
                  >
                    <StyledTypography noWrap>
                      {parameter?.valueWithUnit}
                    </StyledTypography>
                  </Grid>
                )}
              </StyledGrid>
            </StyledListItemText>
          </Tooltip>
        )}
      </ListItemButton>
    </StyledListItem>
  );
}

ParameterCard.propTypes = {
  parameter: PT.shape({
    createdAt: PT.string,
    staffName: PT.string,
    assetName: PT.string,
    name: PT.string,
    status: PT.string,
    units: PT.string,
    internationalizedValue: PT.oneOfType([PT.string, PT.number, PT.bool]),
    valueWithUnit: PT.string,
    staffNameWithDate: PT.string,
    deletedAt: PT.string,
  }),
  selected: PT.bool,
  loading: PT.bool.isRequired,
  handleClick: PT.func,
  assetId: PT.string,
};
ParameterCard.defaultProps = {
  parameter: {},
  selected: false,
  handleClick: null,
  assetId: '',
};

export default ParameterCard;
