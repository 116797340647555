import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { parametersApi, handleApiError, commentsApi } from 'api';

export default (parameter, setSelectedHistoryRowId) => {
  const { i18n } = useTranslation(['common']);
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const getCommentById = async commentId => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    const response = await commentsApi(
      'getCommentById',
      {
        comment_id: commentId,
      },
      source.token
    ).catch(handleApiError);
    if (response && !didCancel) {
      return response.comment?.content;
    }
    return () => {
      didCancel = true;
    };
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    setSelectedHistoryRowId('');
    const loadAllRevisions = async () => {
      setLoading(true);
      const revisionsResponse = await parametersApi(
        'getEntryRevisions',
        {
          entry_id: parameter?.selectedEntry?.id,
        },
        source.token
      ).catch(err => handleApiError(err, []));

      if (!didCancel && revisionsResponse) {
        const { revisions } = revisionsResponse;

        if (!didCancel && revisions) {
          setHistory(
            await Promise.all(
              revisions.map(async revision => {
                let comment;
                if (revision?.comment) {
                  comment = await getCommentById(revision?.comment);
                }

                return {
                  id: revision.id,
                  comment,
                  status: revision?.status || 'unanswered',
                  date: revision?.createdAt,
                  changed: revision?.createdBy,
                  source: revision?.source,
                  value: revision?.values?.[0],
                  units: revision?.values?.[0].unit?.symbol,
                };
              })
            )
          );
          setLoading(false);
        }
      }
    };
    if (parameter?.selectedEntry?.id) {
      loadAllRevisions();
    } else {
      setHistory([]);
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameter, i18n.language]);

  return { handleChangeOpen, open, history, loading };
};
