/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { findIndex } from 'lodash';
import { parameterMetadataApi, handleApiError, parametersApi } from 'api';
import config from 'config';

export default (tags, setTags, id, referenceTable) => {
  const [deletingTag, setDeletingTag] = useState(null);
  const [deleteTagWarning, setDeleteTagWarning] = useState({ open: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const onChipClick = () => {
    // functionality in here for viewing hierarchy of tags
  };

  const deleteTag = async tag => {
    const index = findIndex(tags, type => type?.id === tag?.id);
    setDeletingTag(tag);
    setTags(currTags => {
      currTags.splice(index, 1);
    });

    const removeTag = async () => {
      await parameterMetadataApi(`deleteTagLink`, {
        tag_id: tag.id,
        reference_id: id,
      }).catch(err => {
        handleApiError(err, []);
        setTags(currTags => [...currTags, deletingTag]);
        setDeletingTag(null);
      });
    };

    removeTag();
  };

  const addTag = async tag => {
    setTags(currTags => [...currTags, tag]);

    const postTagLink = async () => {
      const response = await parameterMetadataApi('addTagLink', {
        tag_id: tag.id,
        body: [
          {
            reference_id: id,
            reference_table: referenceTable || 'parameter',
            reference_url: `${config.url.api.base}/api/${
              referenceTable || 'parameter'
            }s`,
          },
        ],
      }).catch(err => handleApiError(err, []));

      if (response) {
        const { tagLinks } = response;
        if (!tagLinks) {
          setTags(currTags => {
            currTags.pop();
            return currTags;
          });
        }
      }
    };

    postTagLink();
  };

  const deleteDiscipline = async tag => {
    const index = findIndex(tags, type => type.id === tag.id);
    setDeletingTag(tag);
    setTags(currTags => {
      currTags.splice(index, 1);
    });

    const removeDiscipline = async () => {
      const res = await parametersApi(`deleteParameterDiscipline`, {
        tag_id: tag.id,
        parameter_id: id,
      }).catch(err => {
        handleApiError(err, []);
        setTags(currTags => [...currTags, deletingTag]);
        setDeletingTag(null);
      });
    };

    removeDiscipline();
  };

  const addDiscipline = async tag => {
    setTags(currTags => [...currTags, tag]);

    const postDiscipline = async () => {
      const { disciplines: res } = await parametersApi(
        `postParameterDisciplines`,
        {
          body: { discipline_id: tag.id },
          parameter_id: id,
        }
      ).catch(err => handleApiError(err, []));

      if (!res) {
        setTags(currTags => {
          currTags.pop();
          return currTags;
        });
      }
    };
    postDiscipline();
  };

  return {
    onChipClick,
    deleteTag,
    addTag,
    anchorEl,
    setAnchorEl,
    openMenu,
    deleteDiscipline,
    addDiscipline,
    deleteTagWarning,
    setDeleteTagWarning,
  };
};
