import { Routes, Route, Navigate } from 'react-router-dom';
import ParametersPage from 'routes/parameters-page';
import ProjectSettings from 'routes/project-settings';
import TemplatesPage from 'routes/templates';
import ReportingPage from 'routes/reporting-page';
import SourcesPage from 'routes/sources-page';
import HistoryPage from 'routes/history-page';
import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { UserContext } from 'context';
import withProject from 'routes/wrappers/with-project';
import config from 'config';

// eslint-disable-next-line react/prop-types
const Protected = ({ internal, children }) => {
  if (!internal) {
    return <Navigate to="/error" replace />;
  }
  return children;
};

function ProjectPages() {
  const { user } = useContext(UserContext);
  return (
    <Routes>
      <Route path="/*" element={<ParametersPage />} />
      <Route
        path="/templates/*"
        element={
          config.featureFlags.areTemplatesEnabled ? (
            <TemplatesPage />
          ) : (
            <Navigate to="/error" replace />
          )
        }
      />
      <Route
        path="/reporting"
        element={
          !isEmpty(user) &&
          (config.featureFlags.isReportingEnabled ? (
            <Protected internal={user?.roles?.includes('App.Internal')}>
              <ReportingPage />
            </Protected>
          ) : (
            <Navigate to="/error" replace />
          ))
        }
      />
      <Route
        path="/sources/*"
        element={
          !isEmpty(user) &&
          (config.featureFlags.areSourcesEnabled ? (
            <Protected internal={user?.roles?.includes('App.Internal')}>
              <SourcesPage />
            </Protected>
          ) : (
            <Navigate to="/error" replace />
          ))
        }
      />
      <Route
        path="/history/*"
        element={
          !isEmpty(user) &&
          (config.featureFlags.isProjectHistoryTabEnabled ? (
            <Protected internal={user?.roles?.includes('App.Internal')}>
              <HistoryPage />
            </Protected>
          ) : (
            <Navigate to="/error" replace />
          ))
        }
      />
      <Route
        path="/settings/*"
        element={
          !isEmpty(user) &&
          (config.featureFlags.isProjectSettingsEnabled ? (
            <Protected internal={user?.roles?.includes('App.Internal')}>
              <ProjectSettings />
            </Protected>
          ) : (
            <Navigate to="/error" replace />
          ))
        }
      />
      <Route path="*" element={<Navigate to="/error" replace />} />
    </Routes>
  );
}

export default withProject(ProjectPages);
