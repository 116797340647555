import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Alert from 'components/alerts';
import AddRevisions from 'components/revisions';
import ParameterTypeSelector from './parameter-types';
import Summary from './summary';
import AddTags from './tags';

export default () => {
  const { t } = useTranslation(['common', 'parameters', 'errors']);
  const steps = [
    {
      label: 'Parameters',
      optional: false,
      header: t('parameters:defineParametersToAdd'),
      content: props => <ParameterTypeSelector {...props} />,
    },

    {
      label: 'Values',
      optional: true,
      header: t('parameters:addParameterRevision'),
      content: props => <AddRevisions {...props} />,
    },
    {
      label: 'Metadata',
      optional: true,
      header: t('parameters:attachMetadata'),
      content: props => <AddTags {...props} />,
    },
    {
      label: 'Submit',
      optional: false,
      header: t('parameters:addParamConfirmation'),
      content: ({ submitError, ...props }) => (
        <>
          {submitError && (
            <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
              <Alert
                color="error"
                title={submitError?.msg || t('errors:submitError')}
                text={submitError?.details || t('errors:resubmit')}
              />
            </Box>
          )}
          <Summary {...props} />
        </>
      ),
    },
  ];

  return steps;
};
