/* eslint-disable react/no-array-index-key */
import PT from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, Menu } from '@mui/material';

function SimpleMenu({
  options,
  handleSelection,
  anchorEl,
  setAnchorEl,
  anchorOrigin,
  disableGutters,
}) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      data-testid="simple-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      anchorOrigin={anchorOrigin}
    >
      {options.map((option, index) => {
        return (
          <MenuItem
            key={`${index} - ${option.name}`}
            dense
            disableGutters={disableGutters}
            value={option}
            onClick={() => handleSelection(option)}
            style={option.style}
          >
            {option.icon && (
              <ListItemIcon
                key={`list-icon - ${option.name}`}
                data-testid="icon"
              >
                {option.icon}
              </ListItemIcon>
            )}
            <ListItemText
              key={`list-item - ${option.name}`}
              data-testid="option"
            >
              {option.name}
            </ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  );
}

SimpleMenu.propTypes = {
  anchorEl: PT.oneOfType([PT.object, PT.func]),
  options: PT.arrayOf(PT.shape({ name: PT.oneOfType([PT.string, PT.object]) }))
    .isRequired,
  handleSelection: PT.func.isRequired,
  setAnchorEl: PT.func.isRequired,
  disableGutters: PT.bool,
  anchorOrigin: PT.shape({ vertical: PT.string, horizontal: PT.string }),
};

SimpleMenu.defaultProps = {
  disableGutters: false,
  anchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export default SimpleMenu;
