import { darken, styled } from '@mui/material/styles';
import { Dialog, DialogTitle, Grid } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    height: '100vh',
  },
  '& .MuiDialog-paper': {
    height: '100vh',
    display: 'flex',
    flexFlow: 'column',
    backgroundColor:
      theme.palette.mode !== 'dark'
        ? theme.palette.grey[100]
        : darken(theme.palette.grey[300]),
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode !== 'dark'
      ? theme.palette.grey[300]
      : darken(theme.palette.grey[300], 0.8),
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(2),
}));

export const StyledGridWrapper = styled(Grid)(({ theme }) => ({
  spacing: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  justifyContent: 'center',
}));

export const StyledHeaderGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const StyledGrid = styled(Grid)(({ theme, ...props }) => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflowX: 'auto',
  width: '100%',
  height: '100%',
  border: props.wizard === 'assets' && `1px solid ${theme.palette.divider}`,
  padding: props.wizard === 'parameters' && 5,
}));
