import { useContext } from 'react';
import { LocalOffer } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Divider, Typography, Grid, Box } from '@mui/material';
import Alert from 'components/alerts';
import {
  GlobalTypesContext,
  PageLayoutContext,
  ProjectPermissionContext,
} from 'context';
import { formatDate } from 'utils';
import { useParams } from 'react-router-dom';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import DataCardHeader from './header';
import { useParameter, useParameterDetails } from './hooks';
import formatParameterInfo from '../helpers';
import DataDrawerGrid from '../common/grid';
import History from '../common/version-history';
import Status from '../common/status-history';
import Tags from '../common/tags';
import Value from './value';
import Details from './details';
import { StyledBox } from '../parameters-page-styles';
import { StyledGrid, StyledCard } from '../common/details-pane-styles';

function DataDrawer() {
  const { t, i18n } = useTranslation(['parameters', 'common']);
  const { openDrawers, setOpenDrawers, numberOfOpen } =
    useContext(PageLayoutContext);
  const { parameterTagsAndTypes, allDisciplines } =
    useContext(GlobalTypesContext);
  const { writePermission } = useContext(ProjectPermissionContext);

  const { error, parameter, setParameter, loading, commentLoading } =
    useParameter();
  const { internationalizedValue, valueWithUnit } = formatParameterInfo(
    parameter,
    i18n
  );
  const { parameterId } = useParams();
  const {
    parameterTags,
    setParameterTags,
    parameterDisciplines,
    setParameterDisciplines,
    isTagLoading,
    parameterError,
    setParameterError,
    setSelectedHistoryRowId,
    statusHistory,
    statusLoading,
  } = useParameterDetails(parameterId, parameter);

  if (error)
    return (
      <Box sx={{ padding: theme => theme.spacing(3) }}>
        <Alert title={error.msg} text={error.details} />
      </Box>
    );

  return (
    <FoldingDrawer
      width={numberOfOpen > 2 ? '20%' : '30%'}
      open={openDrawers.openParamDetail}
      tooltip="common:drawers.parameterDetails"
      onClick={() =>
        setOpenDrawers(curr => {
          // eslint-disable-next-line no-param-reassign
          curr.openParamDetail = !openDrawers.openParamDetail;
        })
      }
    >
      <PageLayoutBox>
        <StyledBox>
          <DataCardHeader
            loading={loading}
            parameter={parameter}
            setError={setParameterError}
          />
          <Divider />
          {parameterError && (
            <Box sx={{ padding: theme => theme.spacing(3) }}>
              <Alert
                title={parameterError?.msg}
                text={parameterError?.details}
                onClose={() => {
                  setParameterError(undefined);
                }}
              />
            </Box>
          )}
          <StyledCard>
            <StyledGrid
              aria-label="content"
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Value
                internationalizedValue={internationalizedValue}
                valueWithUnit={valueWithUnit}
              />
              <br />
              <Details
                loading={loading}
                parameter={parameter}
                setParameter={setParameter}
                setError={setParameterError}
                commentLoading={commentLoading}
                internationalizedValue={internationalizedValue}
                valueWithUnit={valueWithUnit}
              />
              <History
                parameter={parameter}
                header={
                  parameter?.selectedEntry
                    ? t('common:dataDrawer.history.updated', {
                        date: String(
                          formatDate(
                            parameter?.selectedEntry?.createdAt,
                            undefined,
                            i18n.language
                          )
                        ),
                        email: parameter?.selectedEntry?.createdBy?.email,
                        interpolation: { escapeValue: false },
                      })
                    : t('common:dataDrawer.notDefined')
                }
                setSelectedHistoryRowId={setSelectedHistoryRowId}
              />
              {/* TO DO - refactor status history once multiple entries is implemented */}
              <Status
                parameter={parameter}
                header={
                  parameter?.selectedEntry?.status
                    ? t('common:dataDrawer.status.date', {
                        status: t(
                          `parameters:${
                            statusHistory[0]?.status ||
                            parameter?.selectedEntry?.status ||
                            'unanswered'
                          }`
                        ),
                        date: formatDate(
                          statusHistory[0]?.date,
                          undefined,
                          i18n.language
                        ),
                        email: statusHistory[0]?.updatedBy?.email,
                        interpolation: { escapeValue: false },
                      })
                    : t('common:dataDrawer.notDefined')
                }
                statusHistory={statusHistory}
                statusLoading={statusLoading}
              />
              <DataDrawerGrid
                icon={<LocalOffer />}
                caption=""
                content={
                  <Grid container aria-label="tags component">
                    <Typography variant="caption" color="textSecondary">
                      {t('common:tags')}
                    </Typography>
                    {parameterTagsAndTypes.map(tagType => (
                      <Tags
                        id={parameterId}
                        existingTags={parameterTags}
                        setExistingTags={setParameterTags}
                        tagType={tagType}
                        globalTags={tagType?.tags}
                        key={tagType?.id}
                        loading={isTagLoading}
                        referenceTable="parameter"
                        permission={writePermission}
                      />
                    ))}
                    <Tags
                      title="disciplines"
                      id={parameterId}
                      existingTags={parameterDisciplines}
                      setExistingTags={setParameterDisciplines}
                      tagType={{
                        name: 'Disciplines',
                      }}
                      globalTags={allDisciplines}
                      key="disciplines"
                      loading={isTagLoading}
                      referenceTable="parameter"
                      permission={writePermission}
                    />
                  </Grid>
                }
              />
            </StyledGrid>
          </StyledCard>
        </StyledBox>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default DataDrawer;
